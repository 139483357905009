<template>
  <teleport to="#portal">
    <div>
      <div
        id="popup"
        ref="popupContent"
        v-click-outside="() => closePopup()"
        :class="[{ showPopup }, `popup${uuid}`]"
        class="popup"
      >
        <base-card :class="{ 'with-padding': withPadding }" class="popup__content">
          <base-icon
            v-if="showCloseIcons"
            class="popup__content-close-icon"
            clickable
            font-size="24"
            name="close"
            @click="closePopup"
          />
          <slot name="content"></slot>
        </base-card>
      </div>
    </div>
  </teleport>

  <div ref="popupBtn" @click.stop="openSheet">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { getCurrentInstance, ref } from 'vue';
import { ComponentInternalInstance } from '@vue/runtime-core';

const props = withDefaults(
  defineProps<{
    start?: boolean;
    showCloseIcons?: boolean;
    withPadding?: boolean;
  }>(),
  {},
);
const showPopup = ref(false);
const popupBtn = ref<HTMLElement | undefined>();
const popupContent = ref<HTMLElement | undefined>();
const instance: ComponentInternalInstance | null = getCurrentInstance();
const uuid = ref(instance?.uid);

const closePopup = () => {
  showPopup.value = false;
};

const openSheet = (e: Event) => {
  e.stopPropagation();
  showPopup.value = true;
  setTimeout(() => {
    let popup = document.querySelector<HTMLElement>(`.popup${uuid.value}`)!;

    if (popupBtn.value && popupContent.value) {
      let styleLeft: number;

      if (props.start) {
        styleLeft = popupBtn.value.getBoundingClientRect().x;
      } else {
        styleLeft =
          popupBtn.value.getBoundingClientRect().x -
          popupContent.value.getBoundingClientRect().width +
          popupBtn.value.getBoundingClientRect().width;
      }
      popup.style.left = styleLeft < 0 ? '10px' : styleLeft + 'px';

      popup.style.top =
        popupBtn.value.getBoundingClientRect().y +
        popupBtn.value.getBoundingClientRect().height +
        document.documentElement.scrollTop +
        10 +
        'px';
    }
  }, 10);
};
</script>

<style lang="scss" scoped src="./BasePopup.scss"></style>
