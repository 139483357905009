<template>
  <Carousel :breakpoints="breakpoints">
    <Slide v-for="slide in btns" :key="slide">
      <base-button :text="slide" all-block-width height="36" style-type="grey" />
    </Slide>

    <template #addons>
      <Navigation />
    </template>
  </Carousel>
</template>

<script lang="ts" setup>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Navigation, Slide } from 'vue3-carousel';

const props = withDefaults(
  defineProps<{
    btns: string[];
  }>(),
  {},
);

const settings = {
  itemsToShow: 1,
  snapAlign: 'center',
};
const breakpoints = {
  600: {
    itemsToShow: 2,
    snapAlign: 'center',
  },
  960: {
    itemsToShow: 3,
    snapAlign: 'center',
  },
  1000: {
    itemsToShow: 4,
    snapAlign: 'center',
  },
  1264: {
    itemsToShow: 5,
    snapAlign: 'start',
  },
};
</script>

<style lang="scss" scoped src="./BaseButtonsCarousel.scss"></style>
