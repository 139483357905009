<template>
  <div v-if="isLoading" class="center my-cards">
    <base-card class="my-cards__container" full-width-mobile>
      <div class="mb--22 my-cards__container-actions">
        <base-skeleton-loader
          :key="2"
          :styles="{ width: '150px', height: '34px' }"
          type="text"
        />
      </div>
      <div class="my-cards__cards">
        <div v-for="i in 3" :key="i" class="my-cards__cards-item">
          <LoadingResidentialComplexCard />
        </div>
      </div>
      <div></div>
    </base-card>
  </div>
  <div v-else class="my-cards">
    <base-card class="my-cards__container" full-width-mobile>
      <div class="mb--22 my-cards__container-actions">
        <button
          class="gradient-color"
          @click="showCreateCategoryModal({ categoryTitle: 'ЖК' })"
        >
          Создать ЖК
        </button>
      </div>
      <div class="my-cards__cards residentialComplex">
        <div
          v-for="card in residentialComplexes"
          :key="card.id"
          class="my-cards__cards-item"
        >
          <ResidentialComplexCard :project="card" />
          <div class="my-cards__cards-actions-btn items-in-line gap--3">
            <base-button
              :href="{ name: 'updateResidentialComplex', query: { id: card.id } }"
              bgColor="#ffff"
              icon
              style-type="border"
            >
              <template #icon>
                <base-icon name="edit" />
              </template>
              <template #iconActive>
                <base-icon color="#009D3E" name="edit" />
              </template>
            </base-button>
            <base-button
              bgColor="#ffff"
              icon
              style-type="border"
              @click="showDeleteCategoryModal(+card.id)"
            >
              <template #icon>
                <base-icon name="delete" />
              </template>
              <template #iconActive>
                <base-icon color="#009D3E" name="delete" />
              </template>
            </base-button>
          </div>
        </div>
      </div>
      <div></div>
    </base-card>
    <CreateDeveloperModal
      :create-category-modal-data="createCategoryModalData"
      isResidentialComplex
      @close="closeCreateCategoryModal"
      @create-category="createCategory($event)"
    />
    <DeleteDeveloperModal
      :delete-category-modal-data="deleteCategoryModalData"
      @close="closeDeleteCategoryModal"
      @delete-category="deleteCategory"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, Ref } from 'vue';
import { useAppStore } from '@/store/modules/app';
import { ResidentialComplexCard } from '@/components';
import LoadingResidentialComplexCard from '@/components/ResidentialComplexCard/LoadingResidentialComplexCard.vue';
import { useDevelopersStore } from '@/store/modules/developers';
import {
  CreateCategoryModalType,
  DeleteCategoryModalType,
  SmallCardLSDType,
} from '@/types';
import {
  CreateDeveloperModal,
  DeleteDeveloperModal,
} from '@/components/ProfileComponents';

const window = computed((): number => {
  return useAppStore().window;
});
const isLoading: Ref<boolean> = ref(false);
const residentialComplexes: Ref<SmallCardLSDType[]> = ref([]);

const createCategoryModalData: Ref<CreateCategoryModalType> = ref({
  show: false,
  categoryTitle: undefined,
});
const showCreateCategoryModal = (event: { categoryTitle: string }) => {
  createCategoryModalData.value = {
    show: true,
    categoryTitle: event.categoryTitle,
  };
};
const createCategory = async (event: { id: number; title: string }) => {
  residentialComplexes.value = [
    {
      id: String(event.id),
      developerTitle: event.title,
      coefficient: 0,
      title: '',
      logo: undefined,
      address: '',
      deadline: '',
      phone: '',
      coordinates: [],
    },
    ...residentialComplexes.value,
  ];
};

const closeCreateCategoryModal = () => {
  createCategoryModalData.value = {
    show: false,
    categoryTitle: undefined,
  };
};

const deleteCategoryModalData: Ref<DeleteCategoryModalType> = ref({
  show: false,
  id: undefined,
});
const showDeleteCategoryModal = (id: number) => {
  deleteCategoryModalData.value = {
    show: true,
    id: id,
  };
};
const closeDeleteCategoryModal = () => {
  deleteCategoryModalData.value = {
    show: false,
    id: undefined,
  };
};

const deleteCategory = async (id: number) => {
  residentialComplexes.value = residentialComplexes.value.filter(el => +el.id !== id);
};

onMounted(async () => {
  try {
    isLoading.value = true;
    const residentialComplexList = await useDevelopersStore().getResidentialComplexList();

    if (residentialComplexList) {
      residentialComplexes.value = residentialComplexList;
    }
  } finally {
    isLoading.value = false;
  }
});
</script>

<style lang="scss" scoped src="./MyDeveloper.scss"></style>
