<template>
  <div class="items-in-line">
    <div ref="prev" class="swiper-button-prev" />
    <swiper
      :id="`thumbnails-swiper-${swiper}`"
      :modules="modules"
      :navigation="{
        prevEl: prev,
        nextEl: next,
      }"
      :slides-per-group="3"
      :slides-per-view="itemsToShow"
      :space-between="spaceBetween"
      class="swiper"
      @click="$emit('click')"
      @swiper="$emit('swiper', $event)"
    >
      <swiper-slide v-for="(slide, i) in slides" :key="i">
        <base-image
          :max-width="widthItem + 'px'"
          :src="slide"
          border-radius
          cover
          height="70"
          name="img"
          width="100%"
        />
      </swiper-slide>
    </swiper>
    <div ref="next" class="swiper-button-next" />
  </div>
</template>

<script lang="ts" setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
// eslint-disable-next-line import/no-unresolved
import { SwiperModule } from 'swiper/types/shared';
import { onMounted, Ref, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    swiper: string;
    modules: SwiperModule[];
    slides: string[];
    widthItem?: number;
    spaceBetween?: number;
  }>(),
  {
    widthItem: 120,
    spaceBetween: 5,
  },
);

const prev = ref(null);
const next = ref(null);
const itemsToShow: Ref<number> = ref(1);

onMounted(() => {
  setTimeout(() => {
    const elem = document.getElementById(`thumbnails-swiper-${props.swiper}`);
    let width: undefined | number = undefined;

    if (elem) {
      width = elem.offsetWidth;
      itemsToShow.value = width / props.widthItem;
    }
  }, 100);
});
</script>

<style lang="scss" scoped src="./BaseSwiper.scss"></style>
