<template>
  <div class="analytical-chart">
    <div class="analytical-chart__info">
      <div
        v-for="data in analyticsDataList"
        :key="data.id"
        class="border-block analytical-chart__info-item center"
      >
        <div class="analytical-chart__info-data">
          <span class="align-right third-color xs-font-size">{{ data.title }}: </span>
          <span class="align-left main-color m-font-size">{{ data.count }}</span>
        </div>
      </div>
    </div>
    <div class="analytical-chart__container">
      <div class="analytical-chart__container-inputs gap--14">
        <div v-for="(input, i) in inputs" :key="i">
          <base-select-input
            :height="34"
            :label="input"
            :styles="{ backgroundColor: '#fff' }"
            :type="'light'"
            arrow="expand_more"
          />
        </div>
      </div>
      <div class="analytical-chart__container-chart">
        <apexchart
          :options="options"
          :series="series"
          height="260"
          type="area"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const analyticsDataList = [
  {
    id: 1,
    title: 'Посещений',
    count: 1763,
  },
  {
    id: 2,
    title: 'ЦА',
    count: 347,
  },
  {
    id: 3,
    title: 'Подбор',
    count: 45,
  },
  {
    id: 4,
    title: 'Просмотр',
    count: 57,
  },
  {
    id: 5,
    title: 'Избранное',
    count: 45,
  },
  {
    id: 6,
    title: 'Чат',
    count: 45,
  },
  {
    id: 7,
    title: 'Сравнение',
    count: 32,
  },
  {
    id: 8,
    title: 'Телефон',
    count: 58,
  },
];
const inputs = [
  'Период',
  'Выбрать объявления',
  'Застройщик',
  'ЖК',
  'Очередь',
  'Объявления',
];

const options = {
  chart: {
    width: '100%',
    height: '100%',
    toolbar: {
      show: false,
    },
    locales: [
      {
        name: 'ru',
        options: {
          months: [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Мая',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',
          ],
          shortMonths: [
            'Янв',
            'Феб',
            'Март',
            'Апр',
            'Май',
            'Июнь',
            'Июль',
            'Авг',
            'Сент',
            'Окт',
            'Ноя',
            'Дек',
          ],
        },
      },
    ],
    defaultLocale: 'ru',
    fontFamily: 'Roboto',
    foreColor: '#757575',
    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 800,
      animateGradually: {
        enabled: true,
        delay: 150,
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350,
      },
    },

    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  colors: ['#009D3E'],
  fontSize: '12px',
  stroke: {
    curve: 'straight',
    width: 2,
  },
  grid: {
    xaxis: {
      lines: {
        show: true,
      },
    },
    row: {
      colors: ['#F9F9F9'],
    },
    column: {
      colors: ['#F9F9F9'],
    },
  },
  xaxis: {
    type: 'datetime',
    labels: {
      format: 'MM.yyyy',
      style: {
        fontSize: '14px !important',
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        fontSize: '10px',
      },
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      gradientToColors: ['#ABBA00'],
      opacityFrom: 0.7,
      opacityTo: 0.5,
    },
  },
  tooltip: {
    enabled: true,
    x: {
      show: false,
    },
  },
};
const series = [
  {
    name: 'Количество',
    data: [
      { x: '01/02/2023', y: 54 },
      { x: '01/05/2023', y: 200 },
      { x: '01/10/2023', y: 54 },
      { x: '01/20/2023', y: 150 },
      { x: '01/30/2023', y: 26 },
      { x: '02/02/2023', y: 54 },
      { x: '02/10/2023', y: 100 },
      { x: '02/15/2023', y: 26 },
      { x: '02/20/2023', y: 180 },
      { x: '02/28/2023', y: 100 },
      { x: '03/02/2023', y: 200 },
      { x: '03/10/2023', y: 80 },
      { x: '03/15/2023', y: 150 },
      { x: '03/20/2023', y: 190 },
      { x: '03/28/2023', y: 50 },
      { x: '04/02/2023', y: 54 },
      { x: '04/05/2023', y: 70 },
      { x: '04/08/2023', y: 98 },
      { x: '04/10/2023', y: 54 },
      { x: '04/20/2023', y: 150 },
      { x: '05/02/2023', y: 54 },
      { x: '05/05/2023', y: 10 },
      { x: '05/08/2023', y: 98 },
      { x: '05/20/2023', y: 150 },
      { x: '05/30/2023', y: 26 },
      { x: '06/02/2023', y: 123 },
      { x: '06/05/2023', y: 44 },
      { x: '06/08/2023', y: 70 },
      { x: '06/20/2023', y: 150 },
      { x: '06/30/2023', y: 26 },
      { x: '07/02/2023', y: 34 },
      { x: '07/05/2023', y: 65 },
      { x: '07/08/2023', y: 98 },
      { x: '07/20/2023', y: 150 },
      { x: '07/30/2023', y: 205 },
    ],
  },
];
</script>

<style lang="scss" scoped src="./AnalyticalChart.scss"></style>
