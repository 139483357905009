<template>
  <component
    :is="href ? 'router-link' : 'div'"
    :style="[
      {
        width: allBlockWidth ? '100%' : width + 'px',
        height: height === 'auto' ? 'auto' : height + 'px',
      },
    ]"
    :to="href"
    class="custom-btn-container"
  >
    <button
      :class="[
        btnClass,
        { 'hover-btn': !withoutActive },
        { icon, 'before-icon': beforeIcon, active },
        { withPadding: height === 'auto' },
      ]"
      :disabled="disabled"
      :style="[
        {
          width: allBlockWidth ? '100%' : width + 'px',
          height: height === 'auto' ? 'auto' : height + 'px',
          padding: `0 ${padding}px`,
          backgroundColor: bgColor,
        },
      ]"
      :type="type"
      class="custom-btn"
      @click="clickHandler"
    >
      <span v-if="icon">
        <span class="custom-btn__icon center">
          <slot name="icon"></slot>
        </span>
        <span class="custom-btn__active-icon center">
          <slot name="iconActive"></slot>
        </span>
      </span>

      <slot name="text"></slot>
      <img v-if="beforeIcon" :src="beforeIcon" alt="icon" />
      <span v-if="loading" class="custom-btn__spinner center">
        <base-spinner
          :color="styleType === 'coloredBorder' ? '#009D3E' : '#ffffff'"
          :size="height - 10"
        />
      </span>
      <span
        :class="{
          'gradient-color': styleType === 'coloredBorder',
          'custom-btn__loading-text': loading,
          uppercase,
        }"
        >{{ text }}
      </span>
    </button>
  </component>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { ButtonTypesType } from '@/types';
import { RouteRecordNormalized } from 'vue-router';

const props = withDefaults(
  defineProps<{
    text?: string;
    styleType?: ButtonTypesType;
    icon?: boolean;
    allBlockWidth?: boolean;
    width?: string;
    height?: string;
    padding?: string;
    active?: boolean;
    withoutActive?: boolean;
    beforeIcon?: string;
    type?: 'button' | 'submit';
    loading?: boolean;
    disabled?: boolean;
    href?: RouteRecordNormalized;
    bgColor?: string;
    uppercase?: boolean;
  }>(),
  {
    styleType: 'colored',
    icon: false,
    allBlockWidth: false,
    height: '28',
    active: false,
    withoutActive: false,
    beforeIcon: undefined,
    type: 'button',
  },
);

const emit = defineEmits(['click']);

const btnClass = computed((): string => {
  let classForBtn: string;

  switch (props.styleType) {
    case 'coloredBorder':
      classForBtn = 'custom-btn--colored-border-btn gradient';
      break;
    case 'border':
      classForBtn = 'custom-btn--border-btn';
      break;
    case 'greyBorder':
      classForBtn = 'custom-btn--grey-border-btn';
      break;
    case 'greenBorder':
      classForBtn = 'custom-btn--green-border-btn';
      break;
    case 'translucent':
      classForBtn = 'custom-btn--translucent-btn';
      break;
    case 'translucentGreen':
      classForBtn = 'custom-btn--translucent-green-btn';
      break;
    case 'white':
      classForBtn = 'custom-btn--white-btn';
      break;
    case 'grey':
      classForBtn = 'custom-btn--grey-btn';
      break;
    default:
      classForBtn = 'custom-btn--colored-btn';
  }

  return classForBtn;
});

const clickHandler = () => {
  emit('click');
};
</script>

<style lang="scss" scoped src="./BaseButton.scss"></style>
