<template>
  <div :class="style" class="table">
    <table v-if="!headInColumn" :class="{ withBtns: !!btns }" class="table__block">
      <thead v-if="withHeaders">
        <tr :class="style">
          <th
            v-for="(header, i) in headers.filter(el => !el.hide)"
            :key="i"
            :class="style"
            :style="{
              backgroundColor: header.backGroundColor,
              color: header.color,
              textAlign: header.align,
            }"
            scope="col"
          >
            {{ header.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in preparedItems" :key="i">
          <td
            v-for="(header, headerIndex) in headers.filter(el => !el.hide)"
            :key="headerIndex"
            :class="[
              {
                yesIcon:
                  style === 'firstColumnTitle' && item[header.value].value === true,
                noIcon:
                  style === 'firstColumnTitle' && item[header.value].value === false,
                icon: item[header.value]?.icon !== undefined,
              },
              style,
            ]"
            :style="{
              backgroundColor: item[header.value]?.bgColor ?? header.backGroundColumn,
              color: item[header.value]?.color ?? header.colorValue,
              textAlign: header.alignValue ?? header.align,
            }"
            class="table__block-item"
          >
            <div v-if="$slots[header.value]">
              <slot :data="item[header.value]" :name="header.value"></slot>
            </div>
            <div v-else-if="item[header.value]?.isLoading === true" class="center">
              <base-spinner color="#009D3E" />
            </div>
            <div v-else-if="item[header.value]?.value === true" class="center">
              <base-icon
                :class="{ 'firstColumnTitle-yes-icon': style === 'firstColumnTitle' }"
                color="#009D3E"
                height="15"
                name="done"
                width="15"
              />
            </div>
            <div
              v-else-if="
                item[header.value]?.value === false && style === 'firstColumnTitle'
              "
            >
              <base-icon
                class="firstColumnTitle-no-icon"
                color="#EA1F1F"
                height="15"
                name="close"
                width="15"
              />
            </div>

            <div v-else-if="item[header.value]?.value === false" />
            <div v-else-if="item[header.value]?.icon !== undefined" class="center">
              <base-icon
                :name="item[header.value]?.icon"
                clickable
                color="#94857d"
                height="18"
                width="21"
                @click="header.click ? header.click(item) : () => {}"
              />
            </div>
            <div v-else>
              <span class="line-through">{{ item[header.value]?.oldValue }}</span>
              {{ item[header.value]?.value }}
            </div>
            <button
              v-if="showDropdown && headerIndex === 0 && i === preparedItems.length - 1"
              class="table__block-item-btn gradient-color dropdown"
              @click="isTableOpened = !isTableOpened"
            >
              {{ isTableOpened ? 'Свернуть' : 'Развернуть' }}
            </button>
            <div
              v-if="btns && btns[header.value] && i === preparedItems.length - 1"
              class="table__block-item-btn"
            >
              <base-button
                :text="btns[header.value]"
                height="35"
                style-type="coloredBorder"
                width="99"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table v-else class="headInColumn">
      <tr
        v-for="(header, headerIndex) in headers.filter(el => !el.hide)"
        :key="headerIndex"
      >
        <th>{{ header.text }}</th>
        <td
          v-for="(item, i) in preparedItems.filter(el => el[header.value])"
          :key="i"
          :colspan="item[header.value]?.colspan"
        >
          {{ item[header.value]?.value }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { HeaderType, ItemType } from '@/types/TableTypes';
import { computed, ref, Ref } from 'vue';

const props = withDefaults(
  defineProps<{
    headers: HeaderType[];
    items: ItemType[];
    style?: 'base' | 'simple' | 'firstColumnTitle';
    btns?: { [key: string]: string };
    showDropdown?: boolean;
    dropDownCount?: number;
    headInColumn?: boolean;
    withHeaders?: boolean;
  }>(),
  {
    style: 'base',
    dropDownCount: 5,
    headInColumn: false,
    withHeaders: true,
  },
);

const isTableOpened: Ref<boolean> = ref(false);
const preparedItems = computed((): ItemType[] => {
  if (props.showDropdown && !isTableOpened.value) {
    return [...props.items].slice(0, props.dropDownCount);
  } else {
    return props.items;
  }
});
</script>

<style lang="scss" scoped src="./BaseTable.scss"></style>
