import { defineStore } from 'pinia';
import { store } from '@/store';
import { getClassifierByIdApi } from '@/api/classifier';
import { ClassifierType } from '@/api/classifier/types';
import { prepareUrl } from '@/mixins/Common/Common';

type classificationState = {
  maxRate: number | undefined;
};

export const useClassificationStore = defineStore('classification', {
  state: (): classificationState => {
    return {
      maxRate: undefined,
    };
  },
  actions: {
    async searchClassifierById(cardId: number) {
      const res = await getClassifierByIdApi(cardId);

      const prepareClassifier = (classifier: ClassifierType) => {
        return {
          ...classifier,
          categories: classifier.categories.map(el => {
            return {
              ...el,
              values: el.values.map(value => {
                return {
                  title: {
                    value: value.title,
                  },
                  coefficient: {
                    value: value.coefficient,
                  },
                  used: {
                    value: value.used,
                  },
                  weight: {
                    value: value.used ? +value.coefficient * el.weight : '',
                    color: +value.coefficient < 0 ? '#EA1F1F' : '',
                  },
                };
              }),
            };
          }),
          maxRate: classifier?.categories.reduce(function (a, b) {
            return a + b.weight;
          }, 0),
        };
      };

      if (res) {
        return {
          ...res.data,
          classifier: prepareClassifier(res.data.classifier),
          logo: prepareUrl(res.data.logo) as string,
          isResidentialComplex: res.data.is_residential_complex,
        };
      }
    },
  },
  getters: {},
});

export const useClassificationStoreWithOut = () => {
  return useClassificationStore(store);
};
