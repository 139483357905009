<template>
  <div class="container content developer-cards">
    <div class="items-in-line gap--0 flex-start">
      <h2 class="developer-cards__title">
        {{
          `Список застройщиков Ростовской области${countDevelopers ? `: ${countDevelopers}` : ''}`
        }}
      </h2>
    </div>
    <div>
      <base-skeleton-loader
        v-if="isDevelopersLoading"
        :style="{ height: '30px', width: '100%' }"
        type="mediumText"
      />
      <base-input
        v-else
        v-model="searchText"
        appendIcon="search"
        label="Поиск по названию"
        @blur="searchHandler"
      />
    </div>
    <div v-if="isDevelopersLoading" class="developer-cards__container">
      <LoadingMediumDeveloperCard v-for="i in 12" :key="i" />
    </div>
    <div v-else class="developer-cards__container">
      <MediumDeveloperCard
        v-for="(developer, i) in allDevelopers"
        :key="i"
        :developer="developer"
      />
    </div>
    <base-pagination-group
      :customPage="paginationPage"
      :isLoading="isDevelopersLoading"
      :isMoreLoading="isMoreLoading"
      :itemCountOnPage="12"
      :max-for-visible="countDevelopers"
      :min-for-visible="4"
      :objectCount="countDevelopers"
      :step-for-visible="4"
      :value="paginationPage"
      :values="allDevelopers"
      auto-add-mobile
      @changePageAndPageSize="changePageAndPageSize($event)"
    />
  </div>
</template>

<script lang="ts" setup>
import {
  LoadingMediumDeveloperCard,
  MediumDeveloperCard,
} from '@/components/Cards/MediumCards';
import { onMounted, Ref, ref } from 'vue';
import { useDevelopersStore } from '@/store/modules/developers';
import { SmallDeveloperCardType } from '@/types';
import { ListDataType } from '@/api/developer/types';

const isDevelopersLoading = ref(false);
const isMoreLoading = ref(false);
const allDevelopers: Ref<SmallDeveloperCardType[]> = ref([]);
const countDevelopers: Ref<undefined | number> = ref(undefined);
const paginationPage = ref(1);
const pageSize = ref(12);
const searchText: Ref<undefined | string> = ref(undefined);

const searchHandler = () => {
  updateDeveloperList({
    page: paginationPage.value,
    pageSize: pageSize.value,
    search: searchText.value,
  });
};

const changePageAndPageSize = (event: {
  page: number;
  pageSize: number;
  showMore?: true;
}) => {
  updateDeveloperList(
    {
      page: event.page,
      pageSize: event.pageSize,
      search: searchText.value,
    },
    event.showMore,
  );
};

const updateDeveloperList = async (data: ListDataType, showMore?: boolean) => {
  isDevelopersLoading.value = true;
  try {
    const res = await useDevelopersStore().searchAllDevelopers(data);

    if (res) {
      if (showMore) {
        allDevelopers.value = [...allDevelopers.value, ...res.developers];
      } else {
        allDevelopers.value = res.developers;
      }

      countDevelopers.value = res.count;
      pageSize.value = res.pageSize;
    }
  } finally {
    isDevelopersLoading.value = false;
  }
};

onMounted(async () => {
  await updateDeveloperList({});
});
</script>

<style lang="scss" scoped src="./DeveloperCards.scss"></style>
