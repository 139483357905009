<template>
  <div class="result-block">
    <div class="container">
      <div v-if="isLoading" class="result-block__title-box">
        <base-skeleton-loader
          :style="{ height: '30px', width: '300px' }"
          type="mediumText"
        />
      </div>

      <div
        v-else
        :class="{ 'result-block__title-with-line': titleWithLine }"
        class="result-block__title-box"
      >
        <base-divider v-if="titleWithLine" />
        <span class="result-block__title"> {{ title }}</span>
        <base-divider v-if="titleWithLine" />
      </div>
      <div v-if="!isTop" class="result-block__sort">
        <div class="result-block__sort-input">
          <base-select-input
            :loading="isLoading"
            :modelValue="activeSort"
            :options="sortOptions"
            label="Сортировать по"
            @update:modelValue="sortOfferCards($event)"
          />
        </div>
      </div>
      <div v-if="isLoading" class="result-block__cards">
        <div v-for="i in isTop ? 4 : 8" :key="i">
          <LoadingOfferCard />
        </div>
      </div>
      <div
        v-else-if="preparedResultCards.length"
        id="resultCards"
        class="result-block__cards"
      >
        <div
          v-for="card in preparedResultCards"
          :key="card.id"
          :style="[activeResultCardId === card.id ? { height: activeBlockHeight } : '']"
        >
          <OfferSmallCard
            :card="card"
            :is-active="activeResultCardId === card.id"
            :with-delete="!isTop"
            @changeActive="changeActive"
            @hideCard="$emit('hideCard', card.id)"
          />
          <div
            v-if="activeResultCardId === card.id"
            :id="'resultCard' + String(card.id)"
            class="result-block__cards-activeBlock"
          >
            <ActiveCard
              :card="card"
              @changeActive="changeActive"
              @updateActiveBlock="updateActiveBlock"
            />
          </div>
        </div>
      </div>
      <div v-else>По вашему запросу ничего не найдено</div>

      <base-pagination-group
        v-if="!isTop && preparedResultCards.length"
        :customPage="paginationPage"
        :isLoading="isLoading"
        :isMoreLoading="isMoreLoading"
        :itemCountOnPage="8"
        :max-for-visible="40"
        :min-for-visible="8"
        :objectCount="objectCount"
        :step-for-visible="8"
        :value="preparedResultCards"
        :values="resultCards"
        auto-add-mobile
        class="mt--30"
        @changePageAndPageSize="emit('changePageAndPageSize', $event)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, onMounted, Ref, ref, watch } from 'vue';
import { ResultCardType } from '@/types';
import ActiveCard from '@/components/Cards/SmallCards/OfferSmallCard/ActiveOfferCard/ActiveOfferCard.vue';
import { SmoothScrollOptions } from 'vue3-smooth-scroll';
import { LoadingOfferCard, OfferSmallCard } from '@/components/Cards';
import { useAppStore } from '@/store/modules/app';
import { useRouter } from 'vue-router';
import { useOfferCardsStore } from '@/store/modules/offerCards';

type OptionType = {
  id: number;
  name: string;
};

const props = withDefaults(
  defineProps<{
    isLoading?: boolean;
    isMoreLoading?: boolean;
    isTop?: boolean;
    isMoreLink?: boolean;
    title?: string;
    resultCards: ResultCardType[];
    paginationPage?: number;
    titleWithLine?: boolean;
  }>(),
  {
    title: 'Подходящие вам',
  },
);
// eslint-disable-next-line no-undef
const activeSort = defineModel<number | undefined>();
const emit = defineEmits(['hideCard', 'sortResultBlock', 'changePageAndPageSize']);

const activeResultCardId: Ref<number | undefined> = ref(undefined);
const preparedResultCards = computed((): ResultCardType[] => {
  return props.resultCards;
});

const objectCount = computed(() => {
  return useOfferCardsStore().objectCount;
});
const activeBlockHeight: Ref<string> = ref('');

const sortOptions: OptionType[] = [
  { id: 1, name: 'По цене (сначала дешевле)' },
  { id: 2, name: 'По цене (сначала дороже)' },
  { id: 3, name: 'По площади дома (сначала меньше)' },
  { id: 4, name: 'По площади дома (сначала больше)' },
  { id: 5, name: 'По площади участка(сначала меньше)' },
  { id: 6, name: 'По площади участка(сначала больше)' },
];

const sortOfferCards = (id: number) => {
  activeSort.value = id;
  emit('sortResultBlock', id);
};

const smoothScroll = inject('smoothScroll') as (args: SmoothScrollOptions) => void;
const scrollToMyEl = (myEl: HTMLElement, updateHistory: boolean = true) => {
  smoothScroll({
    scrollTo: myEl,
    offset: -100,
    updateHistory: updateHistory,
  });
};

const router = useRouter();
const changeActive = (id: number) => {
  if (useAppStore().getWindowWidth() > 600 && !props.isMoreLink) {
    if (activeResultCardId.value === id) {
      activeResultCardId.value = undefined;
    } else {
      activeResultCardId.value = id;
      setTimeout(() => {
        const myEl = document.getElementById(`resultCard${id}`);

        if (myEl) {
          scrollToMyEl(myEl, false);
        }
      }, 200);
      useAppStore().sendStatistic(5);
    }

    updateActiveBlock();
  } else {
    router.push({ name: 'offerCard', params: { id: id } });
  }
};
const updateActiveBlock = () => {
  setTimeout(() => {
    const activeBlock = document.getElementById('activeCard');

    let height = activeBlock ? activeBlock!.clientHeight : 0;

    activeBlockHeight.value = height ? `${height + 420}px` : '100%';
  }, 1);
};

onMounted(() => {});
watch(
  () => props.isLoading,
  () => {
    updateActiveBlock();
  },
);
</script>

<style lang="scss" scoped src="./OfferCardsBlock.scss"></style>
