<template>
  <div>
    <a
      v-if="docs.length === 1"
      :href="docs[0].path"
      download
      target="_blank"
      @click="linkHandler"
    >
      <slot name="title"></slot>
      <base-button v-if="!$slots.title" icon style-type="border" withoutActive>
        <template #icon>
          <img :src="require('@/assets/img/mainPage/icons/icon-pdf.png')" alt="pdf" />
        </template>
      </base-button>
    </a>
    <base-popup v-else-if="docs.length" with-padding>
      <base-button icon style-type="border" withoutActive>
        <template #icon>
          <img :src="require('@/assets/img/mainPage/icons/icon-pdf.png')" alt="pdf" />
        </template>
      </base-button>
      <template #content>
        <div>
          <div class="m-font-size mb--20">Скачать</div>
          <div class="items-in-column">
            <a
              v-for="(file, i) in docs"
              :key="i"
              :href="file.path"
              class="underline"
              download
              target="_blank"
              >{{ file.name }}</a
            >
          </div>
        </div>
      </template>
    </base-popup>
  </div>
</template>

<script lang="ts" setup>
import { FileType } from '@/api/house/types';

const props = defineProps<{
  docs: FileType[];
}>();

const linkHandler = (e: Event) => {
  e.stopPropagation();
};
</script>

<style lang="scss" scoped></style>
