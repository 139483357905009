<template>
  <div class="auth-modal">
    <div>
      <div class="align-center l-font-size">Восстановление пароля</div>
    </div>
    <base-form class="grid--1 gap--15">
      <base-input
        v-model="formData.email"
        :error="errors.email"
        :invalid="!!errors.email"
        height="40"
        label="E-mail"
        @blur="validationErrorsHandlerByKey('email')"
        @focus="delete errors.email"
        @input="v$.email.$touch"
      />
      <base-button
        :loading="loading"
        allBlockWidth
        height="40"
        text="Сбросить пароль"
        @click="formSubmit"
      />
    </base-form>
    <base-divider />
    <button class="second-color xs-font-size" @click="$emit('close')">
      Перейти на страницу входа
    </button>
  </div>
</template>
<script lang="ts" setup>
import { Ref, ref } from 'vue';
import { useVuelidate, ValidationRuleWithoutParams } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import {
  errorsHandler,
  errorsHandlerByKey,
  validationErrorsByRequest,
} from '@/mixins/Common/Common';
import { ErrorsType } from '@/types';
import { passwordRestoreRequestApi } from '@/api/login';

const emit = defineEmits(['close', 'showSuccessMessage']);

type FormDataType = {
  email: string | undefined;
};

const formData: Ref<FormDataType> = ref({
  email: undefined,
});
const clearFormData = () => {
  v$.value.$reset();
  for (const [key] of Object.entries(formData)) {
    formData.value[key as keyof FormDataType] = undefined;
  }
};
const rules: { [key: string]: { [key: string]: ValidationRuleWithoutParams } } = {
  email: { required, email },
};
const v$ = useVuelidate<FormDataType>(rules, formData);

const errors: Ref<ErrorsType> = ref({});

const validationErrorsHandlerByKey = (key: string) => {
  let error: string | undefined = errorsHandlerByKey(key, rules, v$);

  if (error) {
    errors.value[key] = error;
  } else {
    delete errors.value[key];
  }
};
const validationErrorsHandler = () => {
  errors.value = errorsHandler(formData.value, rules, v$);
};

const loading = ref(false);
const formSubmit = async () => {
  if (v$.value.$invalid) {
    v$.value.$touch();
    validationErrorsHandler();

    return;
  }

  try {
    loading.value = true;
    const res = await passwordRestoreRequestApi(formData.value);

    if (res) {
      clearFormData();
      emit('showSuccessMessage');
    }
  } catch (error) {
    errors.value = validationErrorsByRequest(error);
  } finally {
    loading.value = false;
  }
};
</script>

<style lang="scss" scoped src="./AuthModal.scss"></style>
