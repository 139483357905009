<template>
  <div v-if="isUserLogin">
    <base-modal :show-modal="showModal" title="Отзыв / Предложение" @close="closeModal">
      <template #content>
        <div class="review-modal items-in-column">
          <div v-if="showSuccessMessage" class="l-font-size">
            Отзыв успешно отправлен!
          </div>

          <base-form v-else class="review-modal__form">
            <base-textarea
              v-model="formData.comment"
              :error="errors.comment"
              :invalid="!!errors.comment"
              height="85"
              label="Комментарий"
              textarea
              @blur="validationErrorsHandlerByKey('comment')"
              @focus="delete errors.comment"
              @input="v$.comment?.$touch"
            />
          </base-form>
        </div>
      </template>
      <template #btn>
        <base-button
          v-if="showSuccessMessage"
          height="40"
          text="Закрыть"
          width="150"
          @click="closeModal"
        />
        <base-button
          v-else
          :loading="loading"
          height="40"
          text="Отправить"
          width="150"
          @click="formSubmit"
        />
      </template>
    </base-modal>
  </div>
  <div v-else>
    <FullReviewModal :show-modal="showModal" @close="emit('close')" />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, Ref } from 'vue';
import { useVuelidate, ValidationRuleWithoutParams } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { ErrorsType } from '@/types';
import {
  errorsHandler,
  errorsHandlerByKey,
  validationErrorsByRequest,
} from '@/mixins/Common/Common';
import { useUserStore } from '@/store/modules/user';
import { sendReview } from '@/api/common';
import FullReviewModal from '@/components/ReviewModal/FullReviewModal.vue';

const props = defineProps<{
  showModal?: boolean;
}>();
const emit = defineEmits(['close']);

const isUserLogin = computed((): boolean => {
  return useUserStore().getIsLogin();
});

type FormDataType = {
  comment: string | undefined;
};

const formData: Ref<FormDataType> = ref({
  comment: undefined,
});

const clearFormData = () => {
  v$.value.$reset();
  for (const [key] of Object.entries(formData)) {
    formData.value[key as keyof FormDataType] = undefined;
  }
};
const rules: { [key: string]: { [key: string]: ValidationRuleWithoutParams } } = {
  comment: { required },
};

const v$ = useVuelidate<FormDataType>(rules, formData);
const errors: Ref<ErrorsType> = ref({});
const showSuccessMessage: Ref<boolean> = ref(false);
const validationErrorsHandlerByKey = (key: string) => {
  let error: string | undefined = errorsHandlerByKey(key, rules, v$);

  if (error) {
    errors.value[key] = error;
  } else {
    delete errors.value[key];
  }
};
const validationErrorsHandler = () => {
  errors.value = errorsHandler(formData.value, rules, v$);
};

const loading = ref(false);
const formSubmit = async () => {
  if (v$.value.$invalid) {
    v$.value?.$touch();
    validationErrorsHandler();

    return;
  }

  loading.value = true;
  try {
    const res = await sendReview(formData.value);

    if (res) {
      clearFormData();
      showSuccessMessage.value = true;
    }
  } catch (error) {
    errors.value = validationErrorsByRequest(error);
  } finally {
    loading.value = false;
  }
};

const closeModal = () => {
  clearFormData();
  emit('close');
};
</script>

<style lang="scss" scoped src="./ReviewModal.scss"></style>
