<template>
  <base-modal :show-modal="showModal" title="Регистрация" @close="$emit('close')">
    <template #content>
      <div class="auth-modal registration">
        <base-form>
          <div class="auth-modal__inputs">
            <base-input
              v-if="!isLightRegistration"
              v-model="formData.name"
              :error="errors.name"
              :invalid="!!errors.name"
              height="36"
              label="Имя"
              @blur="validationErrorsHandlerByKey('name')"
              @focus="delete errors.name"
              @input="v$.name.$touch"
            />
            <base-input
              v-model="formData.phone"
              v-maska
              :error="errors.phone"
              :invalid="!!errors.phone"
              data-maska="(###)###-##-##"
              height="36"
              label="Телефон"
              placeholder="(999)000-99-99"
              prefix="+7 "
              @blur="validationErrorsHandlerByKey('phone')"
              @focus="delete errors.phone"
              @input="v$.phone.$touch"
            />
            <base-input
              v-model="formData.email"
              :error="errors.email"
              :invalid="!!errors.email"
              height="36"
              label="E-mail"
              @blur="validationErrorsHandlerByKey('email')"
              @focus="delete errors.email"
              @input="v$.email.$touch"
            />
            <base-select-input
              :error="errors.type_id"
              :modelValue="formData.type_id"
              :options="useAppStore().user_types"
              height="36"
              label="Роль"
              text-key="title"
              @blur="validationErrorsHandlerByKey('type')"
              @focus="delete errors.type_id"
              @update:modelValue="formData.type_id = $event"
            />
            <base-select-input
              v-if="!isLightRegistration"
              :error="errors.region_id"
              :modelValue="formData.region_id"
              :options="useAppStore().regions"
              height="36"
              label="Регион"
              @update:modelValue="formData.region_id = $event"
            />
          </div>
        </base-form>
        <span class="xs-font-size items-in-line gap--5 flex-wrap flex-start">
          <span
            ><base-checkbox
              v-model="formData.acceptanceOfTerms"
              :error="errors.acceptanceOfTerms"
              @update:modelValue="delete errors.acceptanceOfTerms"
          /></span>
          <button
            :class="{ 'error-color': errors.acceptanceOfTerms?.length }"
            @click="formData.acceptanceOfTerms = !formData.acceptanceOfTerms"
          >
            Принимаю условия
          </button>
          <span v-for="(condition, i) in conditions" :key="i">
            <span class="four-color xs-font-size">
              {{ condition }}
            </span>
            <span v-if="i !== conditions.length - 1" class="ml--5 mr--5">/</span>
          </span>
        </span>
        <div class="items-in-line gap--5 flex-end">
          Уже есть аккаунт?
          <button
            class="gradient-color gradient-underline"
            @click="$emit('showLoginModal')"
          >
            Войти
          </button>
        </div>
      </div>
    </template>

    <template #btn>
      <base-button
        :loading="loading"
        height="40"
        text="Зарегистрироваться"
        @click="formSubmit"
      />
    </template>
  </base-modal>
</template>
<script lang="ts" setup>
import { computed, Ref, ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import { vMaska } from 'maska';
import { useAppStore } from '@/store/modules/app';
import {
  errorsHandler,
  errorsHandlerByKey,
  validationErrorsByRequest,
} from '@/mixins/Common/Common';
import { ErrorsType, ValidateRulesType } from '@/types/MainPageTypes';
import { registrationApi } from '@/api/login';

const props = defineProps<{
  showModal?: boolean;
}>();
const emit = defineEmits(['close', 'showLoginModal', 'successRegistration']);

type FormDataType = {
  phone: string | undefined;
  email: string | undefined;
  region_id: undefined | number;
  name: string | undefined;
  type_id: undefined | number;
  acceptanceOfTerms: undefined | boolean;
};

const conditions = [
  'Пользователькое согл.',
  'Соглашение исп. сайта',
  'Маркетинговую рассылку',
  'Лицензионное соглашение',
  'Рекомендация технологии',
  'Обработка и расп. перс.данных',
];

const isLightRegistration = computed((): boolean => {
  return useAppStore().lightRegistration;
});

const formData: Ref<FormDataType> = ref({
  phone: undefined,
  email: undefined,
  region_id: undefined,
  name: undefined,
  type_id: undefined,
  acceptanceOfTerms: false,
});

const clearFormData = () => {
  v$.value.$reset();
  for (const [key] of Object.entries(formData)) {
    formData.value[key as keyof FormDataType] = undefined;
  }
};

const rules: ValidateRulesType = {
  phone: { required },
  email: { required, email },
  region_id: {},
  name: { required },
  type_id: { required },
  acceptanceOfTerms: { checked: (value: boolean | undefined) => value === true },
};
const v$ = useVuelidate<FormDataType>(rules, formData);

const errors: Ref<ErrorsType> = ref({});

const validationErrorsHandlerByKey = (key: string) => {
  let error: string | undefined = errorsHandlerByKey(key, rules, v$);

  if (error) {
    errors.value[key] = error;
  } else {
    delete errors.value[key];
  }
};
const validationErrorsHandler = () => {
  errors.value = errorsHandler(formData.value, rules, v$);
};

const loading = ref(false);
const formSubmit = async () => {
  if (v$.value.$invalid) {
    v$.value.$touch();
    validationErrorsHandler();

    return;
  }
  loading.value = true;
  const preparedFormData = {
    phone: formData.value.phone?.replace(/[^\d]/g, ''),
    email: formData.value.email,
    region_id: formData.value.region_id,
    name: formData.value.name,
    type_id: formData.value.type_id,
  };

  try {
    const res = await registrationApi(preparedFormData);

    if (res) {
      clearFormData();
      emit('successRegistration');
    }
  } catch (error) {
    errors.value = validationErrorsByRequest(error);
  } finally {
    loading.value = false;
  }
};
</script>

<style lang="scss" scoped src="./AuthModal.scss"></style>
