<template>
  <div class="widget">
    <div class="center widget__spinner">
      <base-spinner :size="50" color="#009D3E" />
    </div>
    <div>
      <iframe
        id="MortgageWidget"
        class="widget__content"
        src="/bankiShopWidget.html"
      ></iframe>
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped src="./Widget.scss"></style>
