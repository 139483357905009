<template>
  <div>
    <base-form>
      <div class="edit-profile-info__form">
        <base-input
          v-model="formData.name"
          :error="errors.name"
          :invalid="!!errors.name"
          height="36"
          label="Имя"
          @blur="validationErrorsHandlerByKey('name')"
          @focus="delete errors.name"
          @input="v$.name.$touch"
        />
        <base-input
          v-model="formData.phone"
          v-maska
          :error="errors.phone"
          :invalid="!!errors.phone"
          data-maska="(###)###-##-##"
          height="36"
          label="Телефон"
          placeholder="(999)000-99-99"
          prefix="+7 "
          @blur="validationErrorsHandlerByKey('phone')"
          @focus="delete errors.phone"
          @input="v$.phone.$touch"
        />
        <base-file-input
          v-model="formData.logo"
          :error="errors.logo"
          :files="formData.logoFile"
          :invalid="!!errors.logo"
          height="36"
          label="Фото"
          withIcon
          @blur="validationErrorsHandlerByKey('logo')"
          @deleteFile="openModal('logo')"
          @focus="delete errors.logo"
          @input="v$.logo.$touch"
        />
        <base-input
          v-if="userType !== 1"
          v-model="companyFormData.titleCompany"
          :error="errors.titleCompany"
          :invalid="!!errors.titleCompany"
          height="36"
          label="Название компании"
          @blur="validationErrorsHandlerByKey('titleCompany')"
          @focus="delete errors.titleCompany"
          @input="vc$.titleCompany.$touch"
        />
        <base-input
          v-if="userType !== 1"
          v-model="companyFormData.siteCompany"
          :error="errors.siteCompany"
          :invalid="!!errors.siteCompany"
          height="36"
          label="Сайт компании"
          @blur="validationErrorsHandlerByKey('siteCompany')"
          @focus="delete errors.siteCompany"
          @input="vc$.siteCompany.$touch"
        />
        <base-file-input
          v-if="userType !== 1"
          v-model="companyFormData.logoCompany"
          :error="errors.logoCompany"
          :files="companyFormData.logoCompanyFile"
          :invalid="!!errors.logoCompany"
          height="36"
          label="Логотип компании"
          withIcon
          @blur="validationErrorsHandlerByKey('logoCompany')"
          @deleteFile="openModal('logoCompany')"
          @focus="delete errors.logoCompany"
          @input="vc$.logoCompany.$touch"
        />
      </div>
      <div class="items-in-line mt--20">
        <base-button
          :disabled="loading"
          :href="{ query: { id: 'info' } }"
          height="40"
          style-type="coloredBorder"
          text="Отмена"
        />
        <base-button
          :loading="loading"
          height="40"
          text="Сохранить"
          @click="formSubmit"
        />
      </div>
    </base-form>
    <base-modal :show-modal="modalData.show" hide-title @close="closeModal">
      <template #content>
        <div>Вы уверены, что хотите удалить файл?</div>
      </template>
      <template #btn>
        <div class="items-in-line">
          <base-button
            height="40"
            style-type="border"
            text="Отмена"
            width="118"
            @click="closeModal"
          />
          <base-button
            :loading="modalData.btnLoading"
            height="40"
            text="Да"
            width="118"
            @click="deleteFile"
          />
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, Ref } from 'vue';
import { useVuelidate, ValidationRuleWithoutParams } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { ErrorsType, FileType } from '@/types';
import { vMaska } from 'maska';
import {
  errorsHandler,
  errorsHandlerByKey,
  validationErrorsByRequest,
} from '@/mixins/Common/Common';
import { BaseFileInput } from '@/components/UI';
import { UserType, useUserStore } from '@/store/modules/user';
import { useRouter } from 'vue-router';
import { useAppStore } from '@/store/modules/app';

type FormDataType = {
  phone: string | undefined;
  name: string | undefined;
  logo: { [key: string]: File } | undefined;
  logoFile: FileType | undefined;
};
type CompanyFormDataType = {
  logoCompany: { [key: string]: File } | undefined;
  logoCompanyFile: FileType | undefined;
  titleCompany: string | undefined;
  siteCompany: string | undefined;
};
const formData: Ref<FormDataType> = ref({
  phone: undefined,
  name: undefined,
  logo: undefined,
  logoFile: undefined,
});
const companyFormData: Ref<CompanyFormDataType> = ref({
  logoCompany: undefined,
  logoCompanyFile: undefined,
  titleCompany: undefined,
  siteCompany: undefined,
});
const userType = computed((): number | undefined => {
  return useUserStore().user?.type;
});
const clearFormData = () => {
  v$.value.$reset();
  for (const [key] of Object.entries(formData.value)) {
    formData.value[key as keyof FormDataType] = undefined;
  }
};

const rules: { [key: string]: { [key: string]: ValidationRuleWithoutParams } } = {
  phone: { required },
  logo: {},
  logoFile: {},
  name: { required },
};
const companyRules: { [key: string]: { [key: string]: ValidationRuleWithoutParams } } = {
  logoCompany: {},
  logoCompanyFile: {},
  titleCompany: {},
  siteCompany: {},
};
const v$ = useVuelidate<FormDataType>(rules, formData);
const vc$ = useVuelidate<CompanyFormDataType>(companyRules, companyFormData);

const errors: Ref<ErrorsType> = ref({});

const validationErrorsHandlerByKey = (key: string) => {
  let error: string | undefined = errorsHandlerByKey(key, rules, v$);

  if (error) {
    errors.value[key] = error;
  } else {
    delete errors.value[key];
  }
};
const validationErrorsHandler = () => {
  errors.value = errorsHandler(formData.value, rules, v$);
};

const prepareDataForSend = (): FormData => {
  let sendData = new FormData();
  const copyFormData =
    userType.value !== 1
      ? { ...formData.value, ...companyFormData.value }
      : formData.value;

  for (let key in copyFormData) {
    let name = key;
    let value: string;

    if (key !== 'logoCompanyFile' && key !== 'logoFile') {
      if (key === 'logo') {
        if (formData.value[key as keyof FormDataType]) {
          Object.values(
            formData.value[key] as {
              [key: string]: File | null;
            },
          ).forEach(val => {
            if (val !== null) {
              sendData.append(`parameters[${name}]`, val);
            }
          });
        }
      } else if (key === 'logoCompany') {
        if (companyFormData.value[key as keyof CompanyFormDataType]) {
          Object.values(
            companyFormData.value[key] as {
              [key: string]: File | null;
            },
          ).forEach(val => {
            if (val !== null) {
              sendData.append(`parameters[${name}]`, val);
            }
          });
        }
      } else {
        if (key === 'phone') {
          value = formData.value.phone?.replace(/[^\d]/g, '') ?? '';
        } else {
          value = (copyFormData[key as keyof FormDataType] as string) ?? '';
        }

        sendData.append(`parameters[${name}]`, value);
      }
    }
  }

  return sendData;
};
const loading = ref(false);
const router = useRouter();
const formSubmit = async () => {
  if (v$.value.$invalid) {
    v$.value.$touch();
    validationErrorsHandler();

    return;
  }
  if (userType.value !== 1) {
    if (vc$.value.$invalid) {
      vc$.value.$touch();
      validationErrorsHandler();

      return;
    }
  }
  loading.value = true;

  try {
    const res = await useUserStore().updateUserInfo(prepareDataForSend());

    if (res) {
      await router.push({ query: { id: 'info' } });
      clearFormData();
    }
  } catch (error) {
    errors.value = validationErrorsByRequest(error);
  } finally {
    loading.value = false;
  }
};
const userInfo = computed((): UserType | undefined => {
  return useUserStore().user;
});

const modalData: Ref<{
  show: boolean;
  inputId: undefined | string;
  btnLoading: boolean;
}> = ref({
  show: false,
  inputId: undefined,
  btnLoading: false,
});

const openModal = (inputId: string) => {
  modalData.value = {
    show: true,
    inputId: inputId,
    btnLoading: false,
  };
};
const deleteFile = async () => {
  if (modalData.value.inputId) {
    try {
      modalData.value.btnLoading = true;

      const res =
        modalData.value.inputId === 'logo'
          ? await useUserStore().deleteLogo()
          : await useUserStore().deleteCompanyLogo();

      if (res) {
        useAppStore().openAlert('', 'Файл удален');
        if (modalData.value.inputId === 'logo') {
          formData.value.logoFile = undefined;
        } else {
          companyFormData.value.logoCompanyFile = undefined;
        }
      }
    } finally {
      closeModal();
      modalData.value.btnLoading = false;
    }
  }
};
const closeModal = () => {
  modalData.value = {
    show: false,
    inputId: undefined,
    btnLoading: false,
  };
};

onMounted(() => {
  formData.value = {
    ...formData.value,
    phone: userInfo.value?.phone,
    name: userInfo.value?.name,
    logoFile: userInfo.value?.logo
      ? { id: 1, path: userInfo.value?.logo, name: 'Логотип' }
      : undefined,
  };
  if (userType.value !== 1) {
    companyFormData.value = {
      logoCompanyFile: userInfo.value?.profile.logoCompany
        ? {
            id: 2,
            path: userInfo.value?.profile.logoCompany,
            name: 'Логотип компании',
          }
        : undefined,
      siteCompany: userInfo.value?.profile.siteCompany,
      titleCompany: userInfo.value?.profile.titleCompany,
      logoCompany: undefined,
    };
  }
});
</script>

<style lang="scss" scoped src="./EditProfileInfo.scss"></style>
