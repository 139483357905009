import request from '@/axios';
import { ProfileInfoApiFromBackType } from '@/api/profile/types';

export const updateProfileInfoApi = (
  data: FormData,
): Promise<IResponse<ProfileInfoApiFromBackType>> => {
  return request.post({
    url: process.env.VUE_APP_API_USER_PROFILE_UPDATE,
    data,
  });
};

export const deleteLogoApi = (): Promise<IResponse<{ message: string }>> => {
  return request.post({
    url: process.env.VUE_APP_API_USER_PROFILE_DELETE_LOGO,
  });
};

export const deleteCompanyLogoApi = (): Promise<IResponse<{ message: string }>> => {
  return request.post({
    url: process.env.VUE_APP_API_USER_PROFILE_DELETE_LOGO_COMPANY,
  });
};
