import { defineStore } from 'pinia';
import { store } from '@/store';
import { useAppStore } from '@/store/modules/app';
import { SearchParamType } from '@/api/common/types';

// 1: "Текст (короткий)",
// 2: "Число (целое)",
// 3: "Чекбокс",
// 4: "Список",
// 5: "Число (дробное)",
// 6: "Число (процентное)",
// 7: "Текст (длинный)",
// 8: "Телефон",
// 9: "Ссылка",
// 10: "Файл",
// 11: "Файлы",
// 12: "Дата (с точностью до дня)",
// 13: "Дата (с точностью до минуты)",
// 14: "Список с множественным выбором",
// 15: "Фото",
// 16: "Видео",
// 17: "Карта",
// 18: "Адрес",
// 19: "Дата (год)",
// 20: "Дата (год и месяц)",
// 21: "Выбор из группы значений",
// 22: "Категория",
// 23: "Кадастровый номер",
// 24: "Диапазон (целочисленный)",
// 25: "Диапазон (дробный)"
// 26 - "набор фоток"
// 27 - "расстояние"

export type ParamType = {
  paramId: number;
  categoryName: string;
  options: SearchParamType[];
  optionsIds: number[];
  distance?: 'km' | 'm';
};

export type GroupType = {
  id: number;
  title: string;
};

type searchRealEstateState = {
  filterCategories: {
    id: number;
    icon: string;
    title: string;
  }[];
  filterGroups: {
    categoryId: number;
    groups: GroupType[];
  }[];
  filterSubGroups: {
    groupId: number;
    parameters: ParamType[];
  }[];
};

export const useSearchRealEstateStore = defineStore('searchRealEstate', {
  state: (): searchRealEstateState => {
    return {
      filterCategories: [
        { id: 2, icon: 'holiday_village', title: 'Коттеджный поселок' },
        { id: 3, icon: 'map', title: 'Земельный участок' },
        { id: 4, icon: 'home', title: 'Дом' },
        { id: 5, icon: 'commute', title: 'Транспорт' },
        { id: 6, icon: 'landscape', title: 'Инфраструктура' },
        { id: 1, icon: 'search', title: 'Бренд и ЮЛ' },
        { id: 7, icon: 'flash_on', title: 'Иное' },
      ],
      filterGroups: [
        {
          categoryId: 1,
          groups: [
            {
              id: 1,
              title: 'Паспорт "Бренда Компании"',
            },
          ],
        },
        {
          categoryId: 2,
          groups: [
            {
              id: 2,
              title: 'О поселке',
            },
            {
              id: 22,
              title: 'Транспортная доступность',
            },
          ],
        },
        {
          categoryId: 3,
          groups: [
            {
              id: 3,
              title: 'Паспорт',
            },
            {
              id: 32,
              title: 'О земельном участке',
            },
            {
              id: 33,
              title: 'Финансовые инструменты',
            },
          ],
        },
        {
          categoryId: 4,
          groups: [
            {
              id: 41,
              title: 'Фундамент / Коробка / Кровля',
            },
            {
              id: 42,
              title: 'Инженерные коммуникации',
            },
            {
              id: 43,
              title: 'Иные постройки на территории',
            },
            {
              id: 44,
              title: 'Паспорт дома',
            },
            {
              id: 45,
              title: 'Правоустанавливающие документы дом',
            },
          ],
        },
        {
          categoryId: 5,
          groups: [
            {
              id: 5,
              title: 'Транспорт',
            },
          ],
        },
        {
          categoryId: 6,
          groups: [
            {
              id: 6,
              title: 'Инфраструктура',
            },
          ],
        },
        {
          categoryId: 7,
          groups: [
            {
              id: 7,
              title: 'Иное',
            },
          ],
        },
      ],
      filterSubGroups: [
        {
          groupId: 1,
          parameters: [
            {
              paramId: 1,
              categoryName: 'Паспорт "Бренда / Компании"',
              optionsIds: [6],
              options: [],
            },
            {
              paramId: 2,
              categoryName: 'Показатели бренда',
              optionsIds: [263],
              options: [],
            },
            {
              paramId: 3,
              categoryName: 'Реквизиты',
              optionsIds: [20, 23],
              options: [],
            },
          ],
        },
        {
          groupId: 2,
          parameters: [
            {
              paramId: 1,
              categoryName: 'Паспорт ЖК / Поселка',
              optionsIds: [224, 207, 88, 89, 92, 93, 108, 128, 133, 231, 210],
              options: [],
            },
            {
              paramId: 2,
              categoryName: 'Показатели ЖК / Поселка',
              optionsIds: [101],
              options: [],
            },
            {
              paramId: 3,
              categoryName: 'Характеристики территории ЖК / Поселка',
              optionsIds: [123, 122, 280, 129, 124, 121, 130, 132, 125],
              options: [],
            },
          ],
        },
        {
          groupId: 22,
          parameters: [
            {
              paramId: 1,
              categoryName: 'Транспортная доступность',
              optionsIds: [147, 146, 148, 151, 150, 149],
              options: [],
            },
          ],
        },
        {
          groupId: 3,
          parameters: [
            {
              paramId: 7,
              categoryName: 'Паспорт земельного участка',
              optionsIds: [
                113, 114, 115, 116, 119, 120, 219, 220, 223, 228, 261, 305, 304,
              ],
              options: [],
            },
          ],
        },
        {
          groupId: 32,
          parameters: [
            {
              paramId: 327,
              categoryName: ' О земельном участке',
              optionsIds: [170, 221, 222, 168, 172],
              options: [],
            },
          ],
        },
        {
          groupId: 33,
          parameters: [
            {
              paramId: 277,
              categoryName: 'Финансовые инструменты',
              optionsIds: [277],
              options: [],
            },
          ],
        },
        {
          groupId: 41,
          parameters: [
            {
              paramId: 457,
              categoryName: 'Фундамент / Коробка / Кровля',
              optionsIds: [239, 196, 216, 233, 234, 237, 238, 282],
              options: [],
            },
          ],
        },
        {
          groupId: 42,
          parameters: [
            {
              paramId: 458,
              categoryName: 'Инженерные коммуникации',
              optionsIds: [242, 215, 211, 209, 214, 208, 212, 213, 241, 243],
              options: [],
            },
          ],
        },
        {
          groupId: 43,
          parameters: [
            {
              paramId: 459,
              categoryName: 'Иные постройки на территории',
              optionsIds: [245, 246],
              options: [],
            },
          ],
        },
        {
          groupId: 44,
          parameters: [
            {
              paramId: 460,
              categoryName: 'Паспорт дома',
              optionsIds: [197, 273, 198, 200, 217, 260, 206, 229, 201, 240],
              options: [],
            },
          ],
        },
        {
          groupId: 45,
          parameters: [
            {
              paramId: 461,
              categoryName: 'Правоустанавливающие документы дом',
              optionsIds: [205],
              options: [],
            },
          ],
        },
        {
          groupId: 5,
          parameters: [
            {
              paramId: 7,
              categoryName: 'Транспортная доступность',
              optionsIds: [179, 178, 181, 180, 182],
              options: [],
            },
          ],
        },
        {
          groupId: 6,
          parameters: [
            {
              paramId: 7,
              categoryName: 'Объекты социального, культурного быта',
              optionsIds: [
                155, 158, 153, 160, 164, 156, 165, 157, 162, 166, 152, 161, 163,
              ],
              options: [],
            },
          ],
        },
        {
          groupId: 7,
          parameters: [
            {
              paramId: 8,
              categoryName: 'Экологичность',
              optionsIds: [141, 145, 135, 144, 140, 136, 143, 139],
              options: [],
            },
          ],
        },
      ],
    };
  },
  actions: {
    writeOptions() {
      this.filterSubGroups.forEach((subGroup, subGroupIndex) => {
        subGroup.parameters.forEach((param, paramIndex) => {
          if (param.optionsIds) {
            const options: SearchParamType[] = [];

            param.optionsIds.forEach(option => {
              const foundParam = useAppStore().getParameterById(option);

              if (foundParam) {
                options.push(foundParam);
              }
            });
            this.filterSubGroups[subGroupIndex].parameters[paramIndex].options = options;
            if (options.find(input => input.type === 27)) {
              this.filterSubGroups[subGroupIndex].parameters[paramIndex].distance = 'm';
            }
          }
        });
      });
    },
    changeDistance(groupId: number, paramId: number, value: 'km' | 'm') {
      const subGroupIndex = this.filterSubGroups.findIndex(el => el.groupId === groupId);

      if (subGroupIndex >= 0) {
        const paramIndex = this.filterSubGroups[subGroupIndex].parameters.findIndex(
          param => param.paramId === paramId,
        );

        if (paramIndex >= 0) {
          this.filterSubGroups[subGroupIndex].parameters[paramIndex].distance = value;
        }
      }
    },
  },
  getters: {
    getGroups: state => (categoryId: number | string) => {
      return state.filterGroups.find(el => el.categoryId === categoryId)?.groups;
    },
    getParameters:
      state =>
      (groupId: number): ParamType[] | undefined => {
        return state.filterSubGroups.find(el => el.groupId === groupId)?.parameters;
      },
    getDistanceByOptionId:
      state =>
      (optionId: number): 'km' | 'm' | undefined => {
        let distance: 'km' | 'm' | undefined = undefined;

        state.filterSubGroups.forEach(el => {
          const parameter = el.parameters.find(param =>
            param.optionsIds.includes(optionId),
          );

          if (parameter && parameter.distance) {
            distance = parameter.distance;

            return;
          }
        });

        return distance;
      },
  },
});

export const useAppStoreWithOut = () => {
  return useSearchRealEstateStore(store);
};
