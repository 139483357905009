<template>
  <div class="container content compare">
    <div class="compare__title">
      <h2 v-if="isNewCompare">Сравнение объявлений</h2>
      <h2 v-else-if="compareData">Сравнение объявлений: {{ compareData.name }}</h2>
      <div v-if="!isLoading" class="compare__title-actions">
        <base-button
          v-if="isNewCompare && offerCardsForComparison.length"
          style-type="coloredBorder"
          text="Сохранить"
          @click="saveCompareHandler"
        />
        <base-button
          v-if="compareData"
          :href="{ name: 'main' }"
          height="auto"
          style-type="coloredBorder"
          text="Добавить объявления"
        />
        <base-button
          v-if="useUserStore().user"
          :href="{ name: 'profile', params: { id: 'compare' } }"
          height="auto"
          style-type="coloredBorder"
          text="Список сравнений"
        />
        <base-button
          v-if="compareData"
          :text="
            useAppStore().getWindowWidth() > 600 ? 'Создать новый список' : 'Новый список'
          "
          height="auto"
          style-type="coloredBorder"
          @click="createNewCompare"
        />
      </div>
    </div>
    <div v-if="isLoading" class="container center">
      <base-spinner :size="50" color="#009D3E" />
    </div>

    <div v-else-if="offerCardsForComparison.length">
      <CompareTable
        :offerCards="offerCardsForComparison"
        @changeIsCompare="deleteCard($event)"
        @changeIsFavorite="useUserStore().changeIsFavorite($event)"
      />
    </div>
    <div v-else class="items-in-column align-center">
      <div>Добавьте объявления, чтобы сравнить</div>
      <base-button :href="{ name: 'main' }" height="40" text="К объявлениям" />
    </div>
    <base-info-modal
      :key="deleteCardDataModal.card?.id"
      :show-info-modal="deleteCardDataModal.show"
      position="top-right"
      @close="closeInfoModal()"
    >
      <div class="items-in-column">
        <div>Объявление удалено</div>
        <button
          v-if="deleteCardDataModal.card !== undefined"
          class="underline white-color"
          @click="returnCard(deleteCardDataModal.card)"
        >
          Вернуть к сравнению
        </button>
      </div>
    </base-info-modal>
    <SaveCompareModal
      :offer-cards-ids="offerCardsForComparison.map(el => el.id)"
      :show-modal="showSaveCompareModal"
      @close="showSaveCompareModal = false"
      @updateSuccessMessage="saveNewCompare"
    />
    <base-info-modal
      v-if="!!successMessage"
      :show-info-modal="!!successMessage"
      @close="successMessage = undefined"
    >
      <div>{{ successMessage }}</div>
    </base-info-modal>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, Ref, watch } from 'vue';
import { CompareType, ResultCardType } from '@/types';
import { CompareTable } from '@/components';
import SaveCompareModal from '@/components/CompareTable/SaveCompareModal/SaveCompareModal.vue';
import { useRoute, useRouter } from 'vue-router';
import { useCompareStore } from '@/store/modules/compare';
import { useUserStore } from '@/store/modules/user';
import { useAppStore } from '@/store/modules/app';

const router = useRouter();
const offerCardsForComparison: Ref<ResultCardType[]> = ref([]);
const showSaveCompareModal = ref(false);
const isLoading = ref(false);
const isNewCompare = ref(false);
const compareData: Ref<CompareType | undefined> = ref(undefined);
const successMessage: Ref<string | undefined> = ref(undefined);

const deleteCardDataModal: Ref<{
  show: boolean;
  card: ResultCardType | undefined;
}> = ref({
  show: false,
  card: undefined,
});
const changeIsCompare = (card: ResultCardType) => {
  useCompareStore().changeIsCompare(card);
};

const saveCompareHandler = () => {
  if (useUserStore().user) {
    showSaveCompareModal.value = true;
  } else {
    useAppStore().openAuthModal(true);
  }
};
const saveNewCompare = (data: {
  message: string;
  compareId: number;
  compareName: string;
}) => {
  successMessage.value = data.message;
  compareData.value = {
    id: data.compareId,
    name: data.compareName,
  };
  isNewCompare.value = false;
  router.push({ query: { id: String(data.compareId) } });
  router.push({ query: { id: String(data.compareId) } });
  useCompareStore().changeCurrentCompare(
    compareData.value,
    offerCardsForComparison.value,
  );
};

const returnCard = (card: ResultCardType) => {
  changeIsCompare(card);
  closeInfoModal();
};
const deleteCard = async (card: ResultCardType) => {
  if (isNewCompare.value) {
    changeIsCompare(card);
    deleteCardDataModal.value = {
      show: true,
      card: card,
    };
  } else {
    if (compareData.value) {
      if (offerCardsForComparison.value.length > 1) {
        deleteCardDataModal.value = {
          show: true,
          card: card,
        };
        await useCompareStore()
          .deleteHouseFromCompare(compareData.value.id, card.id)
          .then(() => {
            const compareIndex = offerCardsForComparison.value.findIndex(
              el => el.id === card.id,
            );

            if (compareIndex >= 0) {
              offerCardsForComparison.value?.splice(compareIndex, 1);
            }
          });
      } else {
        await useCompareStore()
          .deleteCompare(compareData.value.id)
          .then(() => {
            createNewCompare();
          });
      }
    }
  }
};

const closeInfoModal = async () => {
  deleteCardDataModal.value = {
    show: false,
    card: undefined,
  };
};

const createNewCompare = () => {
  offerCardsForComparison.value = [];
  isNewCompare.value = true;
  compareData.value = undefined;
  useCompareStore().changeCurrentCompare(undefined, []);
  router.push({ query: undefined });
};

const route = useRoute();
const comparisonId = computed(() => {
  return route.query.id;
});

const getCompare = async () => {
  if (comparisonId.value) {
    isLoading.value = true;
    try {
      const res = await useCompareStore().getComparisonById(+comparisonId.value);

      if (res) {
        offerCardsForComparison.value = res.found;
        compareData.value = res.comparison;
        useCompareStore().changeCurrentCompare(
          compareData.value,
          offerCardsForComparison.value,
        );
      }
    } finally {
      isLoading.value = false;
    }
  }
};

onMounted(async () => {
  if (comparisonId.value) {
    await getCompare();
  } else if (useCompareStore().compareData) {
    compareData.value = useCompareStore().compareData;
    offerCardsForComparison.value = useCompareStore().compareList;
    await router.push({ query: { id: String(compareData.value?.id) } });
  } else {
    offerCardsForComparison.value = useCompareStore().compareList;
    isNewCompare.value = true;
  }
});

watch(
  () => comparisonId.value,
  () => {
    if (comparisonId.value) {
      getCompare();
      isNewCompare.value = false;
    }
  },
);
</script>

<style lang="scss" scoped src="./Compare.scss"></style>
