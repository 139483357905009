<template>
  <div class="main">
    <BannerComponent />
    <div :class="{ show: isShowMapBlock }" class="main__map-block none-Mobile">
      <MapBlock
        :centerPointMap="centerPointMap"
        :resultCardsCoordinates="resultCardsCoordinates"
        showOfferCard
      />
      <base-button
        class="main__map-block-close"
        height="40"
        icon
        width="40"
        withoutActive
        @click="useOfferCardsStore().hideMapBlock()"
      >
        <template #icon>
          <base-icon clickable color="#ffffff" font-size="30" name="close" />
        </template>
      </base-button>
    </div>
    <div id="offerCardsList">
      <OfferCardsBlock
        v-if="(isShowResultBlock && suitableCards.length) || isLoadingResultBlock"
        v-model="activeSort"
        :isLoading="isLoadingResultBlock"
        :isMoreLoading="isMoreLoading"
        :paginationPage="paginationPage"
        :resultCards="suitableCards"
        title-with-line
        @changePageAndPageSize="changePageAndPageSize($event)"
        @hideCard="hideCard($event)"
        @sortResultBlock="sortResultBlock($event)"
      />
      <div
        v-else-if="isShowResultBlock"
        class="container center align-center mt--20 mb--20"
      >
        <div class="bubble bubble__right m-font-size">
          По вашему запросу ничего не найдено, попробуйте изменить запрос
        </div>
      </div>
    </div>

    <!--    Скрытые карточки - нереализованный функционал. Нужен Бэк-->
    <!--    <div v-if="hiddenCards.length" class="container">-->
    <!--      <base-divider />-->
    <!--    </div>-->
    <!--    <OfferCardsBlock-->
    <!--      v-if="isShowHiddenResultBlock && hiddenCards.length"-->
    <!--      :resultCards="hiddenCards"-->
    <!--      isTop-->
    <!--      title="Скрытые карточки"-->
    <!--    />-->
    <!--    <div v-if="hiddenCards.length" class="items-in-line container">-->
    <!--      <h3 v-if="!isShowHiddenResultBlock">Есть скрытые объявления</h3>-->

    <!--      <base-button-->
    <!--        :text="isShowHiddenResultBlock ? 'Скрыть' : 'Отобразить'"-->
    <!--        height="36"-->
    <!--        width="165"-->
    <!--        @click="isShowHiddenResultBlock = !isShowHiddenResultBlock"-->
    <!--      />-->
    <!--    </div>-->
    <!--    <div v-if="hiddenCards.length" class="container">-->
    <!--      <base-divider />-->
    <!--    </div>-->
    <div v-if="similarCards.length || isSimilarLoading">
      <OfferCardsBlock
        :isLoading="isSimilarLoading"
        :resultCards="similarCards"
        class="main__top-block"
        isTop
        title="Похожие объявления"
        title-with-line
      />
    </div>
    <div v-if="activeFilterBtn" class="mt--20">
      <div v-for="(topBlock, i) in activeFilterBtn?.topBlocks" :key="i">
        <OfferCardsBlock
          v-if="topBlock.data.length"
          :isLoading="isLoadingTopBlock"
          :resultCards="topBlock.data"
          :title="topBlock.title"
          class="main__top-block"
          isTop
          title-with-line
        />
      </div>
    </div>
    <div v-else>
      <div v-for="i in 2" :key="i">
        <OfferCardsBlock
          :isLoading="true"
          :result-cards="[]"
          class="main__top-block"
          isTop
        />
      </div>
    </div>

    <base-info-modal
      :key="showInfoModalKey"
      :show-info-modal="showInfoModal"
      position="top-right"
      @close="showInfoModal = false"
    >
      <div class="items-in-column">
        <div>Добавлено к сравнению</div>
        <router-link :to="{ name: 'compare' }" class="underline"
          >Перейти к сравнению
        </router-link>
      </div>
    </base-info-modal>
  </div>
</template>

<script lang="ts" setup>
import qs from 'qs';
import { computed, inject, onBeforeMount, onMounted, Ref, ref, watch } from 'vue';
import { useOfferCardsStore } from '@/store/modules/offerCards';
import { ResultCardType } from '@/types';
import { MapBlock } from '@/components';
import { OfferCardsBlock } from '@/components/Cards';
import { BannerComponent } from '@/components/MainPageComponents';
import { OfferCardCoordinatesType, SortParamsDataType } from '@/api/house/types';
import { useRoute, useRouter } from 'vue-router';
import { useAppStore } from '@/store/modules/app';
import { SmoothScrollOptions } from 'vue3-smooth-scroll';

type ParamsByQueryType = {
  [key: string]: {
    value?: string;
    min?: string;
    max?: string;
  };
};

const resultCardsCoordinates = computed((): OfferCardCoordinatesType[] => {
  return useOfferCardsStore().offerCardsCoordinates;
});

const suitableCards = computed((): ResultCardType[] => {
  return useOfferCardsStore().offerCards;
});
const similarCards = computed((): ResultCardType[] => {
  return useOfferCardsStore().similarCards;
});
const centerPointMap = computed(() => {
  return useOfferCardsStore().centerPointMap;
});
const isShowMapBlock = computed(() => {
  return useOfferCardsStore().isShowMapBlock;
});
const activeFilterBtn = computed(() => {
  return useOfferCardsStore().activeFilterBtn;
});
const showInfoModal: Ref<boolean> = ref(false);
const showInfoModalKey: Ref<number> = ref(1);

const hideCard = (id: number) => {
  console.log('Скрыть карточку: ' + id);
};
const activeSort: Ref<number | undefined> = ref(undefined);

const paginationPage = ref(1);

const searchOfferCardsData = computed(() => {
  return useOfferCardsStore().searchOfferCardsData;
});
const isShowResultBlock = computed(() => {
  return useOfferCardsStore().isShowResultBlock;
});
const isMoreLoading = computed(() => {
  return useOfferCardsStore().isMoreLoading;
});
const isLoadingResultBlock = computed(() => {
  return useOfferCardsStore().isLoadingResultBlock;
});
const isSimilarLoading = computed(() => {
  return useOfferCardsStore().isSimilarLoading;
});

const router = useRouter();
const route = useRoute();

const sortResultBlock = (id: number) => {
  const getParameterId = () => {
    switch (id) {
      case 1:
      case 2:
        return 203;
      case 3:
      case 4:
        return 199;
      default:
        return 116;
    }
  };

  const getCategoryId = () => {
    switch (id) {
      case 1:
      case 2:
      case 3:
      case 4:
        return 19;
      default:
        return 12;
    }
  };

  const getAscDirection = () => {
    switch (id) {
      case 1:
      case 3:
        return true;
      default:
        return false;
    }
  };

  const sortParams: SortParamsDataType = [
    {
      category_id: getCategoryId(),
      parameter_id: getParameterId(),
      field: null,
      ascDirection: getAscDirection(),
    },
  ];

  paginationPage.value = 1;
  useOfferCardsStore().updateSortParam(JSON.stringify(sortParams));
  useOfferCardsStore().getOfferCards();
};

const changePageAndPageSize = (data: {
  page: number;
  pageSize: number;
  showMore?: boolean;
}) => {
  if (
    data.pageSize !== searchOfferCardsData.value.pageSize ||
    data.page !== searchOfferCardsData.value.page
  ) {
    paginationPage.value = data.page;
    useOfferCardsStore().updatePageSizeParams(data.page, data.pageSize);
    useOfferCardsStore().getOfferCards(data.showMore);
  }
};
const isLoadingTopBlock: Ref<boolean> = ref(false);

const getParamsByUrl = () => {
  // Достаем значения фильтров из URL
  const queryParams = route?.query.search as string | undefined;

  if (queryParams) {
    const inputsParams = qs.parse(queryParams) as ParamsByQueryType;

    Object.entries(inputsParams).forEach(param => {
      useOfferCardsStore().updateParameter(
        param[0],
        param[1]?.value ?? null,
        param[1]?.min ?? null,
        param[1]?.max ?? null,
      );
    });
  }

  // Если параметров больше 0, то отображаем результат
  if (Object.keys(useOfferCardsStore().params).filter(el => el !== '306').length) {
    useOfferCardsStore().updateSearchOfferCardsData();
    useOfferCardsStore().getOfferCards();
  }
};

onBeforeMount(async () => {
  getParamsByUrl();
  if (!useOfferCardsStore().filterBtnsByType.length) {
    await useOfferCardsStore().getTopHouse();
  } else {
    useOfferCardsStore().getActiveFilterBtn();
  }
});
const smoothScroll = inject('smoothScroll') as (args: SmoothScrollOptions) => void;

onMounted(() => {
  const hash = route?.hash;

  if (hash === '#list') {
    const myEl = document.getElementById('offerCardsList');

    if (myEl) {
      smoothScroll({
        scrollTo: myEl,
        offset: 0,
        updateHistory: false,
      });
    }
    useOfferCardsStore().showResultBlock();
  }
});

watch(
  () => useAppStore().regions_for_select.defaultForUser,
  async () => {
    await useOfferCardsStore().getTopHouse();
    if (isShowResultBlock.value) {
      useOfferCardsStore().updateSearchOfferCardsData();

      await useOfferCardsStore().getOfferCards();
    }
  },
);
</script>

<style lang="scss" scoped src="./Main.scss"></style>
