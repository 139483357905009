<template>
  <ul class="cloud">
    <li><a data-weight="10" href="#">Семейная</a></li>
    <li><a data-weight="3" href="#">it</a></li>
    <li><a data-weight="5" href="#">Сельская</a></li>
    <li><a data-weight="8" href="#">Под залог</a></li>
    <li><a data-weight="6" href="#">Рефинансирование</a></li>
    <li><a data-weight="4" href="#">Потребительский</a></li>
    <li><a data-weight="5" href="#">Строим дом</a></li>
    <li><a data-weight="6" href="#">Льготная</a></li>
    <li><a data-weight="2" href="#">Сельская</a></li>
    <li><a data-weight="7" href="#">Садовый дом</a></li>
    <li><a data-weight="3" href="#">Жилой дом</a></li>
    <li><a data-weight="7" href="#">Новостройка</a></li>
    <li><a data-weight="4" href="#">Земля. ИЖС</a></li>
    <li><a data-weight="1" href="#">Под залог</a></li>
    <li><a data-weight="3" href="#">Земля. Садоводство</a></li>
  </ul>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped src="./TagCloud.scss"></style>
