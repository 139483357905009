<template>
  <base-menu-with-tabs
    :activeComponent="activeComponentBtnId"
    :componentBtns="componentBtns"
    bgColor="#fff"
    withRadius
    @changeActiveBtn="changeActiveBtn($event)"
  >
    <template #content="contentProps">
      <div class="menuWithTabsContent">
        <AnnouncementList :type="activeComponentBtnId" />
      </div>
    </template>
  </base-menu-with-tabs>
</template>

<script lang="ts" setup>
import { BtnsForTabsMenuType } from '@/types';
import { computed, onMounted, ref, Ref, watch } from 'vue';
import AnnouncementList from '@/components/ProfileComponents/MyAnnouncements/AnnouncementList/AnnouncementList.vue';
import { useRoute, useRouter } from 'vue-router';

const componentBtns: BtnsForTabsMenuType[] = [
  { id: 'active', title: 'Активные' },
  { id: 'drafts', title: 'Черновики' },
  { id: 'archive', title: 'Архив' },
];

const activeComponentBtnId: Ref<undefined | number | string> = ref(undefined);
const router = useRouter();
const route = useRoute();
const changeActiveBtn = (btnId: number | string) => {
  router.push({ params: { id: 'myAnnouncements' }, query: { menuItem: btnId } });
  activeComponentBtnId.value = btnId;
};
const activeBtnId = computed(() => {
  return route?.query.menuItem;
});

onMounted(() => {
  if (activeBtnId.value && !!componentBtns.find(el => el.id === activeBtnId.value)) {
    changeActiveBtn(`${activeBtnId.value}`);
  } else {
    changeActiveBtn(componentBtns[0].id);
  }
});
watch(
  () => activeBtnId.value,
  () => {
    if (activeBtnId.value && !!componentBtns.find(el => el.id === activeBtnId.value)) {
      activeComponentBtnId.value = `${activeBtnId.value}`;
    }
  },
);
</script>

<style lang="scss" scoped src="./MyAnnouncements.scss"></style>
