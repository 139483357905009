<template>
  <div class="pagination">
    <button
      :class="style"
      :disabled="disabled || modelValue === 1"
      class="pagination__button start"
      @click="changePage(1)"
    >
      В начало
    </button>
    <button
      v-if="showPrevNext"
      :class="style"
      :disabled="disabled || modelValue === 1"
      class="pagination__button"
      @click="toPrev"
    >
      <
    </button>

    <button
      v-if="
        showFirstLast &&
        totalVisible &&
        modelValue > Math.ceil(totalVisible / 2) + 1 &&
        length > totalVisible
      "
      :class="style"
      :disabled="disabled"
      class="pagination__button"
      @click="changePage(1)"
    >
      1
    </button>

    <span
      v-if="
        showFirstLast &&
        totalVisible &&
        modelValue > Math.ceil(totalVisible / 2) + 1 &&
        length > totalVisible + 1
      "
      class="pagination__gap"
    >
      ...
    </span>

    <button
      v-for="page in visiblePages"
      :key="page"
      :class="{ active: modelValue === page, simple: style === 'simple' }"
      :disabled="disabled"
      class="pagination__button"
      @click="changePage(page)"
    >
      {{ page }}
    </button>

    <span
      v-if="
        showFirstLast &&
        totalVisible &&
        modelValue < length - Math.ceil(totalVisible / 2) &&
        length > totalVisible + 1
      "
      class="pagination__gap"
    >
      ...
    </span>

    <button
      v-if="
        showFirstLast &&
        totalVisible &&
        modelValue <= length - Math.ceil(totalVisible / 2) &&
        length > totalVisible
      "
      :class="{ active: modelValue === length, simple: style === 'simple' }"
      :disabled="disabled"
      class="pagination__button"
      @click="changePage(length)"
    >
      {{ length }}
    </button>

    <button
      v-if="showPrevNext"
      :class="style"
      :disabled="disabled || modelValue === length"
      class="pagination__button"
      @click="toNext"
    >
      >
    </button>
    <button
      :class="style"
      :disabled="disabled || modelValue === length"
      class="pagination__button end"
      @click="changePage(length)"
    >
      В конец
    </button>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    /** Количество страниц */
    length: number;
    /** Максимальное количество видимых страниц */
    totalVisible?: number;
    /** Текущая страница */
    modelValue: number;
    /** Отображать первую и последнюю страницы */
    showFirstLast?: boolean;
    /** Отображать иконки "назад" и "вперед" */
    showPrevNext?: boolean;
    /** Стиль */
    style?: 'base' | 'simple';
  }>(),
  {
    disabled: false,
    modelValue: 1,
    showFirstLast: false,
    showPrevNext: false,
    style: 'base',
  },
);

const emit = defineEmits(['update:modelValue']);

const visiblePages = computed<number[]>(() => {
  if (props.totalVisible === undefined) return [];

  let startPage: number;
  let endPage: number;

  if (!Number.isInteger(props.totalVisible) || props.length <= props.totalVisible) {
    startPage = 1;
    endPage = props.length;
  } else {
    const pagesBeforeCurrentPage = Math.floor(props.totalVisible / 2);
    const pagesAfterCurrentPage = Math.ceil(props.totalVisible / 2) - 1;

    if (props.modelValue <= pagesBeforeCurrentPage) {
      startPage = 1;
      endPage = props.totalVisible;
    } else if (props.modelValue + pagesAfterCurrentPage >= props.length) {
      startPage = props.length - props.totalVisible + 1;
      endPage = props.length;
    } else {
      startPage = props.modelValue - pagesBeforeCurrentPage;
      endPage = props.modelValue + pagesAfterCurrentPage;
    }
  }

  return Array.from(Array(endPage + 1 - startPage).keys()).map(i => startPage + i);
});

const changePage = (pageNumber: number) => {
  emit('update:modelValue', pageNumber);
};

const toPrev = () => {
  changePage(props.modelValue - 1);
};

function toNext() {
  changePage(props.modelValue + 1);
}
</script>

<style lang="scss" scoped src="./BasePagination.scss"></style>
