<template>
  <base-modal
    :fullScreen="useAppStore().getWindowWidth() < 600"
    :show-modal="showImageModal"
    :show-padding="false"
    close-outside
    @close="showImageModal = false"
  >
    <template #title>
      <slot name="title"></slot>
      <div v-if="!$slots.title">
        <h3 class="image-modal__top-block-title">{{ title }}</h3>
      </div>
    </template>
    <template #content>
      <div class="image-modal__img-carousel">
        <base-image-gallery
          :navigation="true"
          :slides-group="[{ id: 'modalImageSlides', title: '', slides: images }]"
          :space-between="5"
        >
          <template #item="itemProps">
            <base-image
              :name="String(itemProps)"
              :src="itemProps.data.item"
              border-radius
              class="image-modal__img-carousel-item-img"
              contain
              height="100%"
              width="100%"
            />
          </template>
        </base-image-gallery>
      </div>
    </template>
    <template #bottom-box>
      <slot name="bottom-box"></slot>
    </template>
  </base-modal>
</template>

<script lang="ts" setup>
import { useAppStore } from '@/store/modules/app';

const props = withDefaults(
  defineProps<{
    images: string[];
    title?: string;
    phone?: string;
  }>(),
  {},
);
// eslint-disable-next-line no-undef
const showImageModal = defineModel<boolean>();
</script>

<style lang="scss" scoped src="./ImageModal.scss"></style>
