import request from '@/axios';
import { SendComplainDataType } from '@/types';

export const sendComplainApi = (
  data: SendComplainDataType,
): Promise<
  IResponse<{
    message: string;
  }>
> => {
  return request.post({ url: process.env.VUE_APP_API_CLIENT_COMPLAINT_SEND, data });
};
