<template>
  <div v-if="showRates">
    <div class="mortgage-block__cards mb--10">
      <button
        v-for="(mortgage, i) in mortgageData"
        :key="i"
        class="align-left"
        @click="activeMortgageCard = i"
      >
        <base-card
          :active="activeMortgageCard === i"
          class="mortgage-block__card pointer"
          scale
        >
          <div class="medium textHidden">{{ mortgage.title }}</div>
          <div v-if="mortgage.error">-</div>
          <div v-else class="mortgage-block__card-info">
            <span> от </span>
            <span class="blue-color">{{ mortgage.minRate || '-' }}%</span>
            <base-divider color="#000" inset="80%" vertical />
            <span
              >{{
                mortgage.minPayment ? mortgage.minPayment?.toLocaleString() : '-'
              }}
              ₽</span
            >
          </div>
        </base-card>
      </button>
    </div>
  </div>
  <div class="mortgage-block__widget">
    <MortgageWidget />
  </div>
</template>

<script lang="ts" setup>
import { BaseCard, BaseDivider } from '@/components/UI';
import { computed, ref } from 'vue';
import { useAppStore } from '@/store/modules/app';
import { RateType } from '@/types/MainPageTypes';
import { MortgageWidget } from '@/components';

const props = withDefaults(
  defineProps<{
    mortgage?: RateType[];
    showRates?: boolean;
  }>(),
  {
    showRates: true,
  },
);
const mortgageData = computed((): RateType[] => {
  if (props.mortgage) {
    return props.mortgage;
  }

  return [
    {
      title: 'ИТ ипотека',
      minRate: 5.1,
      minPayment: 32246,
    },
    {
      title: useAppStore().getWindowWidth() > 600 ? 'Семейная ипотека' : 'Семейная ип.',
      minRate: 6.5,
      minPayment: 56387,
    },
    {
      title:
        useAppStore().getWindowWidth() > 600 ? 'Гос. поддержка ИЖС' : 'Гос. под. ИЖС',
      minRate: 13.9,
      minPayment: 125898,
    },
    {
      title: 'Стандартная',
      minRate: 16.9,
      minPayment: 158452,
    },
  ];
});
const activeMortgageCard = ref(1);
</script>

<style lang="scss" scoped src="./MortgageBlock.scss"></style>
