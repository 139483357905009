<template>
  <div class="container content not-found items-in-column">
    <div class="xl-font-size medium">Страница не найдена</div>
    <div>Возможно, у вас опечатка в адресе страницы, или её просто не существует :(</div>
    <base-button :href="{ name: 'main' }" text="На главную" />
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import { useAppStore } from '@/store/modules/app';

const route = useRoute();

watch(
  () => route.name,
  () => {
    useAppStore().togglePageNotFound(false);
  },
);
</script>

<style lang="scss" scoped>
.not-found {
  margin-top: 100px;
  align-items: center;
  text-align: center;
}
</style>
