<template>
  <teleport v-if="showInfoModal" to="#modalWindow">
    <div :class="{ 'top-right': position === 'top-right', isError }" class="alert">
      <slot></slot>
      <base-icon
        class="alert__close-icon"
        clickable
        color="#ffffff"
        font-size="20"
        name="close"
        @click="closeInfoModal()"
      />
      <div :class="{ isError }" class="alert__progress" />
      <div class="alert__progress-animation" />
    </div>
  </teleport>
</template>

<script lang="ts" setup>
import { onMounted, ref, Ref, toRefs } from 'vue';

const props = withDefaults(
  defineProps<{
    showInfoModal?: boolean;
    position?: 'top-right' | 'bottom-left';
    isError?: boolean;
  }>(),
  {
    position: 'bottom-left',
  },
);

const emit = defineEmits(['close']);

const timer: Ref<number | undefined> = ref(undefined);
const closeInfoModal = () => {
  emit('close');
};
const { showInfoModal } = toRefs(props);

onMounted(() => {
  clearInterval(timer.value);
  timer.value = undefined;
  if (showInfoModal) {
    timer.value = setTimeout(() => {
      closeInfoModal();
    }, 5000);
  }
});
</script>

<style lang="scss" scoped src="./BaseInfoModal.scss"></style>
