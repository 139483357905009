<template>
  <base-modal
    :show-modal="deleteCategoryModalData.show"
    hide-title
    @close="closeDeleteCategoryModal"
  >
    <template #content>
      <div class="m-font-size">Вы уверены?</div>
    </template>
    <template #btn>
      <div class="items-in-line">
        <base-button
          height="40"
          style-type="coloredBorder"
          text="Отмена"
          width="80"
          @click="closeDeleteCategoryModal"
        />
        <base-button
          :loading="idDeleteBtnLoading"
          height="40"
          text="Да"
          width="80"
          @click="deleteCategory()"
        />
      </div>
    </template>
  </base-modal>
</template>

<script lang="ts" setup>
import { deleteCategoryValueApi } from '@/api/developer';
import { ref } from 'vue';
import { DeleteCategoryModalType } from '@/types';

const props = defineProps<{
  deleteCategoryModalData: DeleteCategoryModalType;
}>();
const emit = defineEmits(['deleteCategory', 'close']);

const idDeleteBtnLoading = ref(false);

const closeDeleteCategoryModal = () => {
  emit('close');
};
const deleteCategory = async () => {
  if (props.deleteCategoryModalData.id) {
    idDeleteBtnLoading.value = true;
    try {
      const res = await deleteCategoryValueApi(props.deleteCategoryModalData.id);

      if (res) {
        emit('deleteCategory', props.deleteCategoryModalData.id);
      }
    } finally {
      idDeleteBtnLoading.value = false;
      emit('close', props.deleteCategoryModalData.id);
    }
  }
};
</script>

<style lang="scss" scoped></style>
