<template>
  <div v-if="userInfo" class="profile-info">
    <div class="profile-info__about">
      <div class="profile-info__photo">
        <base-image
          :contain="!userInfo.logo"
          :cover="!!userInfo.logo"
          :height="userInfo.logo ? '80' : '50'"
          :src="userInfo.logo || require('@/assets/img/profile/avatar.png')"
          :width="userInfo.logo ? '80' : '50'"
          name="ava"
        />
      </div>
      <div class="profile-info__about-main-info">
        <div class="medium mb--15">{{ userInfo.name || 'Имя пользователя' }}</div>
        <div class="mb--15 items-in-line flex-start">
          <span>
            <base-image
              :src="require('@/assets/img/profile/icon-tel.png')"
              class="tertiaryLightColorFilter"
              contain
              height="21"
              name="tel"
              width="21" /></span
          >{{ preparePhone(userInfo.phone) || '-' }}
        </div>
        <div class="mb--15 items-in-line flex-start">
          <span>
            <base-image
              :src="require('@/assets/img/profile/icon-mail.png')"
              class="tertiaryLightColorFilter"
              contain
              height="21"
              name="mail"
              width="21" /></span
          >{{ userInfo.email || '-' }}
        </div>
        <div class="mb--15">
          <span class="medium">Роль: </span><span>{{ useAppStore().getType() }}</span>
        </div>
        <div class="mb--15">
          <span class="medium">Город: </span><span>{{ userInfo.region }}</span>
        </div>
        <button class="gradient-color mb--15" @click="showChangePassModal = true">
          Сменить пароль
        </button>
        <div>
          <span class="medium">ID: </span><span>{{ userInfo.id }}</span>
        </div>
      </div>
      <base-divider
        v-show="userType !== 1"
        class="profile-info__about-divider"
        vertical
      />
      <div v-show="userType !== 1" class="profile-info__about-description">
        <div class="profile-info__company-name mb--15">
          <span class="medium">Название компании: </span>
          <span>{{ userInfo.profile.titleCompany || '-' }}</span>
        </div>
        <div class="profile-info__company-name mb--15">
          <span class="medium">Сайт компании: </span>
          <a :href="userInfo.profile.siteCompany" target="_blank">{{
            userInfo.profile.siteCompany || '-'
          }}</a>
        </div>
        <div v-if="userInfo.profile.logoCompany" class="items-in-column flex-start">
          <span class="medium">Логотип: </span>
          <div class="center allBlockWidth">
            <base-image
              :src="userInfo.profile.logoCompany"
              class="profile__content-avatar"
              contain
              height="80"
              name="ava"
              width="150"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="profile-info__btns">
      <base-button
        :href="{ query: { menuItem: 'editProfile' } }"
        height="36"
        text="Редактировать"
        width="191"
      />
    </div>
  </div>
  <base-modal
    :show-modal="showChangePassModal"
    hide-title
    @close="showChangePassModal = false"
  >
    <template #content>
      <ChangePassword @close="showSuccessChangePassInfo" />
    </template>
  </base-modal>
  <base-modal
    :show-modal="showSuccessInfoModal"
    close-outside
    hide-titles
    @close="showSuccessInfoModal = false"
  >
    <template #content>
      <div class="grid--1 gap--20 mb--20">
        <div class="font-size-title-block">Пароль успешно изменен</div>
        <div class="center">
          <base-button height="40" text="Ок" @click="goToLogin" />
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import ChangePassword from '@/components/AuthComponents/ChangePassword.vue';
import { useRouter } from 'vue-router';
import { UserType, useUserStore } from '@/store/modules/user';
import { preparePhone } from '@/mixins/Common/Common';
import { useAppStore } from '@/store/modules/app';

const showChangePassModal = ref(false);
const showSuccessInfoModal = ref(false);
const router = useRouter();

const userInfo = computed((): UserType | undefined => {
  return useUserStore().user;
});
const showSuccessChangePassInfo = () => {
  showChangePassModal.value = false;
  showSuccessInfoModal.value = true;
};

const goToLogin = () => {
  showSuccessInfoModal.value = false;
  useAppStore().openAuthModal();
  router.push({ name: 'main' });
  useUserStore().writeUserInfo(null);
};

const userType = computed((): number | undefined => {
  return useUserStore().user?.type;
});

onMounted(() => {
  if (!userInfo.value) {
    useAppStore().openAuthModal();
    router.push({ name: 'main' });
  }
});
</script>

<style lang="scss" scoped src="./ProfileInfo.scss"></style>
