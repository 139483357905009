import request from '@/axios';
import { FavoriteHousesType } from '@/api/favorite/types';

export const createFavoriteHouseApi = (
  cardId: number,
): Promise<IResponse<{ message: string }>> => {
  return request.post({
    url: process.env.VUE_APP_API_FAVORITE_HOUSE_CREATE,
    params: {
      id: cardId,
    },
  });
};
export const deleteFavoriteHouseApi = (
  cardId: number,
): Promise<IResponse<{ message: string }>> => {
  return request.post({
    url: process.env.VUE_APP_API_FAVORITE_HOUSE_DELETE,
    params: {
      id: cardId,
    },
  });
};

export const getFavoriteHousesApi = (): Promise<IResponse<FavoriteHousesType>> => {
  return request.get({
    url: process.env.VUE_APP_API_FAVORITE_HOUSE_USER_FAVORITES,
  });
};
