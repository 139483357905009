<template>
  <div class="compare-table">
    <div
      ref="topBlock"
      class="compare-table__main-block topBlock"
      @scroll="scrollBlock(topBlock)"
    >
      <div v-for="card in offerCards" :key="card.id" class="compare-table__one-card">
        <div class="mb--20">
          <div>
            <div class="compare-table__img-box">
              <div class="compare-table__img-box-img">
                <base-image
                  :name="String(card.id)"
                  :src="card.img ? card.img[0] : undefined"
                  borderRadius
                  cover
                  height="100%"
                  width="100%"
                />
              </div>

              <div class="compare-table__img-box-delete-icon">
                <base-button
                  icon
                  style-type="translucent"
                  withoutActive
                  @click="$emit('changeIsCompare', card)"
                >
                  <template #icon>
                    <base-icon color="#ffffff" font-size="20" name="delete" />
                  </template>
                </base-button>
              </div>
            </div>
            <div class="compare-table__info-box items-in-column gap--5 mt--10">
              <div class="medium">
                {{ card.price ? card.price.toLocaleString() : '-' }} ₽
              </div>
              <div class="compare-table__info-box-address">
                {{ card.address }}
              </div>
            </div>
          </div>
          <div class="compare-table__activities-box">
            <div class="items-in-column gap--10">
              <PhoneButton
                :phone="card.phone"
                :phone-modal="useAppStore().getWindowWidth() <= 600"
                height="36"
              />
              <base-button
                all-block-width
                class="active-card__infoBlock-chat"
                height="36"
                style-type="coloredBorder"
                text="Написать"
                @click="useChatStore().openMiniMessenger(card)"
              />
            </div>
            <div>
              <div class="items-in-column gap--10">
                <base-button
                  height="36"
                  icon
                  style-type="border"
                  width="36"
                  withoutActive
                  @click="$emit('changeIsFavorite', card.landId)"
                >
                  <template #icon>
                    <base-icon
                      :color="
                        useUserStore().getIsFavorite(card.landId) ? '#EA1F1F' : '#969696'
                      "
                      font-size="20"
                      name="favorite"
                      width="36"
                    />
                  </template>
                </base-button>
                <base-button
                  height="36"
                  icon
                  style-type="border"
                  width="36"
                  withoutActive
                >
                  <template #icon>
                    <img
                      :src="require('@/assets/img/mainPage/icons/icon-pdf.png')"
                      alt="pdf"
                    />
                  </template>
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isVisible" class="compare-table__top-small-block">
      <div
        ref="smallTopBlock"
        class="container compare-table__main-block smallTopBlock"
        @scroll="scrollBlock(smallTopBlock)"
      >
        <div v-for="card in offerCards" :key="card.id" class="compare-table__one-card">
          <div class="flex gap--10">
            <div class="compare-table__top-small-block-img">
              <base-image
                :name="String(card.id)"
                :src="card.img ? card.img[0] : undefined"
                borderRadius
                cover
                height="100%"
                width="100%"
              />
            </div>

            <div class="items-in-column gap--5">
              <div class="compare-table__top-small-block-title">
                {{ card.price.toLocaleString() }} ₽
              </div>
              <div class="third-color compare-table__top-small-block-info">
                {{ card.address }}
              </div>
              <div class="items-in-line none-Mobile">
                <PhoneButton :phone="card.phone" height="30" phone-modal />
                <base-button
                  height="30"
                  style-type="coloredBorder"
                  text="Чат"
                  @click="useChatStore().openMiniMessenger(card)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      ref="contentBlock"
      class="compare-table__main-block contentBlock"
      @scroll="scrollBlock(contentBlock)"
    >
      <div class="compare-table__main-block-container">
        <base-expansion-panels
          v-for="(item, i) in categories"
          :key="i"
          :is-open="true"
          isMobileCollapse
          styleType="with-line"
        >
          <template #title="titleProps">
            <span class="m-font-size medium"> {{ item.title }} </span>
          </template>
          <template #content="contentProps">
            <div class="mb--20">
              <div
                v-for="(param, i) in item.params"
                :key="i"
                class="compare-table__param"
              >
                <div class="third-color compare-table__param-title">
                  {{ param.paramTitle }}
                </div>
                <div class="compare-table__param-value">
                  <div
                    v-for="card in offerCards"
                    :key="card.id"
                    class="compare-table__one-card"
                  >
                    <span v-if="card[param.key] !== undefined">
                      <span v-if="param.type === 'number'">
                        {{ card[param.key].toLocaleString() }}</span
                      >
                      <span v-else-if="param.type === 'map'">
                        <MapBlock
                          :center-point-map="card[param.key]"
                          :result-cards-coordinates="[
                            { id: card.id, coordinates: card[param.key] },
                          ]"
                          :zoom="16"
                          height="200"
                        />
                      </span>
                      <span v-else-if="param.type === 'classifier'">
                        <base-rating-number
                          :max-rate="card.classifier?.maxRate"
                          :rating="card.rating"
                        />
                      </span>
                      <span v-else> {{ card[param.key] }}</span>
                    </span>
                    <span v-else class="four-color">не указано</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </base-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ResultCardType } from '@/types';
import { ref, watch } from 'vue';
import { MapBlock, PhoneButton } from '@/components';
import { useElementVisibility } from '@vueuse/core';
import { useUserStore } from '@/store/modules/user';
import { useChatStore } from '@/store/modules/chat';
import { useAppStore } from '@/store/modules/app';

type CategoryType = {
  title: string;
  params: {
    paramTitle: string;
    key: string;
    type?: 'number' | 'map' | 'classifier';
  }[];
};

const props = withDefaults(
  defineProps<{
    offerCards: ResultCardType[];
  }>(),
  {},
);
const topBlock = ref<HTMLInputElement>();
const contentBlock = ref<HTMLInputElement>();
const smallTopBlock = ref<HTMLInputElement>();

const isVisible = useElementVisibility(topBlock);

const scrollBlock = (block: HTMLInputElement | undefined) => {
  if (block) {
    const scrollSize: number = block.scrollLeft;

    const contentBlockValue = contentBlock.value;
    const smallTopBlockValue = smallTopBlock.value;
    const topBlockValue = topBlock.value;

    if (contentBlockValue) {
      contentBlockValue.scrollLeft = scrollSize;
    }
    if (smallTopBlockValue) {
      smallTopBlockValue.scrollLeft = scrollSize;
    }
    if (topBlockValue) {
      topBlockValue.scrollLeft = scrollSize;
    }
  }
};

const categories: CategoryType[] = [
  {
    title: 'Основная информация',
    params: [
      {
        paramTitle: 'Цена, ₽',
        key: 'price',
        type: 'number',
      },
      {
        paramTitle: 'Этаж',
        key: 'floor',
      },
      {
        paramTitle: 'Классификатор',
        type: 'classifier',
        key: 'rating',
      },
    ],
  },
  {
    title: 'Информация о доме',
    params: [
      {
        paramTitle: 'Площадь, м²',
        key: 'houseArea',
      },
    ],
  },
  {
    title: 'Информация о земле',
    params: [
      {
        paramTitle: 'Участок, сот.',
        key: 'landArea',
      },
      {
        paramTitle: 'Тип',
        key: 'type',
      },
    ],
  },
  {
    title: 'Расположение',
    params: [
      {
        paramTitle: 'Адрес',
        key: 'address',
      },
      {
        paramTitle: 'На карте',
        key: 'coordinates',
        type: 'map',
      },
    ],
  },
];

watch(
  () => smallTopBlock.value,
  () => {
    scrollBlock(contentBlock.value);
  },
);
</script>

<style lang="scss" scoped src="./CompareTable.scss"></style>
