<template>
  <div v-if="loading">
    <base-skeleton-loader type="input" />
  </div>
  <div
    v-else
    :class="styleType"
    :style="[
      cssProps,
      {
        height: height + 'px',
      },
    ]"
    class="inputBox"
  >
    <base-tooltip :active="tooltipActive" :show-in-mobile="false" :text="label">
      <span
        v-if="labelInValue"
        :class="{ isFocusInput }"
        class="inputBox__labelInValue"
        @click="focusInput"
      >
        {{ label }}
      </span>
      <span
        v-if="prefix"
        :class="{ isFocusInput, invalid }"
        class="inputBox__labelInValue"
        >{{ inputValue || isFocusInput ? prefix : '' }}</span
      >
      <span
        v-if="
          withBg &&
          !isFocusInput &&
          inputValue !== undefined &&
          inputValue !== null &&
          inputValue !== ''
        "
        class="inputBox__value"
        @click="changeFocus(true)"
      >
        <span class="textHidden">{{ prefix }}{{ inputValue }}</span></span
      >

      <input
        ref="element"
        v-model="inputValue"
        :class="[
          { labelInValue: labelInValue || prefix },
          { filledInput: filled },
          { error: isError || errorMessage },
          { invalid },
          { valid: inputValue },
          { paddingRight: type === 'password' },
        ]"
        :data-maska="dataMaska"
        :disabled="disabled"
        :max="max"
        :min="min"
        :placeholder="placeholder"
        :required="required"
        :type="inputType"
        @blur="changeFocus(false)"
        @focus="changeFocus(true)"
        v-on="listeners"
      />

      <span
        v-if="!labelInValue"
        ref="inputLabel"
        :class="{ topMore: withBg }"
        class="inputBox__label"
        >{{ label }}</span
      >

      <span v-if="type === 'number' && !hideArrows" class="inputBox__arrows">
        <span :style="[{ height: '10px' }]">
          <base-icon
            :class="{ disabled: !isStepUpAllowed }"
            :disabled="!isStepUpAllowed"
            class="step-up"
            clickable
            height="10"
            name="arrow_drop_up"
            tabindex="-1"
            width="10"
            @click="stepUp"
          />
        </span>
        <span :style="[{ height: '10px' }]">
          <base-icon
            :class="{ disabled: !isStepDownAllowed }"
            :disabled="!isStepDownAllowed"
            class="step-down"
            clickable
            height="10"
            name="arrow_drop_down"
            tabindex="-1"
            width="10"
            @click="stepDown"
          />
        </span>
      </span>
      <span
        v-if="isError || errorMessage?.length || error?.length"
        class="inputBox__error"
        >{{ errorMessage || error }}</span
      >
      <span v-if="type === 'password'" class="inputBox__append-icon">
        <base-icon
          :height="height"
          :name="showPassword ? 'visibility' : 'visibility_off'"
          clickable
          color="#888298"
          width="21"
          @click="showPassword = !showPassword"
        />
      </span>
      <span
        v-if="
          !isFocusInput &&
          inputValue !== undefined &&
          inputValue !== null &&
          inputValue !== ''
        "
      >
        <span class="inputBox__append-icon">
          <slot name="appendIcon" />
        </span>

        <span v-if="!$slots.appendIcon && appendIcon" class="inputBox__append-icon">
          <base-icon :height="height" :name="appendIcon" color="#94857d" width="21" />
        </span>
        <span v-if="!$slots.appendIcon && isPercent" class="inputBox__append-icon">
          <base-icon :height="height" color="#94857d" name="percent" width="21" />
        </span>
      </span>
    </base-tooltip>
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { StylesType } from '@/types';

const props = withDefaults(
  defineProps<{
    placeholder?: string;
    modelValue?: string | number | undefined;
    type?: string;
    min?: number | undefined;
    max?: number | undefined;
    hideArrows?: boolean;
    disabled?: boolean;
    label?: string;
    step?: number;
    labelInValue?: boolean;
    height?: string;
    filled?: boolean;
    styles?: StylesType;
    styleType?: 'light';
    withoutFocus?: boolean;
    required?: boolean;
    invalid?: boolean;
    dataMaska?: string;
    error?: string;
    prefix?: string;
    autoFocus?: boolean;
    appendIcon?: string;
    isPercent?: boolean;
    loading?: boolean;
    withBg?: boolean;
  }>(),
  {
    labelInValue: false,
    modelValue: undefined,
    height: '36',
    filled: false,
    step: 1,
    min: undefined,
    max: undefined,
    styleType: undefined,
    withoutFocus: false,
    autoFocus: false,
  },
);
const emit = defineEmits(['update:modelValue', 'focus', 'blur', 'input']);
// eslint-disable-next-line no-undef
const inputValue = defineModel<string | number | undefined | null>();
const tooltipActive = ref(false);
const inputLabel = ref<HTMLElement | undefined>();

const inputType = computed((): string | undefined => {
  if (props.type === 'number' || showPassword.value) {
    return 'text';
  }

  if (props.type === 'date' && !isFocusInput.value) {
    return 'text';
  }

  return props.type;
});

const showPassword = ref(false);

const isFocusInput = ref(false);
const changeFocus = (bool: boolean) => {
  if (props.withoutFocus && bool) {
    element.value?.blur();

    return;
  }
  isFocusInput.value = bool;
  if (!bool) {
    if (props.type === 'number' && inputValue.value) {
      let val = +String(inputValue.value).replace(/\s/g, '');

      if (props.min !== undefined && val < props.min) {
        inputValue.value = readableNumber(String(props.min));
      } else if (props.max !== undefined && val > props.max) {
        inputValue.value = readableNumber(String(props.max));
      }
      errorMessage.value = '';
      isError.value = false;
    }
    // emit('update:modelValue', inputValue.value);
  }
};
const element = ref<HTMLInputElement | undefined>();
const focusInput = () => {
  setTimeout(() => {
    element.value?.focus();
  }, 10);
};
const isStepUpAllowed = computed((): boolean => {
  return (
    !props.disabled &&
    (props.max === undefined || parseInt(String(inputValue.value), 10) < props.max)
  );
});
const stepUp = () => {
  if (inputValue.value === undefined || inputValue.value === null) return;
  if (!isStepUpAllowed.value) return;

  const newValue = +inputValue.value + props.step;

  if (newValue != null) {
    inputValue.value = newValue;

    // return emit('update:modelValue', newValue);
  }
};

const isStepDownAllowed = computed((): boolean => {
  return (
    !props.disabled &&
    (props.min === undefined || parseInt(String(inputValue.value), 10) > props.min)
  );
});
const stepDown = () => {
  if (inputValue.value === undefined || inputValue.value === null) return;
  if (!isStepDownAllowed.value) return;

  const newValue = +inputValue.value - props.step;

  if (newValue) {
    inputValue.value = newValue;
  } else {
    inputValue.value = props.min || 0;
  }
};

const listeners = computed(() => ({
  input: handleInput,
  focus: (e: PointerEvent) => emit('focus', e),
  blur: (e: PointerEvent) => emit('blur', e),
}));
const emitValue = (event: InputEvent) => {
  let value = (event.target as HTMLInputElement).value;

  emit('update:modelValue', value);
};

const cssProps = computed(() => {
  return {
    '--border': props.styles?.border,
    '--background-color': props.styles?.backgroundColor,
    '--border-radius': props.styles?.borderRadius,
  };
});
const readableNumber = (value: string) => {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const errorMessage = ref('');
const isError = ref(false);
const handleInput = (e: InputEvent) => {
  if (props.type === 'number') {
    const target = e.target as HTMLInputElement;
    let val = target.value.replace(/\s/g, '');

    //если были введены не числа
    const pattern = /^\d+$/;

    if (!pattern.test(val)) {
      isError.value = true;
      errorMessage.value = 'Только цифры';
      let start = target.selectionStart! - 1;
      const numberValue = readableNumber(val.replace(/[^0-9]/g, ''));

      nextTick(() => {
        target.selectionStart = target.selectionEnd = start;
      });
      inputValue.value = numberValue;

      return;
    }

    // Если был удален пробел
    const readablePattern = /\B(?=(\d{3})+(?!\d))/g;

    if (
      (e.inputType === 'deleteContentBackward' ||
        e.inputType === 'deleteContentForward') &&
      readablePattern.test(target.value)
    ) {
      let start = target.selectionStart!;

      if (e.inputType === 'deleteContentBackward') {
        start = start - 1;
      }
      const arr = target.value.split('');

      arr.splice(start, 1);
      const newVal = arr.join('').replace(/\s/g, '');

      inputValue.value = readableNumber(newVal);
      nextTick(() => {
        target.selectionStart = target.selectionEnd = start + 1;
      });

      return;
    }

    //добавление пробелов и перенос курсора
    let start = target.selectionStart ?? 0;
    let number = target.value.split(' ').length - 1;

    if (props.min !== undefined && +val < props.min) {
      errorMessage.value = `Минимальное значение: ${readableNumber(String(props.min))}`;
      isError.value = true;
    } else if (props.max !== undefined && +val > props.max) {
      errorMessage.value = `Максимальное значение: ${readableNumber(String(props.max))}`;
      isError.value = true;
    } else {
      errorMessage.value = '';
      isError.value = false;
    }
    inputValue.value = readableNumber(val);
    number = String(readableNumber(val)).split(' ').length - 1 - number;
    start = start + number < 0 ? 0 : start + number;

    nextTick(() => {
      target.selectionStart = target.selectionEnd = start;
    });
  } else {
    emitValue(e);
  }
};

onMounted(() => {
  if (props.type === 'number') {
    if (inputValue.value !== undefined && inputValue.value !== null) {
      if (props.min !== undefined) {
        if (+inputValue.value >= props.min) {
          inputValue.value = readableNumber(String(inputValue.value));
        }
      } else {
        inputValue.value = readableNumber(String(inputValue.value));
      }
    } else {
      inputValue.value = undefined;
    }
  }

  if (props.autoFocus) {
    focusInput();
  }

  if (inputLabel.value) {
    if (inputLabel.value.clientWidth < inputLabel.value.scrollWidth) {
      tooltipActive.value = true;
    }
  }
});

watch(inputValue, (newValue, oldValue) => {
  //Приводим число к нужному типу
  if (props.type === 'number') {
    if (newValue !== undefined && newValue !== null) {
      const preparedNewValue = `${newValue}`.replace(/[^0-9]/g, '');
      const preparedOldValue = `${oldValue}`.replace(/[^0-9]/g, '');

      const preparedNumber = readableNumber(String(newValue));

      if (preparedNewValue !== preparedOldValue || newValue !== preparedNumber) {
        inputValue.value = preparedNumber;
      }
    }
  }
});
</script>

<style lang="scss" scoped src="./BaseInput.scss"></style>
