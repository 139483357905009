import { defineStore } from 'pinia';
import { store } from '@/store';
import { getCheckResultByIdApi } from '@/api/check';

export const useCheckResultStore = defineStore('checkResult', {
  state: () => {
    return {};
  },
  actions: {
    async searchCheckResultById(cardsId: number) {
      const res = await getCheckResultByIdApi(cardsId);

      if (res) {
        return res.data;
      }
    },
  },
  getters: {},
});

export const useCheckResultStoreWithOut = () => {
  return useCheckResultStore(store);
};
