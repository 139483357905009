<template>
  <base-modal :show-modal="showModal" title="Материалы продаж" @close="$emit('close')">
    <template #content>
      <div class="allBlockWidth">
        <div class="partners-files">
          <a
            v-for="file in partnerFiles"
            :key="file.id"
            :href="file.path"
            class="partners-files__item"
          >
            <span class="partners-files__item-icon center">{{
              file.name.split('.').pop()
            }}</span>
            <span class="textHidden">{{ file.name }}</span>
          </a>
        </div>
        <div class="flex flex-end mt--10">
          <button class="gradient-color" @click="downloadFiles">Скачать все</button>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script lang="ts" setup>
import { FileType } from '@/types';
import { usePartnerProgramStore } from '@/store/modules/partnerProgram';

const props = defineProps<{
  showModal?: boolean;
  partnerFiles: FileType[];
  partnerProgramId: number;
}>();
const emit = defineEmits(['close']);

const downloadFiles = async () => {
  await usePartnerProgramStore().downloadDocuments(props.partnerProgramId);
};
</script>

<style lang="scss" scoped src="./PartnersFilesModal.scss"></style>
