import { defineStore } from 'pinia';
import { store } from '@/store';
import { getInfoApi, sendStatisticApi } from '@/api/common';
import { BankLogoDataType, SearchParamType } from '@/api/common/types';
import { useSearchRealEstateStore } from '@/store/modules/searchRealEstate';
import { errorHandle, prepareUrl } from '@/mixins/Common/Common';
import { InfographicType } from '@/types';
import { useUserStore } from '@/store/modules/user';

type AppState = {
  window: number;
  baseUrl: string;
  overlay: boolean;
  showAlertModal: {
    show: boolean;
    title: string;
    text: string;
    type?: 'success' | 'error';
  };
  lightRegistration: boolean;
  regions: { id: number; name: string; genitive: string }[];
  user_types: { id: number; title: string }[];
  infographic_icons: InfographicType;
  search_params: SearchParamType[];
  bank_logos: BankLogoDataType[];
  regions_for_select: {
    defaultForUser: number | undefined;
    regions: {
      id: number;
      name: string;
    }[];
  };
  showAuthModal: boolean;
  showAuthMessage: boolean | undefined;
  complaint_types: { [key: string]: string };
  isPageNotFound: boolean;
};

export const useAppStore = defineStore('app', {
  state: (): AppState => {
    return {
      window: 0,
      baseUrl: '',
      overlay: false,
      showAlertModal: { show: false, title: '', text: '' },
      lightRegistration: false,
      regions: [],
      user_types: [],
      search_params: [],
      infographic_icons: {},
      bank_logos: [],
      regions_for_select: {
        defaultForUser: undefined,
        regions: [],
      },
      showAuthModal: false,
      showAuthMessage: false,
      complaint_types: {},
      isPageNotFound: false,
    };
  },
  actions: {
    openAuthModal(showAuthMessage?: boolean) {
      this.showAuthModal = true;
      this.showAuthMessage = showAuthMessage;
    },
    closeAuthModal() {
      this.showAuthModal = false;
    },
    writeWindowWidth(width: number) {
      this.window = width;
    },
    changeBaseUrl(baseUrl: string) {
      this.baseUrl = baseUrl;
    },
    toggleOverlay(bool: boolean) {
      this.overlay = bool;
    },
    openAlert(title: string, text: string, type?: 'success' | 'error') {
      this.showAlertModal = { show: true, title: title, text: text, type: type };
    },
    closeAlert() {
      this.showAlertModal = { show: false, title: '', text: '' };
    },
    updateRegion(region: number) {
      this.regions_for_select.defaultForUser = region;
    },
    async getInfo() {
      try {
        const res = await getInfoApi();

        const getInfografics = (): InfographicType => {
          const infographic: InfographicType = {};

          Object.entries(res.data.infographic_icons).map(el => {
            infographic[el[0]] = {
              ...el[1],
              icons: el[1].icons.map(icon => {
                return {
                  ...icon,
                  path: icon.path !== '' ? prepareUrl(icon.path) : undefined,
                  alternative_path:
                    icon.alternative_path !== ''
                      ? prepareUrl(icon.alternative_path)
                      : undefined,
                };
              }),
            };
          });

          return infographic;
        };

        if (res) {
          this.regions = res.data.regions;
          this.user_types = res.data.user_types;
          this.search_params = res.data.search_params;
          this.infographic_icons = getInfografics();
          this.bank_logos = res.data.bank_logos;
          this.regions_for_select = res.data.regions_for_select;
          this.complaint_types = res.data.complaint_types;
          useSearchRealEstateStore().writeOptions();
        }
      } catch (error) {
        errorHandle(error);
      }
    },
    async sendStatistic(id: number) {
      try {
        await sendStatisticApi(id);
      } catch (error) {
        errorHandle(error);
      }
    },
    togglePageNotFound(bool: boolean) {
      this.isPageNotFound = bool;
    },
  },
  getters: {
    getWindowWidth: state => (): number => {
      return state.window;
    },

    getParameterById:
      state =>
      (paramId: number): SearchParamType | undefined => {
        return state.search_params.find(el => el.id === paramId);
      },
    getComplaintByType:
      state =>
      (type: number): string => {
        return state.complaint_types[type];
      },
    getBankLogo:
      state =>
      (id: number): string | undefined => {
        const foundLogoData = state.bank_logos.find(el => el.internal_id === id);

        if (foundLogoData) {
          return foundLogoData.path;
        }

        return undefined;
      },

    getGenitive: state => (): string | undefined => {
      return state.regions.find(el => el.id === state.regions_for_select.defaultForUser)
        ?.genitive;
    },
    getType: state => (): string | undefined => {
      if (useUserStore().user) {
        return state.user_types.find(el => el.id === useUserStore().user!.type)?.title;
      }

      return undefined;
    },
  },
});

export const useAppStoreWithOut = () => {
  return useAppStore(store);
};
