<template>
  <CreateForm :edit-program="editProgram" :is-edit="isEdit" @cancel="cancelEdit" />
  <PartnerProgramTable class="mt--30" @editPartnerProgram="editPartnerProgram" />
</template>

<script lang="ts" setup>
import CreateForm from './CreateForm/CreateForm.vue';
import PartnerProgramTable from './PartnerProgramTable/PartnerProgramTable.vue';
import { Ref, ref } from 'vue';
import { ItemType } from '@/types/TableTypes';

const isEdit = ref(false);
const editProgram: Ref<ItemType | undefined> = ref(undefined);

const editPartnerProgram = (partnerProgram: ItemType) => {
  isEdit.value = true;
  editProgram.value = partnerProgram;
};
const cancelEdit = () => {
  isEdit.value = false;
  editProgram.value = undefined;
};
</script>

<style lang="scss" scoped src="./PartnerProgram.scss"></style>
