import service from './service';

const request = (option: AxiosConfig) => {
  const { url, method, params, data, headers, responseType } = option;

  return service.request({
    url: url,
    method,
    params,
    headers,
    data: data,
    responseType: responseType,
  });
};

export default {
  get: <T>(option: AxiosConfig) => {
    return request({ method: 'get', ...option }) as Promise<IResponse<T>>;
  },
  post: <T>(option: AxiosConfig) => {
    return request({ method: 'post', ...option }) as Promise<IResponse<T>>;
  },
};
