import { Validation, ValidationArgs } from '@vuelidate/core';
import { Ref } from 'vue';
import { ErrorsType, ValidateRulesType } from '@/types';
import axios, { AxiosError } from 'axios';
import {
  KeyArrayObjectType,
  KeyObjectType,
  ObjectParamsApiType,
  ObjectParamType,
} from '@/api/developer/types';
import type { LngLat } from '@yandex/ymaps3-types';
import { useAppStore } from '@/store/modules/app';

export const errorsHandlerByKey = <T>(
  key: string,
  rules: ValidateRulesType,
  v$: Ref<Validation<ValidationArgs<unknown>, T>>,
): string | undefined => {
  let error: string | undefined = undefined;

  if (rules[key]) {
    Object.keys(rules[key]).forEach((rule: string) => {
      if (v$.value[key].$dirty && !v$.value[key][rule]?.$response) {
        switch (rule) {
          case 'required':
            error = 'Поле не может быть пустым';
            break;
          case 'checked':
            error = 'Обязательное поле';
            break;
          case 'email':
            error = 'Введите корректный e-mail';
            break;
          case 'sameAsPassword':
            error = 'Пароли должны совпадать';
            break;
          case 'passwordRule':
            error =
              'Пароль должен содержать не менее 1 символа в верхнем регистре, 1 символа в нижнем регистре и 1 цифры.';
            break;
          default:
            error = undefined;
        }
      }
    });
  }

  return error;
};

export const errorsHandler = <T>(
  formData: T,
  rules: ValidateRulesType,
  v$: Ref<Validation<ValidationArgs<unknown>, T>>,
): ErrorsType => {
  const errors: ErrorsType = {};

  Object.keys(formData).forEach((key: string) => {
    const error = errorsHandlerByKey(key, rules, v$);

    if (error) {
      errors[key] = error;
    } else {
      delete errors[key];
    }
  });

  return errors;
};

export const validationErrorsByRequest = (
  error: unknown,
  keyValue?: string | undefined,
): { [key: string]: string } => {
  const errors = {} as { [key: string]: string };

  if (axios.isAxiosError(error)) {
    const data = error.response?.data;
    const errorValidation = data?.error === 2;

    if (errorValidation) {
      Object.keys(data.errors).map(key => {
        if (keyValue && key === keyValue) {
          Object.keys(data.errors[key]).map(paramKey => {
            errors[paramKey] = data.errors[key][paramKey].join(', ');
          });
        } else {
          errors[key] = data.errors[key].join(', ');
        }
      });
    }
  }

  return errors;
};

export const prepareUrl = (url: string | undefined): string | undefined => {
  if (url && url.length) {
    const preparedUrl = url.startsWith('/') ? url : `/${url}`;

    if (process.env.VUE_APP_IS_DEV === 'true') {
      return `https://xn--d1aefpjv.xn--p1ai${preparedUrl}`;
    } else {
      return `${useAppStore().baseUrl}${preparedUrl}`;
    }
  }

  return undefined;
};
export const preparePhone = (phone: string | undefined): string | undefined => {
  if (phone && phone.length) {
    return phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 ($1) $2-$3-$4');
  }

  return undefined;
};
export const prepareParam = (id: KeyObjectType, data: ObjectParamsApiType) => {
  const textIds = [87];

  if (textIds.includes(id)) {
    return data[id] ? data[87][0].text || undefined : undefined;
  }
  if (data[id] && data[id][0]) {
    if (data[id][0].type === 2) {
      return data[id][0].numeric_value || undefined;
    }

    return data[id][0].value || undefined;
  }

  return undefined;
};
export const prepareYearByParam = (id: KeyObjectType, data: ObjectParamsApiType) => {
  return data[id] ? new Date(data[id][0].value).getFullYear() : undefined;
};
export const prepareArrByParam = (id: KeyArrayObjectType, data: ObjectParamsApiType) => {
  return data[id] ? Object.values(data[id][0].value) : undefined;
};
export const prepareDocsByParam = (id: KeyArrayObjectType, data: ObjectParamsApiType) => {
  return data[id]
    ? data[id][0].value.map(el => {
        return {
          id: el.id,
          name: el.name || 'файл',
          path: prepareUrl(el.path),
        };
      })
    : undefined;
};
export const prepareNameValueByParams = (data: ObjectParamType[]) => {
  return data.map(el => {
    const getParam = (foundParam: ObjectParamType) => {
      if (foundParam.type === 2) {
        return `${(+foundParam.numeric_value).toLocaleString()} ${foundParam.options.unit ?? ''}`;
      }
      if (foundParam.type === 19) {
        return `${new Date(foundParam.value).getFullYear()} г.`;
      }
      if (foundParam.type === 24 || foundParam.type === 25 || foundParam.type === 28) {
        return `${+foundParam.min} - ${foundParam.max}`;
      }
      if (foundParam.type === 3) {
        return foundParam.value === '1' ? 'Да' : 'Нет';
      }

      return foundParam.value;
    };

    return {
      title: el.title,
      value: getParam(el),
    };
  });
};
export const prepareCoordinatesByParam = (
  id: KeyObjectType,
  data: ObjectParamsApiType,
) => {
  return data[id]
    ? data[id]
        .filter(el => el.min !== null && el.max !== null)
        .map(el => {
          return { id: el.id, coordinates: [+el.min, +el.max] as LngLat };
        })
    : [];
};
export const prepareUrlsByParam = (id: KeyArrayObjectType, data: ObjectParamsApiType) => {
  return data[id] && Array.isArray(data[id])
    ? data[id].map(el => prepareUrl(el.value[0].path))
    : undefined;
};
export const prepareParamOfArray = (
  id: KeyArrayObjectType,
  data: ObjectParamsApiType,
) => {
  const imgsIds = [9, 346, 84];

  if (imgsIds.includes(id) && data[id]) {
    return data[id][0] ? prepareUrl(data[id][0].value[0].path) : undefined;
  }

  return data[id] ? data[id][0].value[0] : undefined;
};

export const replaceLinksInText = (fullText: string): string => {
  const res = [] as string[];

  fullText?.replace(
    /((?:https?:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,})|(\n+|(?:(?!(?:https?:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,}).)+)/gim,
    (m: string, link: string, text: string): string => {
      res.push(
        link ? `<a target="_blank" class="underline" href=${link}>${link}</a>` : text,
      );

      return '';
    },
  );

  return res.join('');
};

export const createDecliningLabel = (number: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];

  return `${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]}`;
};

export const errorHandle = (error: AxiosError | unknown) => {
  if (process.env.VUE_APP_IS_DEV === 'true') {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
