<template>
  <base-card>
    <div class="announcement-card">
      <div v-if="card.isNew" class="verifiedBtn announcement-card__new">Новое</div>
      <div class="announcement-card__img">
        <component
          :is="type === 'active' ? 'router-link' : 'span'"
          :to="{ name: 'offerCard', params: { id: card.id } }"
        >
          <base-image
            :name="String(card.id)"
            :src="card.img"
            borderRadius
            cover
            height="100%"
            width="100%"
          />
        </component>
      </div>
      <div class="announcement-card__info">
        <div><span class="medium">Адрес:</span> {{ card.address || '-' }}</div>
        <div>
          <span class="medium">Цена:</span>
          {{ card.price ? card.price?.toLocaleString() : '-' }} ₽
        </div>
        <div><span class="medium">id:</span> {{ card.id }}</div>
      </div>
      <div class="announcement-card__actions">
        <div></div>
        <div class="items-in-line space-between announcement-card__actions-icons">
          <div class="items-in-line scale gap--3">
            <base-icon height="20" name="visibility" />
            <span>{{ card.views }}</span>
          </div>
          <div class="items-in-line gap--3">
            <base-icon height="20" name="favorite" />
            <span>{{ card.favorites }}</span>
          </div>
          <div class="items-in-line gap--3">
            <base-icon height="20" name="playlist_add_check" />
            <span>{{ card.comparisons }}</span>
          </div>
          <div class="items-in-line gap--5">
            <span class="gradient-color">Чат:</span>
            <span class="error-color xxs-font-size">{{ card.chats }}</span>
          </div>
        </div>
        <div class="third-color">
          <div v-if="type === 'active'">
            <span class="none-Mobile">Осталось</span> {{ activeDays }}
            {{ createDecliningLabel(activeDays, ['День', 'Дня', 'Дней']) }}
          </div>
          <div v-else-if="type === 'drafts'">Не опубликовано</div>
          <div v-else>Закрыто</div>
        </div>
      </div>
      <div class="announcement-card__ellipsis">
        <base-popup>
          <button class="ellipsis">...</button>
          <template #content>
            <div class="items-in-column gap--0 announcement-card__popup-menu">
              <button class="announcement-card__popup-menu-item" @click="editCard">
                Редактировать
              </button>
              <button
                v-if="type === 'active' && activeDays < 30"
                class="announcement-card__popup-menu-item"
                @click="prolongAd"
              >
                Продлить
              </button>
              <button
                v-if="type === 'active'"
                class="announcement-card__popup-menu-item"
                @click="addToArchive"
              >
                Архивировать
              </button>
              <button
                v-if="type === 'archive'"
                class="announcement-card__popup-menu-item"
                @click="removeFromArchive"
              >
                Восстановить
              </button>
              <button
                class="announcement-card__popup-menu-item"
                @click="showDeleteCategoryModal(card.id)"
              >
                Удалить
              </button>
            </div>
          </template>
        </base-popup>
      </div>
    </div>
    <DeleteDeveloperModal
      :delete-category-modal-data="deleteCategoryModalData"
      @close="closeDeleteCategoryModal"
      @delete-category="$emit('deleteCategory', $event)"
    />
    <base-info-modal
      v-if="!!successMessage"
      :show-info-modal="!!successMessage"
      @close="successMessage = undefined"
    >
      <div>{{ successMessage }}</div>
    </base-info-modal>
  </base-card>
</template>

<script lang="ts" setup>
import { SmallOfferCardType } from '@/types/MainPageTypes';
import { computed, ref, Ref } from 'vue';
import { DeleteCategoryModalType, TypesMyAdsType } from '@/types';
import { DeleteDeveloperModal } from '@/components/ProfileComponents';
import { useDevelopersStore } from '@/store/modules/developers';
import { createDecliningLabel } from '@/mixins/Common/Common';
import { useRouter } from 'vue-router';

const props = defineProps<{
  card: SmallOfferCardType;
  type: TypesMyAdsType;
}>();

const successMessage: Ref<string | undefined> = ref(undefined);
const deleteCategoryModalData: Ref<DeleteCategoryModalType> = ref({
  show: false,
  id: undefined,
});
const showDeleteCategoryModal = (id: number) => {
  deleteCategoryModalData.value = {
    show: true,
    id: id,
  };
};

const closeDeleteCategoryModal = () => {
  deleteCategoryModalData.value = {
    show: false,
    id: undefined,
  };
};
const router = useRouter();
const editCard = () => {
  useDevelopersStore().clearMyAnnouncement();
  router.push({ name: 'updateOfferCard', query: { id: props.card.id } });
};
const addToArchive = () => {
  useDevelopersStore().addToArchive(props.card);
};
const removeFromArchive = () => {
  useDevelopersStore().removeFromArchive(props.card);
};
const prolongAd = async () => {
  await useDevelopersStore()
    .prolongAnnouncement(props.card.id)
    .then(() => {
      successMessage.value = 'Объявление пролонгировано';
    });
};

const activeDays = computed((): number => {
  const date1 = new Date();
  const date2 = new Date(props.card.activeTo);

  return Math.round((date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24));
});
</script>

<style lang="scss" scoped src="./AnnouncementCard.scss"></style>
