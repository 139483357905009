<template>
  <button type="button" @click="emitClick">
    <slot name="icon"></slot>
    <span
      v-if="!$slots.icon"
      :class="[sizeClass, { 'has-click': !disabled && clickable, left, right, disabled }]"
      :role="clickable ? 'button' : undefined"
      :style="[
        { color: color },
        { height: `${height}px` },
        { width: `${width}px` },
        { fontSize: `${fontSize}px` },
      ]"
      :tabindex="clickable ? 0 : undefined"
      class="v-icon"
      >{{ name }}</span
    >
  </button>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    clickable?: boolean;
    left?: boolean;
    right?: boolean;
    color?: string;
    name?: string;
    sizeClass?: string;
    height?: string;
    width?: string;
    fontSize?: string;
  }>(),
  {
    color: '#969696',
    fontSize: '24',
  },
);
const emit = defineEmits(['click']);

const emitClick = (event: MouseEvent) => {
  if (props.disabled) return;
  emit('click', event);
};
</script>

<style lang="scss">
@import '@/assets/styles/main';

.v-icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  font-family: 'Material Symbols Outlined', serif;
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;

  &.has-click {
    cursor: pointer;
    transition: color var(--fast) var(--transition);

    &:hover {
      color: $secondDopColor;
    }
  }

  &.disabled {
    color: $dopColor !important;
  }

  &.x-small {
    font-size: 12px;
  }

  &.small {
    font-size: 18px;
  }

  &.large {
    font-size: 36px;
  }

  &.x-large {
    font-size: 48px;
  }

  &.left {
    margin-right: 8px;

    &.small {
      margin-right: 4px;
    }
  }

  &.right {
    margin-left: 6px;

    &.small {
      margin-left: 4px;
    }
  }
}
</style>
