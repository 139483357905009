<template>
  <div class="auth-modal">
    <div>
      <div class="align-center l-font-size">Введите новый пароль</div>
    </div>
    <base-form class="grid--1 gap--15">
      <base-input
        v-model="formData.newPassword"
        :error="errors.newPassword"
        :invalid="!!errors.newPassword"
        height="40"
        label="Пароль"
        type="password"
        @blur="validationErrorsHandlerByKey('password')"
        @focus="delete errors.newPassword"
        @input="v$.newPassword.$touch"
      />
      <base-input
        v-model="formData.confirmPassword"
        :error="errors.confirmPassword"
        :invalid="!!errors.confirmPassword"
        height="40"
        label="Повторите пароль"
        type="password"
        @blur="validationErrorsHandlerByKey('password_confirmation')"
        @focus="delete errors.confirmPassword"
        @input="v$.confirmPassword.$touch"
      />
      <base-button
        :loading="loading"
        allBlockWidth
        height="40"
        text="Сменить пароль"
        @click="formSubmit"
      />
    </base-form>
  </div>
</template>
<script lang="ts" setup>
import { computed, Ref, ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, sameAs } from '@vuelidate/validators';
import {
  errorsHandler,
  errorsHandlerByKey,
  validationErrorsByRequest,
} from '@/mixins/Common/Common';
import { ErrorsType, ValidateRulesType } from '@/types';
import { changePasswordApi } from '@/api/login';

const emit = defineEmits(['close']);

type FormDataType = {
  newPassword: string | undefined;
  confirmPassword: string | undefined;
};

const formData: Ref<FormDataType> = ref({
  newPassword: undefined,
  confirmPassword: undefined,
});
const clearFormData = () => {
  v$.value.$reset();
  for (const [key] of Object.entries(formData)) {
    formData.value[key as keyof FormDataType] = undefined;
  }
};

const passwordRule = helpers.regex(/(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])/);
const rules: ValidateRulesType = {
  newPassword: { required, passwordRule },
  confirmPassword: {
    required,
    sameAsPassword: sameAs(
      computed(() => {
        return formData.value.newPassword;
      }),
    ),
  },
};
const v$ = useVuelidate<FormDataType>(rules, formData);

const errors: Ref<ErrorsType> = ref({});

const validationErrorsHandlerByKey = (key: string) => {
  let error: string | undefined = errorsHandlerByKey(key, rules, v$);

  if (error) {
    errors.value[key] = error;
  } else {
    delete errors.value[key];
  }
};
const validationErrorsHandler = () => {
  errors.value = errorsHandler(formData.value, rules, v$);
};
const loading = ref(false);
const formSubmit = async () => {
  if (v$.value.$invalid) {
    v$.value.$touch();
    validationErrorsHandler();

    return;
  }
  loading.value = true;

  try {
    const res = await changePasswordApi(formData.value);

    if (res) {
      clearFormData();
      emit('close');
    }
  } catch (error) {
    errors.value = validationErrorsByRequest(error);
  } finally {
    loading.value = false;
  }
};
</script>

<style lang="scss" scoped src="./AuthModal.scss"></style>
