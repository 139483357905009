<template>
  <div
    :class="{ 'with-line': styleType === 'with-line' }"
    :style="cssProps"
    class="expansion-panels"
  >
    <base-card
      v-if="styleType === 'base'"
      :fullWidthMobile="fullWidthMobile"
      :without-mobile-shadow="withoutMobileShadow"
    >
      <div class="expansion-panels__collapsible base" @click="collapseHandler">
        <slot :data="openBlock" :events="{ handleClick }" name="title"></slot>
      </div>
      <div :class="{ open: openBlock, withPreview }" class="expansion-panels__content">
        <div class="expansion-panels__content-box">
          <slot :data="openBlock" :events="{ handleClick }" name="content"></slot>
        </div>
      </div>
    </base-card>
    <div
      v-if="
        styleType === 'simple' ||
        styleType === 'simple-green' ||
        styleType === 'with-line'
      "
      :class="{
        'expansion-panels--simple': styleType === 'simple',
        'expansion-panels--simple-green': styleType === 'simple-green',
        'expansion-panels--with-line': styleType === 'with-line',
        isError,
      }"
      class="border-block expansion-panels--simple"
    >
      <div
        :class="{
          'start-arrow': styleType === 'with-line',
        }"
        class="expansion-panels__collapsible simple"
        @click="handleClick"
      >
        <span
          v-if="styleType === 'with-line'"
          class="expansion-panels__collapsible-line"
        />
        <slot :data="openBlock" :events="{ handleClick }" name="title"></slot>

        <button class="relative">
          <span
            :class="{ open: openBlock, right: styleType !== 'with-line' }"
            class="expansion-panels__collapsible-arrow"
          >
            <base-icon
              :color="
                isError ? '#EA1F1F' : styleType === 'simple-green' ? '#009D3E' : '#868298'
              "
              name="expand_more"
            />
          </span>
        </button>
        <span
          v-if="styleType === 'with-line'"
          class="expansion-panels__collapsible-line"
        />
      </div>
      <div :class="{ open: openBlock, withPreview }" class="expansion-panels__content">
        <div
          :class="{
            'mt--20 mb--20': styleType !== 'with-line' && styleType !== 'simple-green',
          }"
        >
          <slot :data="openBlock" :events="{ handleClick }" name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useAppStore } from '@/store/modules/app';
import { StyleType } from '@/types';

const props = withDefaults(
  defineProps<{
    isMobileCollapse?: boolean;
    isFullCollapse?: boolean;
    styleColor?: StyleType;
    withPreview?: boolean;
    fullWidthMobile?: boolean;
    withoutMobileShadow?: boolean;
    styleType?: 'simple' | 'base' | 'simple-green' | 'with-line';
    isOpen?: boolean;
    isError?: boolean;
  }>(),
  {
    isMobileCollapse: false,
    styleColor: 'base',
    withPreview: false,
    styleType: 'base',
  },
);
const emit = defineEmits(['openBlock', 'closeBlock']);

const openBlock = ref(false);
const handleClick = () => {
  openBlock.value = !openBlock.value;
  if (openBlock.value) {
    emit('openBlock');
  } else {
    emit('closeBlock');
  }
};

const collapseHandler = () => {
  if (
    (props.isMobileCollapse && useAppStore().window <= 600 && !openBlock.value) ||
    props.isFullCollapse
  ) {
    handleClick();
  }
};

const cssProps = computed(() => {
  let color;
  let bgColor;

  switch (props.styleColor) {
    case 'blue':
      color = '#0097CB';
      bgColor = '#F8FDFF';
      break;
    case 'green':
      color = '#009D3E';
      bgColor = '#20a2330f';
      break;
    case 'orange':
      color = '#C16800';
      bgColor = '#c168000a';
      break;
  }

  return {
    '--color': color,
    '--background-color': bgColor,
  };
});

onMounted(() => {
  openBlock.value = props.isOpen;
});
</script>

<style lang="scss" scoped src="./BaseExpansionPanels.scss"></style>
