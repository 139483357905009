<template>
  <base-tooltip :active="tooltipActive" :show-in-mobile="false" :text="label">
    <button
      :style="[
        cssProps,
        {
          height: height + 'px',
        },
      ]"
      class="checkbox-input"
      @click.prevent="inputHandler"
    >
      <span class="checkbox-input__box">
        <span>
          <input
            :id="`checkbox${uuid}`"
            v-model="checkboxValue"
            class="checkbox-input__box-input"
            type="checkbox"
            v-on="listeners"
          />
          <span class="checkbox-input__box-ripple pointer"></span>
        </span>

        <label
          ref="checkboxLabel"
          :for="`checkbox${uuid}`"
          class="checkbox-input__box-label"
        >
          <slot name="label"></slot>
          <span v-if="!$slots.label">
            {{ label }}
          </span>
        </label>
      </span>
      <span v-if="error?.length" class="checkbox-input__error">{{ error }}</span>
    </button>
  </base-tooltip>
</template>

<script lang="ts" setup>
import { computed, getCurrentInstance, onMounted, ref } from 'vue';
import { StylesType } from '@/types';
import { ComponentInternalInstance } from '@vue/runtime-core';

let id = 0;

const props = withDefaults(
  defineProps<{
    label?: string;
    height?: string;
    styles?: StylesType;
    error?: string;
  }>(),
  {
    height: '36',
  },
);
const emit = defineEmits(['focus', 'blur', 'input']);
const instance: ComponentInternalInstance | null = getCurrentInstance();
const uuid = ref(instance?.uid);

// eslint-disable-next-line no-undef
const checkboxValue = defineModel<boolean>();

const tooltipActive = ref(false);
const checkboxLabel = ref<HTMLElement | undefined>();
const inputHandler = () => {
  checkboxValue.value = !checkboxValue.value;
};

const listeners = computed(() => ({
  input: (e: PointerEvent) => emit('input', e),
  focus: (e: PointerEvent) => emit('focus', e),
  blur: (e: PointerEvent) => emit('blur', e),
}));
const cssProps = computed(() => {
  return {
    '--border': props.styles?.border,
    '--background-color': props.styles?.backgroundColor,
  };
});

onMounted(() => {
  if (checkboxLabel.value) {
    if (checkboxLabel.value.clientWidth < checkboxLabel.value.scrollWidth) {
      tooltipActive.value = true;
    }
  }
});
</script>

<style lang="scss" scoped src="./BaseCheckboxInput.scss"></style>
