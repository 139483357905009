<template>
  <base-modal
    :customModal="true"
    :fullScreen="windowWidth <= 600"
    :show-modal="isShowFilterBlock"
    :withBackground="false"
    close-outside
    title="Фильтры"
    @close="$emit('close')"
  >
    <template #content>
      <div class="filter-block">
        <base-menu-with-tabs
          :activeComponent="activeCategoryId"
          :componentBtns="categories"
          :hide-title="1264"
          bgColor="#fff"
          greenMobile
          mobile-show-all-blocks
          withRadius
          @changeActiveBtn="getActiveGroups($event)"
        >
          <template #title="titleProps">
            <div
              :class="{ withCount: getCountByCategoryId(titleProps.data.id) }"
              class="filter-block__title"
            >
              <span class="textHidden">{{ titleProps.data.title }}</span>

              <span
                v-if="getCountByCategoryId(titleProps.data.id)"
                class="filter-block__count"
                >{{ getCountByCategoryId(titleProps.data.id) }}</span
              >
            </div>
          </template>
          <template #content="contentProps">
            <div v-if="useSearchRealEstateStore().getGroups(contentProps.data)">
              <div class="filter-block__category none-Mobile">
                <div
                  v-if="
                    useSearchRealEstateStore().getGroups(contentProps.data).length > 1
                  "
                >
                  <base-button-group
                    :active="contentProps.data"
                    :buttons="useSearchRealEstateStore().getGroups(contentProps.data)"
                    btnHeight="40"
                    textKey="title"
                    type="greenBorder"
                    @changeActiveBtn="getVisibleParams($event.id)"
                  >
                    <template #btnText="btnTextProps">
                      <div
                        :class="{ withCount: getCountByGroupId(btnTextProps.data.id) }"
                        class="filter-block__title"
                      >
                        <span class="textHidden">{{ btnTextProps.data.title }}</span>

                        <span
                          v-if="getCountByGroupId(btnTextProps.data.id)"
                          class="filter-block__count"
                        >
                          {{ getCountByGroupId(btnTextProps.data.id) }}</span
                        >
                      </div>
                    </template>
                  </base-button-group>
                </div>
                <ParamsBlock
                  v-if="activeParams"
                  v-model="paramsValues"
                  :active-params="activeParams"
                  :visible-parameters="visibleParameters"
                  @change-visible-category="changeVisibleCategory($event)"
                  @change-distance="
                    activeGroupId
                      ? useSearchRealEstateStore().changeDistance(
                          activeGroupId,
                          $event.id,
                          $event.value,
                        )
                      : () => {}
                  "
                />
              </div>
              <div class="mb--20 only-mobile">
                <div
                  v-for="item in useSearchRealEstateStore().getGroups(contentProps.data)"
                  :key="item.id"
                >
                  <div>
                    <ParamsBlock
                      v-if="useSearchRealEstateStore().getParameters(item.id)"
                      v-model="paramsValues"
                      :active-params="useSearchRealEstateStore().getParameters(item.id)"
                      :visible-parameters="visibleParameters"
                      @change-visible-category="changeVisibleCategory($event)"
                      @change-distance="
                        item.id
                          ? useSearchRealEstateStore().changeDistance(
                              item.id,
                              $event.id,
                              $event.value,
                            )
                          : () => {}
                      "
                    />
                    <div v-else class="filter-block__notFound container">
                      Фильтры не найдены
                    </div>
                  </div>
                </div>
              </div>
              <div class="filter-block__btns">
                <base-button
                  v-if="paramsCount > 0"
                  :disabled="isLoading"
                  :text="`Сбросить (${paramsCount})`"
                  height="36"
                  style-type="coloredBorder"
                  width="130"
                  @click="resetFilters()"
                />
                <base-button
                  :loading="isLoading"
                  height="36"
                  text="Применить"
                  width="130"
                  @click="applyFilters(true)"
                />
              </div>
            </div>

            <div v-else class="filter-block__notFound container">Фильтры не найдены</div>
            <div class="filter-block-closeIcon">
              <base-icon
                clickable
                color="black"
                height="15"
                name="close"
                width="15"
                @click="$emit('close')"
              />
            </div>
          </template>
        </base-menu-with-tabs>
      </div>
    </template>
  </base-modal>
</template>

<script lang="ts" setup>
import { computed, onMounted, Ref, ref, toRefs, watch } from 'vue';
import { ParamType, useSearchRealEstateStore } from '@/store/modules/searchRealEstate';
import { useAppStore } from '@/store/modules/app';
import { useOfferCardsStore } from '@/store/modules/offerCards';
import ParamsBlock from '@/components/MainPageComponents/FilterBlock/ParamsBlock/ParamsBlock.vue';
import qs from 'qs';
import { useRouter } from 'vue-router';
import { SearchParamsDataType } from '@/types';

const props = withDefaults(
  defineProps<{
    isShowFilterBlock: boolean;
    isFilterByTerritory: boolean;
    showCoordinates?: boolean;
    showListBlock?: boolean;
  }>(),
  {
    showListBlock: true,
  },
);
const emit = defineEmits(['close', 'showResultBlock']);
const windowWidth = computed((): number => {
  return useAppStore().window;
});
const router = useRouter();

const categories = computed(() => {
  if (props.isFilterByTerritory) {
    return useSearchRealEstateStore().filterCategories.filter(
      el => el.id !== 2 && el.id !== 4 && el.id !== 1,
    );
  }

  return useSearchRealEstateStore().filterCategories;
});

const paramsValues: Ref<SearchParamsDataType> = ref({});
const isLoading = ref(false);

const activeCategoryId: Ref<undefined | number> = ref(undefined);
const getActiveGroups = (categoryId: number) => {
  activeCategoryId.value = categoryId;
  const activeGroups = useSearchRealEstateStore().getGroups(categoryId);

  if (activeGroups) {
    getVisibleParams(activeGroups[0].id);
  }
};

const activeGroupId: Ref<undefined | number> = ref(undefined);
const activeParams: Ref<ParamType[] | undefined> = ref(undefined);
const visibleParameters: Ref<number[]> = ref([1]);

const getCountByCategoryId = (categoryId: number | string) => {
  let count = 0;

  const groups = useSearchRealEstateStore().getGroups(categoryId);

  if (groups) {
    groups.forEach(group => {
      const params = useSearchRealEstateStore().getParameters(group.id);

      params?.forEach(el => {
        el.optionsIds.forEach(option => {
          const value = paramsValues.value[option];

          if (value.min || value.max || value.value) {
            count++;
          }
        });
      });
    });
  }

  return count;
};

const getCountByGroupId = (groupId: number) => {
  let count = 0;
  const params = useSearchRealEstateStore().getParameters(groupId);

  params?.forEach(el => {
    el.optionsIds.forEach(option => {
      const value = paramsValues.value[option];

      if (value.min || value.max || value.value) {
        count++;
      }
    });
  });

  return count;
};

const paramsCount = computed(() => {
  return Object.values(paramsValues.value).filter(
    paramValue => paramValue.min || paramValue.max || paramValue.value,
  ).length;
});

const getVisibleParams = (groupId: number) => {
  activeGroupId.value = groupId;
  activeParams.value = useSearchRealEstateStore().getParameters(groupId);
  useSearchRealEstateStore().filterSubGroups.map(el =>
    el.parameters.map(el => el.optionsIds),
  );

  visibleParameters.value = [1];
};

const changeVisibleCategory = (categoryId: number) => {
  const index = visibleParameters.value.findIndex(el => el === categoryId);

  if (index >= 0) {
    visibleParameters.value.splice(index, 1);
  } else {
    visibleParameters.value.push(categoryId);
  }
};
const resetFilters = () => {
  Object.keys(paramsValues.value).forEach(param => {
    paramsValues.value[param] = {
      value: null,
      max: null,
      min: null,
    };
  });
  applyFilters(true);
};
const applyFilters = async (withClose: boolean = false) => {
  Object.entries(paramsValues.value).forEach(param => {
    if (param[1]) {
      useOfferCardsStore().updateParameter(
        param[0],
        useSearchRealEstateStore().getDistanceByOptionId(+param[0]) === 'km' &&
          param[1].value
          ? String(+param[1].value / 1000)
          : param[1].value,
        param[1].min,
        param[1].max,
      );
    }
  });
  const preparedData = {
    page: 1,
    pageSize: 8,
    params: useOfferCardsStore().getPreparedParamsForSend(),
  };
  // Добавляем фильтры в URL
  const str = qs.stringify(useOfferCardsStore().params, {
    arrayFormat: 'brackets',
    skipNulls: true,
    encodeValuesOnly: true,
  });

  await router.push(`?${str}`);

  isLoading.value = true;

  try {
    let searchCoordinates;
    let res;

    if (!props.showListBlock && !props.showCoordinates) {
      useOfferCardsStore().toggleIsShowResultBlock(true);
    }

    if (props.showListBlock || (!props.showListBlock && !props.showCoordinates)) {
      res = await useOfferCardsStore().searchOfferCards(preparedData);
    }

    if (props.showCoordinates) {
      searchCoordinates = await useOfferCardsStore().searchOfferCardsCoordinates({
        params: useOfferCardsStore().getPreparedParamsForSend(),
      });
    }
    if (res || searchCoordinates) {
      emit('showResultBlock');
      if (withClose) {
        emit('close');
      }
    }
  } finally {
    isLoading.value = false;
  }
};

//Создаем значения paramsValues и заполняем уже заполненными параметрами
onMounted(() => {
  const getValue = <T,>(option: number, key: 'value' | 'min' | 'max'): T => {
    if (paramsValues.value[option]) {
      return paramsValues.value[option][key] as unknown as T;
    }
    if ({ ...useOfferCardsStore().params }[option]) {
      return useOfferCardsStore().params[option][key] as unknown as T;
    }

    return null!;
  };

  useSearchRealEstateStore().filterSubGroups.forEach(group => {
    group.parameters?.forEach(parameter => {
      parameter.optionsIds.forEach(option => {
        paramsValues.value[option] = {
          value: getValue(option, 'value'),
          min: getValue(option, 'min'),
          max: getValue(option, 'max'),
        };
      });
    });
  });
});

const { isShowFilterBlock } = toRefs(props);

watch(isShowFilterBlock, isOpen => {
  if (isOpen) {
    getActiveGroups(categories.value[0].id);
  }
});
</script>

<style lang="scss" scoped src="./FilterBlock.scss"></style>
