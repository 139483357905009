<template>
  <div class="progress-line">
    <div class="progress-line__description mb--5">
      <div>{{ name }}</div>
      <div class="progress-line__description-value main-color">
        <base-icon color="#009D3E" font-size="20" name="check" width="22" />
      </div>
    </div>

    <div class="progress-line__linear">
      <span :style="cssProps"></span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    value?: string;
    name?: string;
  }>(),
  {
    value: '',
  },
);
const cssProps = computed(() => {
  return {
    width: `100%`,
  };
});
</script>

<style lang="scss" scoped src="./BaseProgressLine.scss"></style>
