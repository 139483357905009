import { FileType, ParamValueType, SearchDataByIdType } from '@/api/house/types';
import { ResultCardType } from '@/types';

import { useAppStore } from '@/store/modules/app';
import type { LngLat } from '@yandex/ymaps3-types';
import { RateType, SmallOfferCardType } from '@/types/MainPageTypes';
import { prepareNameValueByParams, prepareUrl } from '@/mixins/Common/Common';
import {
  KeyArrayObjectType,
  MyAdsType,
  ObjectParamArrayType,
} from '@/api/developer/types';

export const prepareOfferCard = (cardData: SearchDataByIdType): ResultCardType => {
  const getParamById = (
    id: number,
    key: 'about_house' | 'about_land' | 'about_developer' | 'about_residential_complex',
  ) => {
    const returnParam = (foundParam: ParamValueType) => {
      const textIds = [348];

      if (textIds.includes(id)) {
        return foundParam.text;
      }
      if (typeof foundParam.value === 'object') {
        return foundParam.value;
      }
      if (foundParam.numeric_value !== '0' && foundParam.numeric_value) {
        return +foundParam.numeric_value;
      }

      return foundParam.value;
    };

    let foundParam: ParamValueType | undefined;

    if (key === 'about_residential_complex' || key === 'about_developer') {
      foundParam = Object.values(cardData[key]).find(el => {
        if (typeof el === 'object' && el.category_parameter_id === id) {
          return el;
        }

        return undefined;
      }) as ParamValueType | undefined;
    } else {
      foundParam = cardData[key]?.find(el => el.category_parameter_id === id);
    }

    if (foundParam) {
      return returnParam(foundParam);
    }
  };

  const getClassifier = () => {
    return Array.isArray(cardData.about_residential_complex)
      ? undefined
      : {
          coefficient: cardData.about_residential_complex.coefficient,
          developerId: cardData.about_residential_complex.about_residential_complex_id,
        };
  };

  const getImages = (
    id: number,
    key: 'about_house' | 'about_land' | 'about_developer' | 'about_residential_complex',
  ): string[] => {
    const images: string[] = [];

    if (getParamById(id, key) !== '-') {
      const url =
        process.env.VUE_APP_IS_DEV === 'true'
          ? 'https://xn--d1aefpjv.xn--p1ai/'
          : useAppStore().baseUrl;

      if (Array.isArray(getParamById(id, key))) {
        (getParamById(id, key) as FileType[]).forEach(el => {
          images.push(url + '/' + el.path);
        });
      }
    }

    return images;
  };
  const getCoordinates = (): LngLat | undefined => {
    const foundParam226 = cardData.about_land.find(
      el => el.category_parameter_id === 226,
    );

    if (foundParam226 && foundParam226.min && foundParam226.max) {
      return [+foundParam226.min, +foundParam226.max];
    }

    return undefined;
  };
  const sortRates = (): RateType[] => {
    if (Object.values(cardData.rates)[0]) {
      return Object.values(cardData.rates)[0]
        .data.sort((a, b) => {
          if (!a.rate || !b.rate) {
            return 1;
          } else {
            return a.rate > b.rate ? 1 : -1;
          }
        })
        .map(rate => {
          return {
            minRate: rate.rate,
            title: rate.title,
            minPayment: rate.payment,
            downPayment: rate.downPayment,
          };
        });
    }

    return [];
  };

  const getFiles = (): { name: string; path: string }[] => {
    const files: { name: string; path: string }[] = [];

    if (getParamById(303, 'about_house') !== '-') {
      const url =
        process.env.VUE_APP_IS_DEV === 'true'
          ? 'http://localhost:13010'
          : useAppStore().baseUrl;

      if (Array.isArray(getParamById(303, 'about_house'))) {
        (getParamById(303, 'about_house') as FileType[]).forEach(el => {
          files.push({
            name: el.name,
            path: url + '/' + el.path,
          });
        });
      }
    }

    return files;
  };

  return {
    id: cardData.about_house
      ? cardData.about_house[0]?.category_value_id
      : cardData.about_land[0]?.category_value_id,
    landId: cardData.about_land[0]?.category_value_id,
    name: (cardData.about_house
      ? getParamById(202, 'about_house')
      : getParamById(114, 'about_land')) as string,
    classifierValue: getClassifier(),
    img: cardData.about_house ? getImages(275, 'about_house') : undefined,
    landImgs: getImages(274, 'about_land') || [],
    price: (cardData.about_house
      ? getParamById(203, 'about_house')
      : getParamById(365, 'about_land')) as string | number,
    rates: sortRates(),
    rating: Array.isArray(cardData.about_residential_complex)
      ? undefined
      : cardData.about_residential_complex?.coefficient,
    houseArea: (cardData.about_house ? getParamById(199, 'about_house') : undefined) as
      | string
      | number
      | undefined,
    landArea: getParamById(116, 'about_land') as string | number,
    floor: (cardData.about_house ? getParamById(198, 'about_house') : undefined) as
      | string
      | number
      | undefined,
    type: getParamById(114, 'about_land') as string,
    address: cardData.about_house
      ? (getParamById(218, 'about_house') as string) ||
        (getParamById(227, 'about_house') as string)
      : (getParamById(250, 'about_land') as string),
    coordinates: getCoordinates(),
    phone: getParamById(96, 'about_residential_complex') as string | undefined,
    isCompare: false,
    residentialComplexWebsite: undefined,
    description: cardData.about_house
      ? (getParamById(348, 'about_house') as string)
      : (getParamById(366, 'about_land') as string),
    aboutDeveloper: Array.isArray(cardData.about_developer)
      ? undefined
      : {
          title: getParamById(6, 'about_developer') as string,
          logo: getImages(9, 'about_developer'),
          id: cardData.about_developer.developerId,
          description: getParamById(11, 'about_developer') as string,
        },
    aboutResidentialComplex: Array.isArray(cardData.about_residential_complex)
      ? undefined
      : {
          title: cardData.about_residential_complex.about_residential_complex_name,
          logo: getImages(84, 'about_residential_complex'),
          id: cardData.about_residential_complex.about_residential_complex_id,
        },
    infographics: cardData.infographics,
    docs: getFiles(),
    passportLand: prepareNameValueByParams(cardData.passport_land),
    passportHouse: cardData.passport_house
      ? prepareNameValueByParams(cardData.passport_house)
      : [],
    isEstateAgent: cardData.is_estate_agent,
    affiliateProgramId: cardData.affiliate_program_id,
    seller: {
      ...cardData.seller,
      logo_path_company: prepareUrl(cardData?.seller?.logo_path_company),
    },
    isPhysics: cardData.isPhysics,
  };
};

export const prepareSmallOfferCard = (ads: MyAdsType[]): SmallOfferCardType[] => {
  const smallCards: SmallOfferCardType[] = [];

  const getImg = (
    el: {
      [key in KeyArrayObjectType]: ObjectParamArrayType;
    },
    id: KeyArrayObjectType,
  ) => {
    return prepareUrl(
      Array.isArray(el[id]?.value) ? el[id]?.value[0].path : el[id]?.value,
    );
  };

  ads.forEach(announcement => {
    Object.entries(announcement).forEach(el => {
      smallCards.push({
        id: +el[0],
        img: el[1].isLand ? getImg(el[1], 274) : getImg(el[1], 275),
        address: el[1].isLand
          ? el[1][250]?.value
          : el[1][218]?.value && el[1][218]?.value.length
            ? el[1][218]?.value
            : el[1][250]?.value,
        price: el[1].isLand ? +el[1][365]?.numeric_value : +el[1][203]?.numeric_value,
        favorites: el[1].favorites,
        comparisons: el[1].comparisons,
        chats: el[1].chats,
        views: el[1].views,
        isLand: el[1].isLand,
        activeFrom: el[1].active_from,
        activeTo: el[1].active_to,
      });
    });
  });

  return smallCards;
};
