<template>
  <div>
    <base-card v-if="isLoading" class="partners-table container center">
      <base-spinner :size="50" color="#009D3E" />
    </base-card>
    <base-card v-else class="partners-table" full-width-mobile>
      <div v-if="values.length" class="partners-table__table">
        <base-table :headers="headers" :items="prepareValues" :style="'simple'">
          <template #ads="adsProps">
            <div v-if="Array.isArray(adsProps.data.value)">
              <span v-for="(id, i) in adsProps.data.value" :key="id">
                <router-link :to="{ name: 'offerCard', params: { id: id } }">
                  {{ id }}
                </router-link>
                <span v-if="i !== adsProps.data.value.length - 1">, </span>
              </span>
            </div>
          </template>
        </base-table>
      </div>
      <div v-else>Партнерские программы не найдены</div>
      <base-pagination-group
        v-if="values.length"
        :customPage="paginationData.page"
        :itemCountOnPage="paginationData.pageSize"
        :max-for-visible="20"
        :objectCount="objectCount"
        :show-btn="false"
        :step-for-visible="5"
        :value="prepareValues"
        :values="values"
        class="mt--20"
        @change-page-and-page-size="changePageAndPageSize"
      />
    </base-card>
    <base-info-modal
      :key="key"
      :show-info-modal="showDeleteDataModal"
      @close="showDeleteDataModal = false"
    >
      <div>Партнерская программа удалена</div>
    </base-info-modal>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, Ref } from 'vue';
import { ItemType } from '@/types/TableTypes';
import { usePartnerProgramStore } from '@/store/modules/partnerProgram';

type PaginationDataType = {
  page: number;
  pageSize: number;
};
const emit = defineEmits(['editPartnerProgram']);

const showDeleteDataModal = ref(false);
const key = ref(0);
const downloadFiles = async (item: ItemType) => {
  await usePartnerProgramStore().downloadDocuments(+item['id'].value);
};
const editPartnerProgram = (item: ItemType) => {
  emit('editPartnerProgram', item);
};
const deletePartnerProgram = async (item: ItemType) => {
  isLoading.value = true;
  try {
    const res = await usePartnerProgramStore().deletePartnerProgram(+item['id'].value);

    if (res) {
      showDeleteDataModal.value = true;
      key.value++;
      await getPrograms();
    }
  } finally {
    isLoading.value = false;
  }
};
const headers = [
  {
    text: 'Дата создания',
    value: 'createDate',
    align: 'left',
  },
  {
    text: 'Период действия',
    value: 'period',
    align: 'left',
  },
  {
    text: 'Объявления',
    value: 'ads',
    align: 'left',
  },
  {
    text: '%',
    value: 'percent',
    align: 'left',
  },
  {
    text: 'Файлы',
    value: 'files',
    align: 'left',
    click: downloadFiles,
  },
  {
    text: '',
    value: 'edit',
    align: 'center',
    click: editPartnerProgram,
  },
  {
    text: '',
    value: 'delete',
    align: 'center',
    click: deletePartnerProgram,
  },
];

const values = computed(() => {
  let values = usePartnerProgramStore().partnerPrograms;

  if (values.length > paginationData.value.pageSize) {
    values = values.slice(0, paginationData.value.pageSize);
  }

  return values;
});

const prepareValues = computed((): ItemType[] => {
  return values.value;
});
const isLoading = ref(false);
const paginationData: Ref<PaginationDataType> = ref({
  page: 1,
  pageSize: 5,
});
const objectCount: Ref<number | undefined> = ref(undefined);

const getPrograms = async () => {
  isLoading.value = true;
  try {
    const res = await usePartnerProgramStore().getAllPartnerPrograms(
      paginationData.value,
    );

    if (res) {
      objectCount.value = res.count;
    }
  } finally {
    isLoading.value = false;
  }
};

const changePageAndPageSize = async (data: { page: number; pageSize: number }) => {
  if (
    data.pageSize !== paginationData.value.pageSize ||
    data.page !== paginationData.value.page
  ) {
    paginationData.value = {
      page: data.page,
      pageSize: data.pageSize,
    };
  }
  await getPrograms();
};

onMounted(async () => {
  await getPrograms();
});
</script>

<style lang="scss" scoped src="./PartnerProgramTable.scss"></style>
