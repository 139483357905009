<template>
  <form
    ref="form"
    :class="{
      loading: isLoading,
    }"
    class="ui form base-form-container"
    novalidate
    @submit.prevent="handleSubmit"
  >
    <div v-if="error" class="main-top-section">
      {{ error }}
    </div>

    <slot />
  </form>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';

const props = defineProps<{
  isLoading?: boolean;
  error?: string;
}>();
const emit = defineEmits(['submit', 'reset']);

const form = ref<HTMLElement | undefined>();

const handleSubmit = () => {
  emit('submit');
};

const resetForm = () => {
  emit('reset');
};

onMounted(() => {
  resetForm();
});
</script>

<style lang="scss" scoped src="./BaseForm.scss"></style>
