<template>
  <base-modal
    :show-modal="createCategoryModalData.show"
    :title="`Создание сущности категории '${createCategoryModalData.categoryTitle}'`"
    @close="closeCreateCategoryModal"
  >
    <template #content>
      <div class="create-category-modal">
        <base-form class="mb--20" @submit="createCategory()">
          <base-input
            v-model="formData.newTitle"
            :error="errors.newTitle"
            :invalid="!!errors.newTitle"
            height="36"
            hideArrows
            label="Название"
            @blur="validationErrorsHandlerByKey('newTitle')"
            @focus="delete errors.newTitle"
            @input="v$.newTitle.$touch"
          />
          <div v-if="isOfferCard">
            <base-select-input
              v-model="developerId"
              :options="listDevelopersOptions"
              class="mt--20"
              label="Выберите СК"
              @update:modelValue="updateResidentialComplex"
            />
            <base-select-input
              v-model="residentialComplexId"
              :options="listResidentialComplexOptions"
              class="mt--20"
              label="Выберите ЖК"
              @update:modelValue="updateConstructionLines"
            />
            <base-select-input
              v-if="useUserStore().user?.is_construction_company"
              v-model="constructionLineId"
              :options="listConstructionLinesOptions"
              class="mt--20"
              label="Выберите очередь"
            />
          </div>
          <base-select-input
            v-else-if="isResidentialComplex"
            v-model="legalEntityId"
            :options="listLegalEntitiesOptions"
            class="mt--20"
            label="Выберите Юр. Лицо"
          />
        </base-form>
        <div class="center">
          <base-button
            :loading="isCreateCategoryLoading"
            height="40"
            text="Сохранить"
            @click="createCategory()"
          />
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script lang="ts" setup>
import {
  errorsHandler,
  errorsHandlerByKey,
  validationErrorsByRequest,
} from '@/mixins/Common/Common';
import { ref, Ref, watch } from 'vue';
import { CreateCategoryModalType, ErrorsType, OptionsType } from '@/types';
import { useVuelidate, ValidationRuleWithoutParams } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useDevelopersStore } from '@/store/modules/developers';
import { useUserStore } from '@/store/modules/user';
import { LegalEntitiesType } from '@/api/developer/types';

type FormDataType = {
  newTitle: string;
};

const props = defineProps<{
  createCategoryModalData: CreateCategoryModalType;
  isResidentialComplex?: boolean;
  isOfferCard?: boolean;
}>();
const emit = defineEmits(['createCategory', 'close']);

const errors: Ref<ErrorsType> = ref({});
const isCreateCategoryLoading = ref(false);
const listLegalEntitiesOptions: Ref<OptionsType[]> = ref([]);
const listDevelopersOptions: Ref<OptionsType[]> = ref([]);
const listResidentialComplexOptions: Ref<OptionsType[]> = ref([]);
const listConstructionLinesOptions: Ref<OptionsType[]> = ref([]);
const currentEntitiesOptions: Ref<number | undefined> = ref(undefined);
const legalEntityId: Ref<number | undefined> = ref(undefined);
const developerId: Ref<number | undefined> = ref(undefined);
const residentialComplexId: Ref<number | undefined> = ref(undefined);
const constructionLineId: Ref<number | undefined> = ref(undefined);
const categoryId: Ref<number | string | undefined> = ref(undefined);
const formData: Ref<FormDataType> = ref({
  newTitle: '',
});

const rules: { [key: string]: { [key: string]: ValidationRuleWithoutParams } } = {
  newTitle: { required },
};
const prepareList = (res: LegalEntitiesType | undefined) => {
  if (res) {
    categoryId.value = res.childCategory;

    return res.list?.map(el => {
      return {
        id: el.id,
        name: el.value,
      };
    });
  } else {
    return [];
  }
};
const updateResidentialComplex = async (id?: number) => {
  if (id !== 0) {
    await useDevelopersStore()
      .getCategoryResidentialComplexes(id)
      .then(res => {
        listResidentialComplexOptions.value = prepareList(res);
        if (props.isOfferCard && !useUserStore().user?.is_construction_company) {
          listResidentialComplexOptions.value.unshift({
            id: 0,
            name: 'Нет моего ЖК',
          });
        }
        if (
          !listResidentialComplexOptions.value.find(
            el => el.id === residentialComplexId.value,
          )
        ) {
          residentialComplexId.value = undefined;
        }
      });
  }
};
const updateConstructionLines = async (id?: number) => {
  await useDevelopersStore()
    .getConstructionLines(id)
    .then(res => {
      listConstructionLinesOptions.value = prepareList(res);
      if (
        !listConstructionLinesOptions.value.find(el => el.id === constructionLineId.value)
      ) {
        constructionLineId.value = undefined;
      }
    });
};
const updateDevelopers = async (id?: number) => {
  await useDevelopersStore()
    .getDevelopers(id)
    .then(res => {
      listDevelopersOptions.value = prepareList(res);

      if (props.isOfferCard && !useUserStore().user?.is_construction_company) {
        listDevelopersOptions.value.unshift({
          id: 0,
          name: 'Нет моего СК',
        });
      }
      if (!listDevelopersOptions.value?.find(el => el.id === developerId.value)) {
        developerId.value = undefined;
      }
    });
};
const validationErrorsHandlerByKey = (key: string | number) => {
  let error: string | undefined = errorsHandlerByKey(String(key), rules, v$);

  if (error) {
    errors.value[key] = error;
  } else {
    delete errors.value[key];
  }
};
const validationErrorsHandler = () => {
  errors.value = errorsHandler(formData.value, rules, v$);
};
const v$ = useVuelidate<FormDataType>(rules, formData, {
  $autoDirty: true,
  $stopPropagation: true,
});
const clearFormData = () => {
  v$.value.$reset();
  formData.value.newTitle = '';
  residentialComplexId.value = undefined;
  legalEntityId.value = undefined;
  developerId.value = undefined;
  constructionLineId.value = undefined;
};
const closeCreateCategoryModal = () => {
  clearFormData();
  emit('close');
};
const createCategory = async () => {
  if (v$.value.$invalid) {
    v$.value.$touch();
    validationErrorsHandler();

    return;
  }
  if (categoryId.value) {
    let parentId;

    if (props.isOfferCard && useUserStore().user?.is_construction_company) {
      parentId = constructionLineId.value;
    } else if (props.isOfferCard) {
      parentId =
        residentialComplexId.value === 0 ? undefined : residentialComplexId.value;
    } else if (props.isResidentialComplex) {
      parentId = legalEntityId.value;
    } else {
      parentId = props.createCategoryModalData.parentId;
    }
    try {
      isCreateCategoryLoading.value = true;
      const preparedData = {
        categoryId: String(categoryId.value),
        parentId: parentId,
        value: formData.value.newTitle,
      };
      const res = await useDevelopersStore().createCategoryValue(preparedData);

      if (res) {
        closeCreateCategoryModal();
        emit('createCategory', { id: res.id, title: formData.value.newTitle });
      }
    } catch (error) {
      errors.value = validationErrorsByRequest(error);
    } finally {
      isCreateCategoryLoading.value = false;
    }
  }
};

watch(
  () => props.createCategoryModalData.show,
  async () => {
    if (props.createCategoryModalData.show) {
      if (props.isResidentialComplex || props.isOfferCard) {
        if (props.isResidentialComplex) {
          await useDevelopersStore()
            .getLegalEntities()
            .then(res => {
              listLegalEntitiesOptions.value = prepareList(res);
            });
        } else if (props.isOfferCard) {
          await updateDevelopers();
          await updateResidentialComplex();

          if (useUserStore().user?.is_construction_company) {
            await updateConstructionLines();
          }
        }
      } else {
        categoryId.value = props.createCategoryModalData.categoryId;
        currentEntitiesOptions.value = props.createCategoryModalData.parentId;
      }
    }
  },
);
</script>

<style lang="scss" scoped>
.create-category-modal {
  width: 100%;
}
</style>
