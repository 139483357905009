import { defineStore } from 'pinia';
import { store } from '@/store';
import { getUserLoginApi, logoutApi } from '@/api/login';
import {
  createFavoriteHouseApi,
  deleteFavoriteHouseApi,
  getFavoriteHousesApi,
} from '@/api/favorite';
import { prepareOfferCard } from '@/mixins/Cards/OfferCard';
import { prepareUrl } from '@/mixins/Common/Common';
import { deleteCompanyLogoApi, deleteLogoApi, updateProfileInfoApi } from '@/api/profile';
import { NotificationsTypeFromBack } from '@/api/notification/types';
import {
  closeNotificationByIdApi,
  getCountNotificationsApi,
  getNotificationsApi,
} from '@/api/notification';
import { sendSupportApi } from '@/api/support';
import { houseMyApi } from '@/api/house';
import { useAppStore } from '@/store/modules/app';
import { ClearStateAndStorage } from '@/mixins/Common/ClearStateAndStorage';
import router from '@/router';

export type AccessTypes = 'back' | 'PartnerProgramEdit';

export type UserType = {
  id: number;
  name: string;
  email: string;
  logo: string | undefined;
  region: string;
  region_id: number;
  phone: string;
  profile: {
    logoCompany: string | undefined;
    titleCompany: string;
    siteCompany: string;
  };
  type: number;
  is_construction_company: boolean;
  verified: boolean;
  favorite_ids: number[];
  access: AccessTypes[];
  comparisons: number;
  notificationUser: {
    open: number;
    all: number;
  };
} | null;
type UserState = {
  user: UserType | undefined;
  notifications: NotificationsTypeFromBack;
};

export const useUserStore = defineStore('user', {
  state: (): UserState => {
    return {
      user: undefined,
      notifications: [],
    };
  },
  actions: {
    async getUserInfo() {
      const res = await getUserLoginApi();

      if (res) {
        this.writeUserInfo(res.data.user);
      }
    },

    writeUserInfo(user: UserType) {
      if (user) {
        this.user = {
          ...user,
          logo: prepareUrl(user.logo),
          profile: { ...user.profile, logoCompany: prepareUrl(user.profile.logoCompany) },
        };
      } else {
        this.user = null;
      }
    },
    async asyncLogout() {
      useAppStore().toggleOverlay(true);
      try {
        await logoutApi();
        await router.push({ name: 'login' });
        ClearStateAndStorage();
        localStorage.setItem('auth-event', 'logout' + Math.random());
      } finally {
        useAppStore().toggleOverlay(false);
      }
    },
    async getFavoriteHouses() {
      const res = await getFavoriteHousesApi();

      if (res && res.data.found?.length) {
        return res.data.found.map(el => prepareOfferCard(el));
      }
    },
    async getMyHouses(paginationData: { page: number; pageSize: number }) {
      const res = await houseMyApi(paginationData);

      if (res && res.data.found?.length) {
        return {
          ...res.data,
          found: res.data.found.map(el => prepareOfferCard(el)),
        };
      }
    },
    async changeIsFavorite(cardId: number) {
      const favoriteIndex = this.user?.favorite_ids.findIndex(el => el === cardId);

      if (favoriteIndex !== undefined && favoriteIndex >= 0) {
        const res = await deleteFavoriteHouseApi(cardId);

        if (res) {
          this.user?.favorite_ids?.splice(favoriteIndex, 1);
        }
      } else {
        const res = await createFavoriteHouseApi(cardId);

        if (res) {
          this.user?.favorite_ids.push(cardId);
        }
      }
    },
    addComparisons() {
      if (this.user) {
        this.user.comparisons = this.user?.comparisons + 1;
      }
    },
    deleteComparisons() {
      if (this.user) {
        this.user.comparisons = this.user?.comparisons - 1;
      }
    },
    async updateUserInfo(preparedData: FormData) {
      const res = await updateProfileInfoApi(preparedData);

      if (res) {
        if (this.user) {
          this.user = {
            ...this.user,
            logo: prepareUrl(res.data.logo),
            name: res.data.name,
            phone: res.data.phone,
            profile: {
              ...res.data.profile,
              logoCompany: prepareUrl(res.data.profile.logoCompany),
            },
          };
        }

        return res.data;
      }
    },
    async deleteLogo() {
      const res = await deleteLogoApi();

      if (res) {
        if (this.user) {
          this.user = { ...this.user, logo: undefined };
        }

        return res.data;
      }
    },
    async deleteCompanyLogo() {
      const res = await deleteCompanyLogoApi();

      if (res) {
        if (this.user) {
          this.user = {
            ...this.user,
            profile: { ...this.user.profile, logoCompany: undefined },
          };
        }

        return res.data;
      }
    },
    async getNotifications() {
      const res = await getNotificationsApi();

      if (res) {
        this.notifications = res.data;
      }
    },
    async closeNotificationById(id: number) {
      const res = await closeNotificationByIdApi({ id });

      if (res) {
        if (this.user) {
          this.user.notificationUser.open = this.user.notificationUser.open - 1;
        }

        this.notifications = this.notifications.map(el => {
          if (el.id === id) {
            return { ...el, close: true };
          }

          return el;
        });

        return res.data;
      }
    },
    async getCountNotifications() {
      const res = await getCountNotificationsApi();

      if (res) {
        if (this.user) {
          if (res.data.open > this.user.notificationUser.open) {
            await this.getNotifications();
          }
          this.user.notificationUser = res.data;
        }

        return res.data;
      }
    },
    async sendSupport(data: { comment: string }) {
      const res = await sendSupportApi(data);

      if (res) {
        return res.data;
      }
    },
  },
  getters: {
    getIsLogin: state => (): boolean => {
      return state.user !== null && state.user !== undefined;
    },
    getIsFavorite:
      state =>
      (cardId: number): boolean => {
        const favoriteIds = state.user?.favorite_ids || [];

        return favoriteIds.includes(cardId);
      },
    getShowPartnersProgram: state => (): boolean => {
      return state.user?.type === 1 || state.user?.type === 2 || state.user?.type === 3;
    },
  },
});

export const useUserStoreWithOut = () => {
  return useUserStore(store);
};
