import request from '@/axios';
import {
  ComparisonTypeFromBack,
  CreateCompareDataType,
  DeleteHouseFromCompareDataType,
  UpdateCompareDataType,
  UserComparisonTypeFromBack,
} from './types';

export const createCompareApi = (
  data: CreateCompareDataType,
): Promise<IResponse<{ comparisonHouse: string }>> => {
  return request.post({ url: process.env.VUE_APP_API_COMPARISON_HOUSE_CREATE, data });
};

export const getComparisonsApi = (): Promise<
  IResponse<{ comparisons: ComparisonTypeFromBack[]; status: boolean }>
> => {
  return request.get({ url: process.env.VUE_APP_API_COMPARISON_HOUSE_COMPARISONS });
};

export const getComparisonByIdApi = (
  id: number,
): Promise<IResponse<UserComparisonTypeFromBack>> => {
  return request.get({
    url: process.env.VUE_APP_API_COMPARISON_HOUSE_USER_COMPARISON,
    params: {
      id: id,
    },
  });
};

export const deleteHouseFromCompareApi = (
  data: DeleteHouseFromCompareDataType,
): Promise<IResponse<{ status: boolean; success: string }>> => {
  return request.post({
    url: process.env.VUE_APP_API_COMPARISON_HOUSE_REMOVE_FROM_COMPARISON,
    data,
  });
};

export const updateCompareApi = (
  data: UpdateCompareDataType,
): Promise<IResponse<{ status: boolean; success: string }>> => {
  return request.post({
    url: process.env.VUE_APP_API_COMPARISON_HOUSE_UPDATE_COMPARISON,
    data,
  });
};

export const deleteCompareApi = (
  compareId: number,
): Promise<IResponse<{ status: boolean; success: string }>> => {
  return request.post({
    url: process.env.VUE_APP_API_COMPARISON_HOUSE_REMOVE_COMPARISON,
    params: {
      id: compareId,
    },
  });
};
