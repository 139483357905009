import axios from 'axios';

export const getLocation = (data: string) => {
  return axios.get('https://geocode-maps.yandex.ru/1.x', {
    params: {
      format: 'json',
      apikey: '72e7233b-21bd-4a70-b726-6feb8f7d3bc7',
      geocode: data,
    },
  });
};

export const getAddress = (data: string) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Token ' + 'fd86504cf25ef3ac451da49c2102d66a33b34041',
    },
  };

  return axios.post(
    `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`,
    {
      query: data,
      count: 6,
    },
    config,
  );
};
