<template>
  <div>
    <div class="full-map">
      <div class="full-map__header">
        <base-icon
          clickable
          color="#000000"
          name="arrow_back"
          @click="$router.push({ name: 'main', hash: '#list' })"
        />
        <base-button all-block-width style-type="grey" @click="isShowSearchBlock = true">
          <template #text>
            <div class="items-in-line gap--5">
              <base-icon color="#009D3E" name="search" />
              Поиск
            </div>
          </template>
        </base-button>
        <base-button all-block-width style-type="grey" @click="isShowFilterBlock = true">
          <template #text>
            <div class="items-in-line gap--5">
              <base-icon color="#009D3E" name="tune" />
              Фильтры
            </div>
          </template>
        </base-button>
      </div>
      <div
        v-if="useOfferCardsStore().isLoadingResultBlock"
        class="center full-map__loading"
      >
        <base-spinner :size="50" color="#009D3E" />
      </div>
      <MapBlock
        v-else
        :center-point-map="useOfferCardsStore().centerPointMap"
        :resultCardsCoordinates="resultCardsCoordinates"
        height="100%"
        is-full
        showOfferCard
      />
      <base-button
        class="full-map__list-btn"
        height="36"
        @click="$router.push({ name: 'main', hash: '#list' })"
      >
        <template #text>
          <div class="items-in-line gap--5">
            <base-icon color="#ffffff" name="list" />
            Список
          </div>
        </template>
      </base-button>
    </div>
    <FilterBlock
      :is-show-filter-block="isShowFilterBlock"
      :isFilterByTerritory="activeFilterBtn?.id === 3"
      :show-list-block="false"
      show-coordinates
      @close="isShowFilterBlock = false"
    />
    <base-modal
      :show-modal="isShowSearchBlock"
      close-outside
      custom-modal
      hide-title
      @close="isShowSearchBlock = false"
    >
      <template #content>
        <div class="full-map__search-modal">
          <FilterBtns class="full-map__search-modal-filter-btns" />
          <InputGroup height="44" />
          <div class="allBlockWidth pt--10 pb--10 pr--10 pl--10">
            <base-button all-block-width height="36" @click="isShowSearchBlock = false">
              <template #text>
                <div v-if="resultCardsCoordinates.length" class="items-in-line gap--5">
                  <span>Показать</span>
                  <span v-if="isLoading"> <base-spinner /></span>
                  <span v-else>{{ resultCardsCoordinates.length }}</span>
                  <span>
                    {{
                      createDecliningLabel(resultCardsCoordinates.length, [
                        'объект',
                        'объекта',
                        'объектов',
                      ])
                    }}
                  </span>
                </div>
                <div v-else class="items-in-line gap--5">Показать</div>
              </template>
            </base-button>
          </div>
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script lang="ts" setup>
import { MapBlock } from '@/components';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { OfferCardCoordinatesType } from '@/api/house/types';
import { useOfferCardsStore } from '@/store/modules/offerCards';
import { FilterBlock, FilterBtns } from '@/components/MainPageComponents';
import InputGroup from '@/components/MainPageComponents/Banner/InputGroup/InputGroup.vue';
import { createDecliningLabel } from '@/mixins/Common/Common';

const isShowFilterBlock = ref(false);
const isShowSearchBlock = ref(false);

const resultCardsCoordinates = computed((): OfferCardCoordinatesType[] => {
  return useOfferCardsStore().offerCardsCoordinates;
});
const isLoading = computed(() => {
  return useOfferCardsStore().isLoadingResultBlock;
});

const activeFilterBtn = computed(() => {
  const param306 = useOfferCardsStore().params[306]?.value;

  if (param306) {
    switch (param306) {
      case '265':
        return useOfferCardsStore().filterBtnsByType[0];
      case '266':
        return useOfferCardsStore().filterBtnsByType[1];
      case '267':
        return useOfferCardsStore().filterBtnsByType[2];
    }
  } else {
    return useOfferCardsStore().filterBtnsByType[0];
  }
});

onMounted(() => {
  useOfferCardsStore().toggleIsShowResultBlock(false);
});

onUnmounted(() => {
  useOfferCardsStore().hideMapBlock();
});
</script>

<style lang="scss" scoped src="./FullMapSearch.scss"></style>
