<template>
  <div class="widget">
    <div class="center widget__spinner">
      <base-spinner :size="50" color="#009D3E" />
    </div>
    <div>
      <iframe :src="widgetUrl" class="widget__content"></iframe>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useAppStore } from '@/store/modules/app';

const widgetUrl = computed(() => {
  return `${useAppStore().baseUrl}${process.env.VUE_APP_API_CHECK_WIDGET_CLIENT}`;
});
</script>

<style lang="scss" scoped src="./Widget.scss"></style>
