import { App } from 'vue';
import { RouterName, RouterPath } from './types';
import { createRouter, createWebHistory } from 'vue-router';
import {
  CheckResult,
  Classification,
  Compare,
  DeveloperCardPageLayout,
  DeveloperCards,
  FullMapSearch,
  JuristicDocuments,
  Main,
  Mortgage,
  NotFound,
  OfferCardPageLayout,
  Profile,
  ResidentialComplexCards,
  ResidentialComplexPageLayout,
  Services,
  UpdateMyCard,
} from '@/views';
import { useUserStore } from '@/store/modules/user';
import { useAppStore } from '@/store/modules/app';

const routes = [
  {
    path: RouterPath.main,
    name: RouterName.main,
    component: Main,
  },
  {
    path: RouterPath.fullMap,
    name: RouterName.fullMap,
    component: FullMapSearch,
    meta: {
      withoutNav: true,
    },
  },
  {
    path: RouterPath.login,
    name: RouterName.login,
    component: Main,
  },
  {
    path: RouterPath.passwordRestore,
    name: RouterName.passwordRestore,
    component: Main,
  },
  {
    path: RouterPath.classification,
    name: RouterName.classification,
    component: Classification,
  },
  {
    path: RouterPath.checkResult,
    name: RouterName.checkResult,
    component: CheckResult,
  },
  {
    path: RouterPath.profile,
    name: RouterName.profile,
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: RouterPath.offerCard,
    name: RouterName.offerCard,
    component: OfferCardPageLayout,
  },
  {
    path: RouterPath.updateOfferCard,
    name: RouterName.updateOfferCard,
    component: UpdateMyCard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: RouterPath.residentialComplex,
    name: RouterName.residentialComplex,
    component: ResidentialComplexPageLayout,
  },
  {
    path: RouterPath.updateResidentialComplex,
    name: RouterName.updateResidentialComplex,
    component: UpdateMyCard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: RouterPath.developerCards,
    name: RouterName.developerCards,
    component: DeveloperCards,
  },
  {
    path: RouterPath.updateDeveloper,
    name: RouterName.updateDeveloper,
    component: UpdateMyCard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: RouterPath.developer,
    name: RouterName.developer,
    component: DeveloperCardPageLayout,
  },
  {
    path: RouterPath.residentialComplexCards,
    name: RouterName.residentialComplexCards,
    component: ResidentialComplexCards,
  },
  {
    path: RouterPath.mortgage,
    name: RouterName.mortgage,
    component: Mortgage,
  },
  {
    path: RouterPath.services,
    name: RouterName.services,
    component: Services,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: RouterPath.compare,
    name: RouterName.compare,
    component: Compare,
  },
  {
    path: RouterPath.juristicDocuments,
    name: RouterName.juristicDocuments,
    component: JuristicDocuments,
  },
  {
    path: RouterPath.notFound,
    name: RouterName.notFound,
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    }
    window.scrollTo(0, 0);
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (useUserStore().user === null) {
      useAppStore().openAuthModal(true);
    } else {
      next();
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (useUserStore().user && to.path.includes('user')) {
    next({ name: 'main' });
  } else {
    next();
  }
});
export const setupRouter = (app: App<Element>) => {
  app.use(router);
};
export default router;
