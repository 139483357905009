<template>
  <div>
    <LoadingPaginationGroup v-if="isLoading && !autoAddMobile" :showBtn="showBtn" />
    <div
      v-else
      :class="{ withoutBtn: !showBtn, 'none-Mobile': autoAddMobile }"
      class="pagination-group"
    >
      <base-pagination
        :length="paginationLength"
        :modelValue="page"
        :showFirstLast="windowWidth > 960"
        :style="style"
        :totalVisible="totalVisible"
        @update:modelValue="changePage($event)"
      />
      <div v-if="showBtn" class="pagination-group__btn">
        <base-button
          v-if="isMoreLoading || page < paginationLength"
          :loading="isMoreLoading"
          height="36"
          text="Показать еще"
          width="165"
          @click="showMoreClick"
        />
      </div>
      <div v-if="sortItems && sortItems.length" class="pagination-group__inputBox">
        Показывать по:
        <base-select-input
          v-model="itemCountOnPage"
          :options="sortItems"
          :show-search="false"
          class="pagination-group__inputBox-input"
          type="text"
          @update:modelValue="changeItemCountOnPage(+$event)"
        />
      </div>
    </div>
    <div
      v-if="autoAddMobile"
      ref="autoMobilePagination"
      class="center pagination-group__auto-mobile"
    >
      <base-spinner v-if="isMoreLoading" :size="50" color="#009D3E" />
    </div>
  </div>
</template>

<script generic="T" lang="ts" setup>
import { computed, onMounted, ref, Ref, watch } from 'vue';
import { useAppStore } from '@/store/modules/app';
import { StylesType } from '@/types';
import LoadingPaginationGroup from '@/components/UI/BasePaginationGroup/LoadingPaginationGroup.vue';
import { useElementVisibility } from '@vueuse/core';

// eslint-disable-next-line no-undef
const model = defineModel<T[]>();

const props = withDefaults(
  defineProps<{
    // eslint-disable-next-line no-undef
    values: T[];
    totalVisible?: number;
    style?: 'base' | 'simple';
    minForVisible?: number;
    maxForVisible?: number;
    stepForVisible?: number;
    inputStyles?: StylesType;
    showBtn?: boolean;
    itemCountOnPage?: number;
    isLoading?: boolean;
    isMoreLoading?: boolean;
    objectCount?: number;
    customPage?: number;
    autoAddMobile?: boolean;
  }>(),
  {
    totalVisible: 3,
    style: 'simple',
    minForVisible: 5,
    maxForVisible: 40,
    stepForVisible: 5,
    inputStyles: () => ({ border: 'none' }),
    showBtn: true,
    itemCountOnPage: 5,
  },
);

const emit = defineEmits(['changePageAndPageSize']);

const autoMobilePagination = ref<HTMLInputElement>();
const isVisibleMobilePagination = useElementVisibility(autoMobilePagination);

const windowWidth = computed((): number => {
  return useAppStore().window;
});
const sortItems = computed(() => {
  let items = [];
  let max = Math.ceil(props.maxForVisible / props.stepForVisible) * props.stepForVisible;

  for (let i = props.minForVisible; i <= max; i = i + props.stepForVisible) {
    items.push({ id: i, name: `${i}` });
  }

  return items;
});
const itemCountOnPage: Ref<number> = ref(props.itemCountOnPage);
const page = ref(1);
const showMoreCount: Ref<number> = ref(0);
const countValues = computed(() => {
  return props.objectCount ? props.objectCount : props.values.length;
});
const paginationLength = computed(() => {
  return Math.ceil(countValues.value / itemCountOnPage.value);
});
const changePage = (pageNumber: number) => {
  page.value = pageNumber;
  emit('changePageAndPageSize', { page: pageNumber, pageSize: +itemCountOnPage.value });
  showMoreCount.value = 0;
  updateValues();
};
const changeItemCountOnPage = (count: number) => {
  if (paginationLength.value < page.value) {
    page.value = countValues.value <= 0 ? 1 : Math.ceil(countValues.value / count);
  }
  itemCountOnPage.value = count;

  emit('changePageAndPageSize', { page: page.value, pageSize: count });
  updateValues();
};

const showMoreClick = () => {
  showMoreCount.value = showMoreCount.value + 1;
  page.value = page.value + 1;
  emit('changePageAndPageSize', {
    page: page.value,
    pageSize: +itemCountOnPage.value,
    showMore: true,
  });
  updateValues();
};

const updateValues = () => {
  model.value = props.values.slice(
    (page.value - 1 - showMoreCount.value) * itemCountOnPage.value,
    itemCountOnPage.value * page.value,
  );
};

onMounted(() => {
  updateValues();
  if (props.customPage) {
    page.value = props.customPage;
  }
});
watch(
  () => props.values,
  () => {
    updateValues();
  },
  {
    deep: true,
  },
);

watch(
  () => props.customPage,
  newValue => {
    if (newValue) {
      page.value = newValue;
    }
  },
);

watch(
  () => isVisibleMobilePagination.value,
  () => {
    if (
      isVisibleMobilePagination.value &&
      !props.isMoreLoading &&
      page.value < paginationLength.value
    ) {
      showMoreClick();
    }
  },
);
</script>

<style lang="scss" scoped src="./BasePaginationGroup.scss"></style>
