<template>
  <div :id="`chat${uuid}`" class="chat">
    <div class="chat__content">
      <div v-for="group in preparedMessages">
        <div
          v-if="group[group.length - 1].isNewDate"
          class="center chat__message-time mt--10 mb--10"
        >
          {{ getRuDate(group[group.length - 1].created_at) }}
        </div>
        <div
          :class="{
            'chat__item--responder': group[0].user_id === useUserStore().user?.id,
          }"
          class="chat__item"
        >
          <div v-if="withAva" class="chat__person-avatar">
            <base-image
              :src="require('@/assets/img/profile/avatar.png')"
              contain
              height="25"
              name="ava"
              width="25"
            />
          </div>

          <div class="chat__messages">
            <div v-for="message in group" class="chat__message">
              <div class="chat__message-content">{{ message.message }}</div>
              <div class="chat__message-time">
                {{
                  new Date(message.created_at).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { MessageType } from '@/api/chat/types';
import { computed, getCurrentInstance, onMounted, ref, watch } from 'vue';
import { useUserStore } from '@/store/modules/user';
import { ComponentInternalInstance } from '@vue/runtime-core';

type PreparedMessageType = {
  isNewDate?: boolean;
} & MessageType;

const props = withDefaults(
  defineProps<{
    withAva?: boolean;
    messages: MessageType[];
  }>(),
  {
    withAva: true,
  },
);
const instance: ComponentInternalInstance | null = getCurrentInstance();
const uuid = ref(instance?.uid);

const getRuDate = (date: string): string => {
  return new Date(date).toLocaleString('ru', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

const preparedMessages = computed((): PreparedMessageType[][] => {
  if (props.messages) {
    let preparedMessages: PreparedMessageType[][] = [];
    let index = 0;

    props.messages.forEach((el, i) => {
      if (i === 0) {
        preparedMessages.push([{ ...el, isNewDate: true }]);
      } else {
        if (
          props.messages[i - 1].user_id === el.user_id &&
          new Date(props.messages[i - 1].created_at).toDateString() ===
            new Date(el.created_at).toDateString()
        ) {
          preparedMessages[0].unshift(el);
        } else {
          index++;
          preparedMessages.unshift([
            {
              ...el,
              isNewDate:
                new Date(props.messages[i - 1].created_at).toDateString() !==
                new Date(el.created_at).toDateString(),
            },
          ]);
        }
      }
    });

    return preparedMessages;
  }

  return [];
});

const scrollToBottom = () => {
  let div = document.getElementById(`chat${uuid.value}`);

  if (div) {
    div.scrollTop = div.scrollHeight;
  }
};

onMounted(() => {
  scrollToBottom();
});

watch(
  () => props.messages,
  () => {
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  },
);
</script>

<style lang="scss" scoped src="./ChatComponent.scss"></style>
