<template>
  <div class="items-in-line">
    <div ref="prev" class="swiper-button-prev" />
    <swiper
      :id="`swiper${uuid}`"
      :modules="modules"
      :navigation="{
        prevEl: prev,
        nextEl: next,
      }"
      :slides-per-group="maxItemsToShow"
      :slides-per-view="maxItemsToShow"
      :space-between="spaceBetween"
      class="swiper"
      @swiper="$emit('swiper')"
    >
      <swiper-slide v-for="(slide, i) in slides" :key="i">
        <slot :data="slide" name="item"></slot>
      </swiper-slide>
    </swiper>
    <div ref="next" class="swiper-button-next" />
  </div>
</template>

<script generic="T" lang="ts" setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Thumbs } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { computed, getCurrentInstance, onMounted, ref, Ref, watch } from 'vue';
import { useAppStore } from '@/store/modules/app';
import { ComponentInternalInstance } from '@vue/runtime-core';

const modules = [Pagination, Navigation, Thumbs];

const props = withDefaults(
  defineProps<{
    // eslint-disable-next-line no-undef
    slides: T[];
    widthItem?: number;
    itemsToShow?: number;
    spaceBetween?: number;
  }>(),
  {
    widthItem: 120,
    spaceBetween: 14,
  },
);
const emit = defineEmits(['swiper']);
const window = computed((): number => {
  return useAppStore().window;
});
const instance: ComponentInternalInstance | null = getCurrentInstance();
const uuid = ref(instance?.uid);
const galleryWidth: Ref<number | undefined> = ref(undefined);
const maxItemsToShow: Ref<number> = ref(1);
const prev = ref(null);
const next = ref(null);
const updateGalleryWidth = () => {
  if (props.slides?.length > 1) {
    const thumbnails = document.getElementById(`swiper${uuid.value}`);

    galleryWidth.value = thumbnails?.offsetWidth;
    updateMaxItemsToShow();
  }
};

const updateMaxItemsToShow = () => {
  if (galleryWidth.value) {
    maxItemsToShow.value =
      (galleryWidth.value - 60) / (props.widthItem + props.spaceBetween);
  } else {
    updateGalleryWidth();
  }
};

onMounted(() => {
  if (props.itemsToShow) {
    maxItemsToShow.value = props.itemsToShow;
  } else {
    updateGalleryWidth();
  }
});
watch(
  () => window.value,
  () => {
    if (!props.itemsToShow) {
      updateGalleryWidth();
    }
  },
);
</script>

<style lang="scss" scoped src="./BaseSwiper.scss"></style>
