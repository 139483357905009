<template>
  <div class="menu-block">
    <div v-for="(item, i) in menuItems" :key="item.id">
      <a
        :class="{ 'gradient-color': activeMenuItemId !== item.id }"
        :href="`#${item.id}`"
        class="menu-block__item"
        @click="changeMenuItem(item.id)"
      >
        {{ item.title }}
      </a>
      <base-divider v-if="i !== menuItems.length - 1" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { inject, onMounted, ref } from 'vue';
import { SmoothScrollOptions } from 'vue3-smooth-scroll';

const props = withDefaults(
  defineProps<{
    menuItems: { id: string; title: string }[];
  }>(),
  {},
);

const activeMenuItemId = ref('');
const smoothScroll = inject('smoothScroll') as (args: SmoothScrollOptions) => void;
const changeMenuItem = (menuItemId: string) => {
  const myEl = document.getElementById(menuItemId);

  if (myEl) {
    smoothScroll({
      scrollTo: myEl,
      offset: 0,
    });
  }

  activeMenuItemId.value = menuItemId;
};

onMounted(() => {
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        activeMenuItemId.value = entry.target.getAttribute('id') ?? '';
      }
    });
  });

  document.querySelectorAll('.section').forEach(section => {
    observer.observe(section);
  });
});
</script>

<style lang="scss" scoped src="./MenuBlock.scss"></style>
