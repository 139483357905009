<template>
  <div class="banner">
    <div class="banner-filter" />
    <div class="container content banner__content">
      <h1>Загородная недвижимость {{ regionGenitive }}</h1>

      <base-form class="banner__content-actions">
        <div class="banner__content-btns">
          <FilterBtns />
          <base-button-group
            v-if="activeFilterBtn?.text === 'Снять'"
            :active="activeRentalBtn"
            :buttons="filterBtnsByRental"
            btnHeight="34"
            btnWidth="122"
            class="banner__content-btns-right"
            type="translucentGreen"
            @changeActiveBtn="activeRentalBtn = $event"
          />
        </div>
        <div class="banner__content-inputs">
          <InputGroup height="44" />
          <div class="banner__content-inputs-setting">
            <base-button
              height="44"
              icon
              style-type="white"
              width="44"
              without-active
              @click="isShowFilterBlock = !isShowFilterBlock"
            >
              <template #icon>
                <base-icon color="#614B40FF" font-size="30" name="tune" />
              </template>
            </base-button>
          </div>
        </div>
        <div class="banner__content-showBtns">
          <base-button
            height="44"
            padding="15"
            style-type="white"
            @click="showMapBlock()"
          >
            <template #text>
              <div class="items-in-line gap--5">
                <base-image
                  :src="require('@/assets/img/banner/icon-for-map.svg')"
                  contain
                  height="25"
                  name="map"
                  width="25"
                />
                На карте
              </div>
            </template>
          </base-button>
          <base-button
            height="44"
            padding="15"
            @click="useOfferCardsStore().showResultBlock()"
          >
            <template #text>
              <div v-if="objectCount" class="items-in-line gap--5">
                <span>Найдено</span>
                <span v-if="isLoading"> <base-spinner /></span>
                <span v-else>{{ objectCount }}</span>
                <span> объекта </span>
              </div>
              <div v-else class="items-in-line gap--5">
                <base-icon color="#ffffff" name="search" />
                Найти
              </div>
            </template>
          </base-button>
        </div>
        <div class="banner__content-showMobileBtns">
          <base-button
            height="44"
            icon
            style-type="white"
            width="44"
            withoutActive
            @click="isShowFilterBlock = !isShowFilterBlock"
          >
            <template #icon>
              <base-icon color="#614B40FF" font-size="30" name="tune" />
            </template>
          </base-button>
          <base-button
            height="44"
            icon
            style-type="white"
            width="44"
            without-active
            @click="showMapBlock()"
          >
            <template #text>
              <base-image
                :src="require('@/assets/img/banner/icon-for-map.svg')"
                contain
                height="25"
                name="map"
                width="25"
              />
            </template>
          </base-button>
          <base-button
            height="44"
            padding="15"
            @click="useOfferCardsStore().showResultBlock()"
          >
            <template #text>
              <div v-if="objectCount" class="items-in-line gap--5">
                <span>Найдено</span>
                <span v-if="isLoading"> <base-spinner /></span>
                <span v-else>{{ objectCount }}</span>
                <span> объекта </span>
              </div>
              <div v-else class="items-in-line gap--5">
                <base-icon color="#ffffff" name="search" />
                Найти
              </div>
            </template>
          </base-button>
        </div>
      </base-form>
    </div>
    <FilterBlock
      :is-show-filter-block="isShowFilterBlock"
      :isFilterByTerritory="activeFilterBtn?.id === 3"
      :show-coordinates="isShowMapBlock"
      :show-list-block="isShowResultBlock"
      @close="isShowFilterBlock = false"
      @show-result-block="useOfferCardsStore().toggleIsShowResultBlock(true)"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useOfferCardsStore } from '@/store/modules/offerCards';
import InputGroup from '@/components/MainPageComponents/Banner/InputGroup/InputGroup.vue';
import { useAppStore } from '@/store/modules/app';
import { useRouter } from 'vue-router';
import { FilterBlock, FilterBtns } from '@/components/MainPageComponents';

const isShowFilterBlock = ref(false);
const regionGenitive = computed((): string | undefined => {
  return useAppStore().getGenitive();
});

const activeFilterBtn = computed(() => {
  return useOfferCardsStore().activeFilterBtn;
});

const objectCount = computed(() => {
  return useOfferCardsStore().objectCount;
});
const isLoading = computed(() => {
  return useOfferCardsStore().isLoadingResultBlock;
});
const isShowMapBlock = computed(() => {
  return useOfferCardsStore().isShowMapBlock;
});
const isShowResultBlock = computed(() => {
  return useOfferCardsStore().isShowResultBlock;
});

const activeRentalBtn = ref(0);
const filterBtnsByRental = [
  {
    id: 'rental1',
    text: 'Посуточно',
  },
  {
    id: 'rental2',
    text: 'Долгострочно',
  },
];

const router = useRouter();
const showMapBlock = () => {
  if (useAppStore().getWindowWidth() <= 600) {
    router.push({ name: 'fullMap' });
    useOfferCardsStore().toggleIsShowResultBlock(false);
  }

  useOfferCardsStore().showResultBlock(true);
};
</script>

<style lang="scss" scoped src="./Banner.scss"></style>
