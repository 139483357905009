<template>
  <teleport to="#modalWindow">
    <div id="tooltip" class="tooltip"></div>
  </teleport>

  <div
    class="tooltip-slot"
    @click="openSheet()"
    @mousemove="mouseEnter($event)"
    @mouseout="mouseLeave"
  >
    <slot></slot>
  </div>
  <base-bottom-sheet :sheet="sheet" close-outsize @close="closeSheet">
    {{ text }}
  </base-bottom-sheet>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    text?: string;
    showInMobile?: boolean;
    active?: boolean;
  }>(),
  {
    text: 'Текст',
    showInMobile: true,
    active: true,
  },
);

const isMobile = computed((): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
    navigator.userAgent,
  );
});
const mouseEnter = (e: MouseEvent) => {
  if (!isMobile.value && props.active) {
    let text = document.querySelector<HTMLElement>('.tooltip')!;

    text.style.display = 'flex';
    text.style.left = e.pageX + 10 + 'px';
    text.style.top = e.pageY - 20 + 'px';
    text.textContent = props.text;
  }
};
const mouseLeave = () => {
  if (!isMobile.value && props.active) {
    let text = document.querySelector<HTMLElement>('.tooltip')!;

    text.textContent = '';
    text.style.display = 'none';
  }
};

const sheet = ref(false);

const closeSheet = () => {
  let body = document.querySelector<HTMLElement>('body')!;

  if (body) {
    body.style.height = 'auto';
    body.style.width = 'auto';
    body.style.overflow = 'visible';
  }

  sheet.value = false;
};
const openSheet = () => {
  if (isMobile.value && props.showInMobile) {
    setTimeout(() => {
      let body = document.querySelector<HTMLElement>('body')!;

      if (body) {
        body.style.height = '100vh';
        body.style.width = '100vw';
        body.style.overflow = 'hidden';
      }
      sheet.value = true;
    });
  }
};
</script>

<style lang="scss" scoped src="./BaseTooltip.scss"></style>
