<template>
  <div class="divider" :class="{ vertical, inset }" :style="cssProps" />
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  vertical?: boolean;
  inset?: string;
  color?: string;
}>();

const cssProps = computed(() => {
  return {
    '--background-color': props.color,
    '--inset-size': props.inset,
  };
});
</script>

<style scoped lang="scss" src="./BaseDivider.scss"></style>
