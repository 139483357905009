<template>
  <div class="profile-menu">
    <div
      v-for="(category, i) in profileMenuItems"
      :key="i"
      class="profile-menu__category"
    >
      <base-divider v-if="i !== 0" class="mt--10 mb--10" />
      <div class="profile-menu__category-items">
        <router-link
          v-for="menuItem in category.filter(item => !item.hide)"
          :key="menuItem.id"
          :to="{ name: 'profile', params: { id: menuItem.id } }"
          class="items-in-line gap--10 space-between"
          @click="changeMenuItem(menuItem)"
        >
          <span
            :class="
              activeMenuItem?.id === menuItem.id ? 'custom-color' : 'gradient-color'
            "
            >{{ menuItem.title }}</span
          >
          <span v-if="menuItem.count" class="profile-menu__category-items-item-count">
            {{ menuItem.count }}
          </span>
        </router-link>
      </div>
    </div>
    <button class="gradient-color mt--10" @click="logout">Выйти</button>
  </div>
</template>

<script lang="ts" setup>
import { ProfileMenuItemsType, ProfileMenuItemType } from '@/types';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/store/modules/user';

const props = defineProps<{
  activeMenuItem: ProfileMenuItemType | undefined;
  profileMenuItems: ProfileMenuItemsType;
}>();
const emit = defineEmits(['changeActiveMenuItem']);

const changeMenuItem = (menuItem: ProfileMenuItemType) => {
  emit('changeActiveMenuItem', menuItem);
};
const router = useRouter();

const logout = async () => {
  await useUserStore().asyncLogout();
};
</script>

<style lang="scss" scoped src="./ProfileMenu.scss"></style>
