<template>
  <svg :style="[cssProps]" class="spinner" viewBox="0 0 50 50">
    <circle class="path" cx="25" cy="25" fill="none" r="20" stroke-width="5"></circle>
  </svg>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    size?: number;
    color?: string;
  }>(),
  {
    size: 25,
    color: '#ffffff',
  },
);
const cssProps = computed(() => {
  return {
    '--spinner-size': `${props.size}px`,
    '--spinner-color': props.color,
  };
});
</script>

<style lang="scss" scoped src="./BaseSpinner.scss"></style>
