<template>
  <div v-click-outside="clickOutside" class="menu">
    <div :class="{ open: isMenuOpen }" class="menu-btn" @click="handleClick">
      <div class="icon"></div>
    </div>
    <div id="menu-dropdown" :class="{ show: isMenuOpen }" class="menu-dropdown">
      <slot :events="{ handleClick }" name="menuItems"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

const isMenuOpen = ref(false);

const clickOutside = () => {
  if (isMenuOpen.value) {
    isMenuOpen.value = false;
  }
};
const handleClick = () => {
  isMenuOpen.value = !isMenuOpen.value;
  if (isMenuOpen.value) {
    const menu: HTMLInputElement | null = document.querySelector('#menu-dropdown');

    if (menu) {
      const screenHeight = window.innerHeight;
      const topPosition = menu.getBoundingClientRect().top;
      const heightMenu = menu.getBoundingClientRect().height;

      if (topPosition + heightMenu > screenHeight) {
        menu.style.bottom = '50px';
      }
    }
  }
};
</script>

<style lang="scss" scoped src="./BaseMenu.scss"></style>
