<template>
  <div class="footer items-in-column">
    <div class="container items-in-column">
      <div class="footer__top-block">
        <div class="footer__top-block-docs">
          <router-link
            :to="{ name: 'juristicDocuments' }"
            class="pointer underline footer__top-block-doc"
            >Юридические документы
          </router-link>
        </div>

        <div class="items-in-line footer__top-block-socials">
          <base-image
            v-for="social in socials"
            :key="social.id"
            :alt="social.id"
            :src="social.icon"
            class="pointer tertiaryLightColorFilter"
            contain
            height="30"
            width="30"
          />
        </div>
      </div>
      <base-divider color="#a79f9a" />
      <div class="footer__bottom-block">
        <router-link :to="{ name: 'main' }">
          <base-image
            :src="require('@/assets/img/header/logo.svg')"
            contain
            height="30"
            name="logo"
            width="150"
          />
          <div class="header__logo-mobile" />
        </router-link>
        <div class="footer__bottom-block-contacts">
          <div class="items-in-line gap--10 footer__bottom-block-hover">
            <base-image
              :src="require('@/assets/img/footer/icon-call.svg')"
              alt="phone"
              class="tertiaryLightColorFilter"
              contain
              height="20"
              width="20"
            />
            <a href="tel:+79215810509">8 (921) 581-05-09</a>
          </div>
          <div class="items-in-line gap--10 footer__container-hover">
            <base-image
              :src="require('@/assets/img/footer/icon-mail.svg')"
              alt="email"
              class="tertiaryLightColorFilter"
              contain
              height="20"
              width="20"
            />
            <a href="mailto:bs1@banki.shop">bs1@banki.shop</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const socials = [
  {
    id: 'vk',
    icon: require('@/assets/img/footer/icon-vk.svg'),
  },
  {
    id: 'tg',
    icon: require('@/assets/img/footer/icon-tg.svg'),
  },
  {
    id: 'whatsapp',
    icon: require('@/assets/img/footer/icon-wapp.svg'),
  },
  {
    id: 'youtube',
    icon: require('@/assets/img/footer/icon-yt.svg'),
  },
];
</script>

<style lang="scss" scoped src="./Footer.scss"></style>
