<template>
  <div :class="type" :style="[cssProps]" class="skeleton-loading" />
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
  type: 'img' | 'mediumText' | 'text' | 'btn' | 'icon' | 'input';
  styles?: {
    width?: string;
    height?: string;
    borderRadius?: string;
  };
}>();
const cssProps = computed(() => {
  return {
    '--width': props.styles?.width,
    '--height': props.styles?.height,
    '--border-radius': props.styles?.borderRadius,
  };
});
</script>

<style lang="scss" scoped src="./BaseSkeletonLoader.scss"></style>
