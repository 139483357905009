<template>
  <div>
    <div v-if="isInput">
      <div class="fileInputBox paddingRight">
        <base-input
          id="txt"
          :label="title"
          append-icon="add"
          type="text"
          withoutFocus
          @click="
            showCreateCategoryModal({
              categoryTitle: title,
              categoryId: categoryId,
              parentId: parentId,
            })
          "
        />
      </div>

      <div v-if="editableCategory.length" class="inputBox__file-link mt--5">
        <base-popup :show-close-icons="false" start>
          <button class="hoverUnderline">Список созданных элементов</button>
          <template #content>
            <div class="fileInputBox__popup-menu">
              <div
                v-for="(category, i) in editableCategory"
                :key="i"
                class="fileInputBox__popup-menu-item"
              >
                <div>{{ category.title }}</div>
                <div class="items-in-line">
                  <base-icon
                    clickable
                    color="black"
                    font-size="16"
                    height="10"
                    name="close"
                    width="10"
                    @click="showDeleteCategoryModal(category.id)"
                  />
                  <base-icon
                    clickable
                    color="black"
                    font-size="16"
                    height="10"
                    name="edit"
                    width="10"
                    @click="$emit('changeCategory', category.id)"
                  />
                </div>
              </div>
            </div>
          </template>
        </base-popup>
      </div>
    </div>
    <div v-else>
      <div class="m-font-size mb--20 items-in-line flex-start">
        Следующий шаг - создать {{ title }}

        <base-button
          :text="useAppStore().getWindowWidth() > 600 ? '+ Создать' : 'Создать'"
          class="scale"
          height="36"
          uppercase
          without-active
          @click="
            showCreateCategoryModal({
              categoryTitle: title,
              categoryId: categoryId,
              parentId: parentId,
            })
          "
        />
      </div>
      <div v-if="editableCategory.length" class="update-developer-categories">
        <div v-for="category in editableCategory">
          <base-card class="update-developer-categories__card">
            <div>{{ category.title }}</div>

            <div class="items-in-line">
              <base-button
                :loading="idDeleteBtnLoading === category.id"
                style-type="coloredBorder"
                text="Удалить"
                @click="showDeleteCategoryModal(category.id)"
              />

              <base-button
                text="Редактировать"
                @click="$emit('changeCategory', category.id)"
              />
            </div>
          </base-card>
        </div>
      </div>
      <div v-else>Не найдено</div>
    </div>

    <CreateDeveloperModal
      :create-category-modal-data="createCategoryModalData"
      @close="closeCreateCategoryModal"
      @create-category="createCategory($event)"
    />
    <DeleteDeveloperModal
      :delete-category-modal-data="deleteCategoryModalData"
      @close="closeDeleteCategoryModal"
      @delete-category="deleteCategory"
    />
  </div>
</template>
<script lang="ts" setup>
import { Ref, ref } from 'vue';
import {
  CreateDeveloperModal,
  DeleteDeveloperModal,
} from '@/components/ProfileComponents';
import { CreateCategoryModalType, DeleteCategoryModalType } from '@/types';
import { useAppStore } from '@/store/modules/app';

const props = withDefaults(
  defineProps<{
    editableCategory: { id: number; title: string }[];
    title: string;
    parentId: number;
    categoryId: string;
    isInput?: boolean;
  }>(),
  {
    isInput: false,
  },
);
const emit = defineEmits(['changeCategory', 'createCategory', 'deleteCategory']);
const createCategoryModalData: Ref<CreateCategoryModalType> = ref({
  show: false,
  categoryTitle: undefined,
  categoryId: undefined,
  parentId: undefined,
});
const deleteCategoryModalData: Ref<DeleteCategoryModalType> = ref({
  show: false,
  id: undefined,
});
const loading = ref(false);
const idDeleteBtnLoading: Ref<number | undefined> = ref(undefined);
const showCreateCategoryModal = (event: {
  categoryTitle: string;
  categoryId: string;
  parentId?: number;
}) => {
  createCategoryModalData.value = {
    show: true,
    categoryTitle: event.categoryTitle,
    categoryId: event.categoryId,
    parentId: event.parentId,
  };
};
const closeCreateCategoryModal = () => {
  createCategoryModalData.value = {
    show: false,
    categoryTitle: undefined,
    parentId: undefined,
    categoryId: undefined,
  };
};

const showDeleteCategoryModal = (id: number) => {
  deleteCategoryModalData.value = {
    show: true,
    id: id,
  };
};

const closeDeleteCategoryModal = () => {
  deleteCategoryModalData.value = {
    show: false,
    id: undefined,
  };
};

const createCategory = async (event: { id: number; title: string }) => {
  emit('createCategory', event);
};

const deleteCategory = async (id: number) => {
  emit('deleteCategory', id);
};
</script>

<style lang="scss" scoped src="./UpdateCategories.scss"></style>
<style lang="scss" scoped src="@/components/UI/BaseInputs/BaseInput.scss"></style>
