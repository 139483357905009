import request from '@/axios';
import {
  ChangePasswordDataType,
  LoginDataType,
  PasswordRestoreDataType,
  RegistrationDataType,
  UserDataType,
} from '@/api/login/types';

export const registrationApi = (
  data: RegistrationDataType,
): Promise<IResponse<{ message: string }>> => {
  return request.post({ url: process.env.VUE_APP_API_USER_REGISTRATION, data });
};
export const loginApi = (data: LoginDataType): Promise<IResponse<UserDataType>> => {
  return request.post({ url: process.env.VUE_APP_API_USER_LOGIN, data });
};

export const logoutApi = (): Promise<IResponse<{ message: string }>> => {
  return request.get({ url: process.env.VUE_APP_API_USER_LOGOUT });
};

export const getUserLoginApi = (): Promise<IResponse<UserDataType>> => {
  return request.get({ url: process.env.VUE_APP_API_USER_PROFILE });
};
export const passwordRestoreRequestApi = (data: {
  email: string | undefined;
}): Promise<IResponse<{ message: string }>> => {
  return request.post({
    url: process.env.VUE_APP_API_USER_PASSWORD_RESTORE_REQUEST,
    data,
  });
};

export const passwordRestoreApi = (
  data: PasswordRestoreDataType,
): Promise<IResponse<{ message: string }>> => {
  return request.post({
    url: process.env.VUE_APP_API_USER_PASSWORD_RESTORE,
    data,
  });
};

export const changePasswordApi = (
  data: ChangePasswordDataType,
): Promise<IResponse<{ message: string }>> => {
  return request.post({
    url: process.env.VUE_APP_API_USER_CHANGE_PASSWORD,
    data,
  });
};
