import request from '@/axios';
import {
  ChannelsTypeFromBack,
  MessageSendTypeFromBack,
  messagesFromChanelTypeFromBack,
} from '@/api/chat/types';

export const findChannelByLandIdApi = (
  entity_id: number,
): Promise<IResponse<{ channelId: number | null }>> => {
  return request.get({
    url: process.env.VUE_APP_API_CLIENT_MESSENGER_FIND_CHANNEL_BY_LAND_ID,
    params: {
      entity_id: entity_id,
    },
  });
};
export const sendMessageByLandIdApi = (data: {
  message: string;
  entity_id: number;
}): Promise<
  IResponse<{ messageId: number; messageCreatedAt: string; channelId: number }>
> => {
  return request.post({
    url: process.env.VUE_APP_API_CLIENT_MESSENGER_OPEN_CHANNEL_BY_LAND_ID,
    data,
  });
};
export const sendMessageInChannelApi = (data: {
  message: string;
  channel_id: number;
}): Promise<IResponse<{ messageId: number; messageCreatedAt: string }>> => {
  return request.post({
    url: process.env.VUE_APP_API_CLIENT_MESSENGER_SEND_MESSAGE_IN_CHANNEL,
    data,
  });
};

export const getChannelsApi = (): Promise<IResponse<ChannelsTypeFromBack>> => {
  return request.get({
    url: process.env.VUE_APP_API_CLIENT_MESSENGER_GET_CHANNELS_BY_USER_ID,
  });
};

export const getCountChannelWithNewApi = (): Promise<
  IResponse<{ withNewMessage: number[] }>
> => {
  return request.get({
    url: process.env.VUE_APP_API_CLIENT_MESSENGER_GET_COUNT_CHANNEL_WITH_NEW,
  });
};

export const getChannelByIdApi = (
  channelId: number,
): Promise<IResponse<messagesFromChanelTypeFromBack>> => {
  return request.get({
    url: process.env.VUE_APP_API_CLIENT_MESSENGER_GET_MESSAGES_FROM_CHANEL,
    params: {
      channel_id: channelId,
    },
  });
};

export const readChannelByIdApi = (data: {
  channel_id: number;
}): Promise<IResponse<MessageSendTypeFromBack>> => {
  return request.post({
    url: process.env.VUE_APP_API_CLIENT_MESSENGER_MARK_LAST_READER_FOR_CHANNEL,
    data,
  });
};

export const deleteChannelByIdApi = (
  channelId: number,
): Promise<IResponse<{ message: string }>> => {
  return request.post({
    url: process.env.VUE_APP_API_CLIENT_MESSENGER_DELETE_CHANNEL_BY_LAND_ID,
    params: {
      channel_id: channelId,
    },
  });
};
