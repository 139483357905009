<template>
  <base-popup with-padding>
    <base-button
      :height="height"
      :style-type="btnType"
      :width="width"
      class="scale"
      icon
      without-active
    >
      <template #icon>
        <base-icon
          :color="color"
          :font-size="fontSize"
          :height="String(+height - 12)"
          :width="String(+width - 12)"
          contain
          name="share"
        />
      </template>
    </base-button>
    <template #content>
      <div>
        <div class="m-font-size mb--20">Поделиться</div>
        <div class="share-btns">
          <button
            class="items-in-column align-center"
            @click="shareHandler(vkLink, 'Vkontakte')"
          >
            <base-image
              :src="require('@/assets/img/socials/vk.png')"
              contain
              height="50"
              name="vk"
            />
            <span class="xs-font-size dark-text-color">Вконтакте</span>
          </button>
          <button
            class="items-in-column align-center"
            @click="shareHandler(okLink, 'Ok')"
          >
            <base-image
              :src="require('@/assets/img/socials/ok.png')"
              contain
              height="50"
              name="ok"
            />
            <span class="xs-font-size dark-text-color"> Одноклассники </span>
          </button>
          <button class="items-in-column align-center" @click="copyLink">
            <base-image
              :src="require('@/assets/img/socials/link.png')"
              contain
              height="50"
              name="link"
            />
            <span class="xs-font-size dark-text-color"> Скопировать ссылку </span>
          </button>
        </div>
      </div>
    </template>
  </base-popup>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    width?: string;
    height?: string;
    fontSize?: string;
    color?: string;
    btnType?: 'greenBorder' | 'border';
  }>(),
  {
    width: '36',
    height: '36',
    color: '#614B40FF',
    btnType: 'border',
  },
);
const link = computed(() => {
  return window.location.href;
});

const vkLink = computed(() => {
  return `https://vk.com/share.php?url=${link.value}`;
});

const okLink = computed(() => {
  return `https://connect.ok.ru/offer?url=${link.value}`;
});
const shareHandler = (link: string, target: string) => {
  window.open(
    link,
    target,
    'width=860, height=470, top=' +
      (screen.height - 470) / 2 +
      ',left=' +
      (screen.width - 860) / 2 +
      '',
  );

  return false;
};

const copyLink = () => {
  navigator.clipboard.writeText(link.value);
};
</script>

<style lang="scss" scoped src="./ShareBtn.scss"></style>
