<template>
  <div class="update-params-groups">
    <base-menu-with-tabs
      :activeComponent="activeParamsGroupId"
      :btn-ids-with-errors="errorsGroupIds"
      :componentBtns="componentBtns"
      bgColor="#fff"
      mobile-show-all-blocks
      withRadius
      @changeActiveBtn="changeActiveParamsGroupId($event)"
    >
      <template #content="contentProps">
        <div
          v-if="getActiveParams(contentProps.data)"
          class="update-params-groups__params update-params-groups__form"
        >
          <UpdateForm
            v-model:paramsData="paramsData"
            v-model:v$="v$"
            :errors="errors"
            :params="getActiveParams(contentProps.data)"
            :parent-id="parentId"
            :rules="rules"
            @validation-errors-handler-by-key="
              $emit('validationErrorsHandlerByKey', $event)
            "
            @input-touch="$emit('inputTouch', $event)"
            @focus-="$emit('focus', $event)"
            @change-category="$emit('changeCategory', $event)"
            @create-category="$emit('createCategory', [$event[0], $event[1]])"
            @delete-category="$emit('deleteCategory', [$event[0], $event[1]])"
          />
        </div>
        <div
          v-if="
            getActiveGroups(contentProps.data) &&
            getActiveGroups(contentProps.data).length
          "
        >
          <div class="update-params-groups__btns">
            <base-expansion-panels
              v-for="item in getActiveGroups(contentProps.data)"
              :key="item.id"
              styleType="simple-green"
            >
              <template #title="titleProps">
                <div class="update-params-groups__title">{{ item.title }}</div>
              </template>
              <template #content="contentProps">
                <div class="update-params-groups__form with-padding">
                  <UpdateForm
                    v-model:paramsData="paramsData"
                    v-model:v$="v$"
                    :errors="errors"
                    :params="paramValues.filter(el => el.group_id === item.id)"
                    :parent-id="parentId"
                    :rules="rules"
                    @validation-errors-handler-by-key="
                      $emit('validationErrorsHandlerByKey', $event)
                    "
                    @input-touch="$emit('inputTouch', $event)"
                    @focus-="$emit('focus', $event)"
                    @change-category="$emit('changeCategory', $event)"
                  />
                </div>
              </template>
            </base-expansion-panels>
          </div>
        </div>
      </template>
    </base-menu-with-tabs>
  </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, ref, Ref } from 'vue';
import { ErrorsType, ParamGroupType, ParamsDataType } from '@/types';
import { ParamsValuesType } from '@/api/developer/types';
import { UpdateForm } from '@/components';
import { ValidationRuleWithoutParams } from '@vuelidate/core';

const props = defineProps<{
  paramsGroups: ParamGroupType[];
  paramValues: ParamsValuesType[];
  parentId: number;
  errors: ErrorsType;
  rules: { [key: string]: { [key: string]: ValidationRuleWithoutParams } };
}>();

const emit = defineEmits([
  'validationErrorsHandlerByKey',
  'inputTouch',
  'focus',
  'changeCategory',
  'createCategory',
  'deleteCategory',
]);
// eslint-disable-next-line no-undef
const paramsData = defineModel<ParamsDataType>('paramsData');
// eslint-disable-next-line no-undef
const v$ = defineModel<ParamsDataType>('v$');

const errorsGroupIds = computed((): number[] => {
  let errorIds: number[] = [];

  componentBtns.value.forEach(group => {
    const params = getActiveParams(group.id);

    params.forEach(param => {
      if (props.errors[param.id]) {
        errorIds.push(group.id);
      }
    });
  });

  return errorIds;
});

const componentBtns = computed(() => {
  return props.paramsGroups
    .filter(group => group.parent === 0)
    .map(el => {
      return {
        id: el.id,
        title: el.title,
      };
    });
});

const activeParamsGroupId: Ref<undefined | number> = ref(undefined);

const getActiveParams = (categoryId: number | string) => {
  return props.paramValues.filter(el => el.group_id === categoryId);
};
const getActiveGroups = (categoryId: number | string) => {
  return props.paramsGroups.filter(el => el.parent === categoryId);
};

const changeActiveParamsGroupId = (categoryId: number) => {
  activeParamsGroupId.value = categoryId;
};

onMounted(() => {
  changeActiveParamsGroupId(props.paramsGroups[0].id);
});
</script>

<style lang="scss" scoped src="./UpdateParamsGroups.scss"></style>
