<template>
  <base-menu-with-tabs
    :activeComponent="activeComponentBtnId"
    :componentBtns="componentBtns.filter(el => !el.hide)"
    bgColor="#fff"
    withRadius
    @changeActiveBtn="changeActiveBtn($event)"
  >
    <template #content="contentProps">
      <div v-if="getActiveComponent(contentProps.data)" class="menuWithTabsContent">
        <component
          :is="getActiveComponent(contentProps.data)"
          :key="contentProps.data"
          v-bind="getCurrentProperties(contentProps.data)"
        />
      </div>
    </template>
  </base-menu-with-tabs>
</template>

<script lang="ts" setup>
import { BtnsForTabsMenuType } from '@/types';
import { Component, computed, markRaw, onMounted, ref, Ref, watch } from 'vue';
import { EditProfileInfo, ProfileInfo, ProfileView } from './index';
import { DocumentList } from '@/components';
import { useRoute, useRouter } from 'vue-router';

const getActiveComponent = (btnId: string | number) => {
  activeComponentBtnId.value = btnId;

  return componentBtns.value.find(el => el.id === btnId)?.component;
};

const getCurrentProperties = (btnId: string | number) => {
  if (btnId === 'documents') {
    return {
      documents: [
        {
          id: 1,
          name: 'Документы',
          path: '',
        },
      ],
    };
  }

  return {};
};

const componentBtns = computed((): BtnsForTabsMenuType[] => {
  return [
    {
      id: 'info',
      title: 'Профиль',
      component: markRaw(ProfileInfo),
      hide: activeComponentBtnId.value === 'editProfile',
    },
    {
      id: 'editProfile',
      title: 'Профиль',
      component: markRaw(EditProfileInfo),
      hide: activeComponentBtnId.value !== 'editProfile',
    },
    { id: 'documents', title: 'Документы', component: markRaw(DocumentList) },
    { id: 'view', title: 'Вид профиля', component: markRaw(ProfileView) },
  ];
});

const activeComponentBtnId: Ref<undefined | string | number> = ref(undefined);

const router = useRouter();
const changeActiveBtn = (btnId: string | number) => {
  activeComponentBtnId.value = btnId;
  router.push({ query: { menuItem: btnId } });
};

const route = useRoute();

const activeBtnId = computed(() => {
  return route?.query.menuItem;
});

const updateActiveBtn = () => {
  if (
    activeBtnId.value &&
    !!componentBtns.value.find(el => el.id === activeBtnId.value)
  ) {
    changeActiveBtn(`${activeBtnId.value}`);
  } else {
    changeActiveBtn(componentBtns.value[0].id);
  }
};

onMounted(() => {
  updateActiveBtn();
});

watch(
  () => activeBtnId.value,
  () => {
    updateActiveBtn();
  },
);
</script>

<style lang="scss" scoped src="./Profile.scss"></style>
