import { Directive } from 'vue';

export const clickOutside: Directive = {
  beforeMount(element, { value }) {
    const iframes = document.getElementsByTagName('iframe');
    const iframesArray = Array.prototype.slice.apply(iframes);

    //Если клик по iframe, то также срабатывает clickOutSide
    iframesArray.forEach(function (frame) {
      frame.contentWindow.document.addEventListener(
        'click',
        function (event: Event) {
          value(event);
        },
        true,
      );
    });

    // eslint-disable-next-line no-param-reassign
    element.clickOutSide = function (event: Event) {
      if (!(element === event.target || element.contains(event.target))) {
        value(event);
      }
    };
    document.body.addEventListener('click', element.clickOutSide);
  },
  beforeUnmount(element) {
    document.body.removeEventListener('click', element.clickOutSide);
  },
};
