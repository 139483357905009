<template>
  <MiniMessenger
    v-if="isMini"
    v-model="formData.messages[currentChannelId]"
    :allMessages="allMessages"
    :chatInfo="chatInfo[currentChannelId]"
    :isLoadingChatByHouseId="
      isLoadingChatByHouseId ||
      !!(currentChannelId && loadingChatIds.includes(currentChannelId))
    "
    :isSendMessageLoading="
      !!(currentChannelId && sendMessageChatsLoading.includes(currentChannelId))
    "
    :isShowAllMessagesLoading="isShowAllMessagesLoading"
    :mini-messenger-info="miniMessengerInfo"
    @getMessagesByChannelId="getMessagesByChannelId"
    @getMessagesByLandId="getMessagesByLandId"
    @sendMessage="sendMessage(currentChannelId)"
    @showAllChats="showAllChats"
  />
  <AllChats
    v-else
    v-model="formData"
    :allMessages="allMessages"
    :chatInfo="chatInfo"
    :isShowAllMessagesLoading="isShowAllMessagesLoading"
    :loadingChatIds="loadingChatIds"
    :sendMessageChatsLoading="sendMessageChatsLoading"
    @deleteChatById="deleteChatById"
    @getMessagesByChannelId="getMessagesByChannelId"
    @sendMessage="sendMessage"
    @showAllChats="showAllChats"
  />
</template>

<script lang="ts" setup>
import MiniMessenger from './MiniMessenger/MiniMessenger.vue';
import { AllChats } from '@/components/ProfileComponents';
import { useChatStore } from '@/store/modules/chat';
import { computed, Ref, ref } from 'vue';
import { useAppStore } from '@/store/modules/app';
import { FormDataType, MiniMessengerType } from '@/types/MessangerTypes';
import { MessagesFromChanelType } from '@/api/chat/types';
import { useRouter } from 'vue-router';

const props = withDefaults(
  defineProps<{
    isMini?: boolean;
  }>(),
  {
    isMini: false,
  },
);

const isShowAllMessagesLoading = ref(false);
const isLoadingChatByHouseId = ref(false);
const sendMessageChatsLoading: Ref<(number | 'newChat')[]> = ref([]);
const loadingChatIds: Ref<(number | 'newChat')[]> = ref([]);
const currentChannelId: Ref<number | undefined | null | 'newChat'> = ref(undefined);
const miniMessengerInfo: Ref<MiniMessengerType | undefined> = ref(undefined);
const router = useRouter();
const formData: Ref<FormDataType> = ref({
  messages: {},
});

const allMessages = computed((): MessagesFromChanelType[] => {
  return useChatStore().allChats;
});
const chatInfo = computed((): { [key: string]: MessagesFromChanelType } => {
  return useChatStore().chatInfo;
});
const showAllChats = async () => {
  isShowAllMessagesLoading.value = true;
  try {
    await useChatStore().getAllChannels();
  } finally {
    isShowAllMessagesLoading.value = false;
  }
};

const getMessagesByChannelId = async (channelId: number) => {
  loadingChatIds.value.push(channelId);
  try {
    await useChatStore().getChannelById(channelId);
  } catch {
    useChatStore().deleteOpenChatId(channelId);
  } finally {
    currentChannelId.value = channelId;
    const index = loadingChatIds.value.findIndex(el => el === channelId);

    if (index >= 0) {
      loadingChatIds.value.splice(index, 1);
    }
  }
};
const getMessagesByLandId = async (houseId: number) => {
  isLoadingChatByHouseId.value = true;

  try {
    currentChannelId.value = await useChatStore().findChannelByLandId(houseId);

    if (currentChannelId.value) {
      await getMessagesByChannelId(currentChannelId.value);
    } else {
      currentChannelId.value = 'newChat';
      miniMessengerInfo.value = useChatStore().miniMessenger;
    }
  } catch (error) {
    if (props.isMini) {
      useChatStore().closeMiniMessenger();
      useAppStore().openAlert('', error as string);
    }
  } finally {
    isLoadingChatByHouseId.value = false;
  }
};

const sendMessage = async (chatId: number | undefined | null | 'newChat') => {
  if (chatId && chatId !== 'newChat') {
    sendMessageChatsLoading.value.push(chatId);
    try {
      const res = await useChatStore().sendMessageInChannel(
        formData.value.messages[chatId],
        chatId,
      );

      if (res) {
        delete formData.value.messages[chatId];
      }
    } finally {
      const index = sendMessageChatsLoading.value.findIndex(el => el === chatId);

      if (index >= 0) {
        sendMessageChatsLoading.value.splice(index, 1);
      }
    }
  } else if (miniMessengerInfo.value && chatId && miniMessengerInfo.value.landId) {
    sendMessageChatsLoading.value.push(chatId);
    try {
      const res = await useChatStore().sendMessageByLand(
        formData.value.messages[chatId],
        miniMessengerInfo.value,
      );

      if (res) {
        currentChannelId.value = res.channelId;
        delete formData.value.messages[chatId];
      }
    } finally {
      const index = sendMessageChatsLoading.value.findIndex(el => el === chatId);

      if (index >= 0) {
        sendMessageChatsLoading.value.splice(index, 1);
      }
    }
  }
};

const deleteChatById = (chatId: number) => {
  if (currentChannelId.value === chatId) {
    currentChannelId.value = null;
    router.replace({ query: undefined });
  }
};
</script>

<style lang="scss" scoped></style>
