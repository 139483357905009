<template>
  <button class="info-btn" :style="cssProps">
    <span>?</span>
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    color?: string;
    size?: string;
  }>(),
  {
    size: '20',
  },
);

const cssProps = computed(() => {
  return {
    '--color': props.color,
    '--size': props.size + 'px',
    '--font-size': `${+props.size - 4}px`,
  };
});
</script>

<style scoped lang="scss" src="./BaseInfoBtn.scss"></style>
