import request from '@/axios';
import { ClassifierRequestType } from '@/api/classifier/types';

export const getClassifierByIdApi = (
  cardId: number,
): Promise<IResponse<ClassifierRequestType>> => {
  return request.post({
    url: process.env.VUE_APP_API_CLASSIFIER_CALCULATE,
    params: {
      id: cardId,
    },
  });
};
