<template>
  <base-card class="developer-project-card">
    <router-link :to="{ name: 'residentialComplex', query: { id: project.id } }">
      <base-image
        :src="project.logo"
        border-radius
        contain
        height="150"
        name="news"
        width="100%"
      />
    </router-link>
    <div class="developer-project-card__box">
      <div class="developer-project-card__title-box">
        <div>
          <base-tooltip :active="tooltipActive" :text="project.title">
            <div class="developer-project-card__title">
              {{ title }}
            </div>
          </base-tooltip>
        </div>
        <router-link
          :to="{ name: 'classification', query: { id: project.id } }"
          target="_blank"
        >
          <base-rating-number :rating="project.coefficient" />
        </router-link>
      </div>
      <div class="developer-project-card__content">
        <div class="developer-project-card__content-info">
          <div>
            <div class="third-color">Адрес:</div>
            <div>
              <div ref="addressEl" class="developer-project-card__content-address">
                {{ props.project.address }}
              </div>
              <button v-if="isOverflowed" class="main-color" @click="showAddress">
                Далее
              </button>
            </div>
          </div>
          <div>
            <div class="third-color">Застройщик:</div>
            <div>
              {{ project.developerTitle || '-' }}
            </div>
          </div>
          <div>
            <router-link
              :to="{
                name: 'residentialComplex',
                query: { id: project.id },
                hash: '#ads',
              }"
            >
              <div class="underline main-color">Витрина объектов</div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="developer-project-card__bottom-content none-Mobile mt--0 mb--0">
        <base-button
          :href="{ name: 'residentialComplex', query: { id: project.id } }"
          all-block-width
          style-type="coloredBorder"
          text="Подробнее"
        />
        <base-button
          :href="{
            name: 'residentialComplex',
            query: { id: project.id },
            hash: '#map',
          }"
          icon
          style-type="coloredBorder"
          withoutActive
        >
          <template #icon>
            <img :src="require('@/assets/img/mainPage/icons/icon-map.png')" alt="map" />
          </template>
        </base-button>
      </div>
    </div>
  </base-card>
  <base-modal
    :show-modal="addressModal"
    close-outside
    hide-title
    @close="addressModal = false"
  >
    <template #content>
      <div class="developer-project-card__modal">{{ project.address }}</div>
    </template>
  </base-modal>
</template>

<script lang="ts" setup>
import { SmallCardLSDType } from '@/types';
import { computed, ref } from 'vue';
import { useAppStore } from '@/store/modules/app';

const props = withDefaults(
  defineProps<{
    project: SmallCardLSDType;
  }>(),
  {},
);

const tooltipActive = ref(false);
const title = computed(() => {
  if (props.project.title) {
    let sliced: string = props.project.title.slice(
      0,
      useAppStore().getWindowWidth() > 600 ? 15 : 12,
    );

    if (sliced.length < props.project.title.length) {
      tooltipActive.value = true;
      sliced = sliced + '...';
    }

    return sliced;
  }

  return '-';
});

const addressModal = ref(false);
const addressEl = ref<HTMLElement | undefined>();
const isOverflowed = computed((): boolean => {
  if (addressEl.value) {
    return addressEl.value?.scrollHeight > addressEl.value.offsetHeight;
  }

  return false;
});
const showAddress = () => {
  addressModal.value = true;
};
</script>

<style lang="scss" scoped src="./ResidentialComplexCard.scss"></style>
