<template>
  <base-input
    v-maska
    :disabled="disabled"
    :height="height"
    :label="label"
    :labelInValue="labelInValue"
    :required="required"
    :styleType="styleType"
    :styles="styles"
    :v-model="inputValue"
    data-maska="(###)###-##-##"
    placeholder="+7 (999)000-99-99"
  />
</template>

<script lang="ts" setup>
import { StylesType } from '@/types';
import { vMaska } from 'maska';

const props = withDefaults(
  defineProps<{
    modelValue?: string | number | undefined;
    disabled?: boolean;
    label?: string;
    labelInValue?: boolean;
    height?: string;
    styles?: StylesType;
    styleType?: 'light';
    required?: boolean;
  }>(),
  {
    labelInValue: false,
    modelValue: undefined,
    height: '36',
    styleType: undefined,
  },
);
const emit = defineEmits(['update:modelValue', 'focus']);
// eslint-disable-next-line no-undef
const inputValue = defineModel<string | number | undefined>();
</script>

<style lang="scss" scoped></style>
