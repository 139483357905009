<template>
  <base-card
    v-if="useChatStore().miniMessenger.show"
    :class="{ isOpenBlock }"
    class="mini-messenger"
    full-width-mobile
  >
    <div :class="{ isOpenBlock }" class="mini-messenger__header">
      <div v-if="isShowAllMessages || !isOpenBlock" class="m-font-size medium">
        Сообщения
      </div>
      <div v-else class="items-in-line">
        <base-icon
          class="xl-scale"
          clickable
          font-size="28"
          name="arrow_back"
          @click="showAllMessages"
        />
        <div v-show="!isLoadingChatByHouseId" class="items-in-line">
          <span v-if="chatInfo">
            <base-image
              :src="chatInfo?.type === 10 ? chatInfo?.more?.house?.images[0] : undefined"
              border-radius
              cover
              height="36"
              name="img"
              width="50"
            />
          </span>
          <span v-else>
            <base-image
              :src="miniMessengerInfo?.more?.house?.images[0]"
              border-radius
              cover
              height="36"
              name="img"
              width="50"
            />
          </span>
          <div>
            <div class="medium mini-messenger__header-hidden">
              <span v-if="chatInfo">
                <span v-if="chatInfo.type === 10">
                  {{ chatInfo?.more.house.address }}</span
                >
                <span v-else-if="chatInfo.more.id !== useUserStore().user?.id">{{
                  chatInfo.more.name
                }}</span>
                <span v-else>Чат с администрацией</span>
              </span>
              <span v-else>
                {{ miniMessengerInfo?.more.house.address }}
              </span>
            </div>
            <div class="mini-messenger__header-info">
              <span v-if="chatInfo">
                <span v-if="chatInfo.type === 10">
                  {{ (+chatInfo?.more.house.price).toLocaleString() || '-' }} ₽</span
                >
                <span v-else-if="chatInfo.more.id !== useUserStore().user?.id">{{
                  chatInfo.more.id
                }}</span>
              </span>
              <span v-else>
                {{ miniMessengerInfo?.more.house.price.toLocaleString() || '-' }} ₽
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="items-in-line gap--5">
        <router-link
          :to="{ name: 'profile', params: { id: 'chat' } }"
          class="none-Mobile"
          target="_blank"
        >
          <base-icon class="xl-scale" font-size="28" name="open_in_new" />
        </router-link>
        <base-icon
          :class="{ transform: !isOpenBlock }"
          class="mini-messenger__header-icon"
          clickable
          font-size="32"
          name="expand_more"
          @click="useChatStore().toggleMiniMessenger(isOpenBlock)"
        />
        <base-icon
          :class="{ transform: !isOpenBlock }"
          class="mini-messenger__header-mobile-icon"
          clickable
          font-size="32"
          name="close"
          @click="useChatStore().closeMiniMessenger()"
        />
      </div>
    </div>
    <base-divider />
    <div v-if="isLoadingChatByHouseId" class="mini-messenger__main-container">
      <div class="mini-messenger__main-container-messages">
        <base-spinner :size="50" color="#009D3E" />
      </div>
    </div>
    <div v-else-if="isShowAllMessages" class="mini-messenger__dialogs">
      <div
        v-if="isShowAllMessagesLoading"
        class="center mini-messenger__main-container-messages"
      >
        <base-spinner :size="50" color="#009D3E" />
      </div>
      <button
        v-for="message in allMessages"
        v-else
        :key="message.id"
        class="mini-messenger__dialogs-item"
        @click="changeChat(message)"
      >
        <base-image
          :src="message.type === 10 ? message.more.house.images[0] : undefined"
          border-radius
          cover
          height="50"
          name="ava"
          width="50"
        />
        <span v-if="message.type === 10" class="mini-messenger__dialogs-info">
          <span class="medium items-in-line flex-start textHidden">{{
            message.more.house.address
          }}</span>
          <span class="items-in-line flex-start"
            >{{ (+message.more.house.price).toLocaleString() }} ₽</span
          >
        </span>
        <span v-else-if="message.more.id !== useUserStore().user?.id">
          <span class="medium items-in-line flex-start">{{ message.more.name }}</span>
          <span class="items-in-line flex-start">id: {{ +message.more.id }}</span>
        </span>
        <span v-else>
          <span class="medium items-in-line flex-start">Чат с администрацией</span>
        </span>
        <span class="mini-messenger__dialogs-date">{{
          new Date(message.created_at).toLocaleString()
        }}</span>
      </button>
    </div>
    <div v-else class="mini-messenger__main-container">
      <ChatComponent
        v-if="chatInfo?.messages?.length"
        :messages="chatInfo.messages"
        :withAva="false"
        class="mini-messenger__main-container-chat"
      />
      <div v-else class="mini-messenger__main-container-messages">
        <base-icon color="#CBC8D9" font-size="50" name="chat" />
      </div>
      <base-form v-if="isOpenBlock" class="mini-messenger__main-container-dialog-footer">
        <base-textarea
          v-model="message"
          :disabled="isLoadingChatByHouseId"
          :styleType="'light'"
          label="Написать сообщение"
          max-height="150"
          withScroll
        />
        <base-icon
          :color="message?.length ? '#969696' : 'rgba(150,150,150,0.5)'"
          :disabled="message?.length <= 0"
          class="xl-scale"
          clickable
          font-size="28"
          name="send"
          @click="sendMessage"
        />
      </base-form>
    </div>
  </base-card>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, ref, watch } from 'vue';
import { useAppStore } from '@/store/modules/app';
import { MessagesFromChanelType } from '@/api/chat/types';
import { ChatComponent } from '@/components';
import { MiniMessengerType } from '@/types/MessangerTypes';
import { useChatStore } from '@/store/modules/chat';
import { useUserStore } from '@/store/modules/user';

const isOpenBlock = computed((): boolean => {
  return !useChatStore().miniMessenger.isHidden;
});
const cardId = computed((): number | undefined => {
  return useChatStore().miniMessenger.landId;
});
const isShowAllMessages = ref(false);

const props = withDefaults(
  defineProps<{
    isShowAllMessagesLoading: boolean;
    isLoadingChatByHouseId: boolean;
    isSendMessageLoading: boolean;
    allMessages: MessagesFromChanelType[];
    chatInfo: MessagesFromChanelType | undefined;
    miniMessengerInfo?: MiniMessengerType | undefined;
  }>(),
  {},
);

const emit = defineEmits([
  'showAllChats',
  'getMessagesByLandId',
  'getMessagesByChannelId',
  'sendMessage',
]);

// eslint-disable-next-line no-undef
const message = defineModel<string | undefined>();
const changeChat = (chat: MessagesFromChanelType) => {
  emit('getMessagesByChannelId', chat.id);
  isShowAllMessages.value = !isShowAllMessages.value;
};
const sendMessage = async () => {
  emit('sendMessage');
};

const showAllMessages = async () => {
  isShowAllMessages.value = true;
  emit('showAllChats');
};

watch(isOpenBlock, isOpen => {
  if (useAppStore().getWindowWidth() <= 600) {
    document.body.classList[isOpen ? 'add' : 'remove']('overflow-hidden');
  }
});

watch(cardId, () => {
  if (cardId.value) {
    isShowAllMessages.value = false;
    emit('getMessagesByLandId', cardId.value);
  }
});
onBeforeUnmount(() => {
  if (useAppStore().getWindowWidth() <= 600) {
    document.body.classList.remove('overflow-hidden');
  }
});
</script>

<style lang="scss" scoped src="./MiniMessenger.scss"></style>
