import { defineStore } from 'pinia';
import { store } from '@/store';
import {
  createCompareApi,
  deleteCompareApi,
  deleteHouseFromCompareApi,
  getComparisonByIdApi,
  getComparisonsApi,
  updateCompareApi,
} from '@/api/compare';
import { CreateCompareDataType, UpdateCompareDataType } from '@/api/compare/types';
import { CompareType, ResultCardType } from '@/types';
import { prepareOfferCard } from '@/mixins/Cards/OfferCard';
import { useUserStore } from '@/store/modules/user';
import { useAppStore } from '@/store/modules/app';

type CompareStoreType = {
  compareList: ResultCardType[];
  compareData: CompareType | undefined;
};

export const defaultCompareStore: CompareStoreType = {
  compareList: [],
  compareData: undefined,
};
export const useCompareStore = defineStore('compare', {
  state: (): CompareStoreType => {
    return { ...defaultCompareStore };
  },
  actions: {
    async createCompare(data: CreateCompareDataType) {
      const res = await createCompareApi(data);

      if (res) {
        useUserStore().addComparisons();

        return res.data;
      }
    },
    async getComparisons() {
      const res = await getComparisonsApi();

      if (res) {
        return res.data.comparisons;
      }
    },
    async getComparisonById(comparisonId: number) {
      const res = await getComparisonByIdApi(comparisonId);

      if (res) {
        return {
          ...res.data,
          found: res.data.found
            ? res.data.found.map(el => {
                return prepareOfferCard(el);
              })
            : [],
        };
      }
    },
    async deleteHouseFromCompare(compareId: number, cardId: number) {
      return await deleteHouseFromCompareApi({
        id: compareId,
        value: cardId,
      });
    },
    async deleteCompare(compareId: number) {
      const res = await deleteCompareApi(compareId);

      if (res) {
        useUserStore().deleteComparisons();

        return res.data;
      }
    },
    async updateCompare(data: UpdateCompareDataType) {
      return await updateCompareApi(data);
    },
    async changeIsCompare(card: ResultCardType) {
      const promise: Promise<'showInfo' | 'success'> = new Promise(resolve => {
        const compareIndex = this.compareList.findIndex(el => el.id === card.id);

        if (compareIndex !== undefined && compareIndex >= 0) {
          this.compareList?.splice(compareIndex, 1);
          resolve('success');

          if (this.compareData) {
            this.deleteHouseFromCompare(this.compareData.id, card.id);
          }
        } else {
          useAppStore().sendStatistic(13);
          this.compareList.push(card);
          if (this.compareData) {
            this.updateCompare({
              id: this.compareData.id,
              values: this.compareList.map(el => el.id),
            });
          }

          resolve('showInfo');
        }
      });

      localStorage.setItem('compare-list', JSON.stringify(this.compareList));

      return promise;
    },
    getCompareFromLocalStorage() {
      const stringCompareList = localStorage.getItem('compare-list');
      const stringCompareData = localStorage.getItem('compare-data');

      if (stringCompareList) {
        this.compareList = JSON.parse(stringCompareList);
      } else {
        this.compareList = [];
      }

      if (stringCompareData) {
        this.compareData = JSON.parse(stringCompareData);
      } else {
        this.compareData = undefined;
      }
    },
    changeCurrentCompare(
      compareData: CompareType | undefined,
      compareList: ResultCardType[],
    ) {
      this.compareData = compareData;
      this.compareList = compareList;

      localStorage.setItem('compare-list', JSON.stringify(this.compareList));

      if (compareData) {
        localStorage.setItem('compare-data', JSON.stringify(this.compareData));
      } else {
        localStorage.removeItem('compare-data');
      }
    },
  },
  getters: {
    getIsCompare:
      state =>
      (cardId: number): boolean => {
        return !!state.compareList.find(el => el.id === cardId);
      },
    getCompareCount: state => (): number => {
      return state.compareList.length;
    },
  },
});

export const useClassificationStoreWithOut = () => {
  return useCompareStore(store);
};
