<template>
  <div class="active">
    <div v-if="type === 'active'" class="items-in-line flex-start gap--30 mb--20">
      <button
        class="gradient-color"
        @click="showCreateCategoryModal({ categoryTitle: 'Объявление' })"
      >
        Создать объявление
      </button>
    </div>

    <div v-if="isLoading" class="active__cards">
      <base-skeleton-loader
        v-for="i in 4"
        :key="i"
        :styles="{ height: '126px', width: '100%' }"
        class="mb--5"
        type="mediumText"
      />
    </div>
    <div v-else-if="resultCards && resultCards.length" class="active__cards">
      <div v-for="card in resultCards" :key="card.id">
        <AnnouncementCard :card="card" :type="type" @deleteCategory="deleteCategory" />
      </div>
    </div>
    <div v-else class="center">Объявлений нет</div>
    <CreateDeveloperModal
      :create-category-modal-data="createCategoryModalData"
      isOfferCard
      @close="closeCreateCategoryModal"
      @create-category="createCategory($event)"
    />
  </div>
</template>

<script lang="ts" setup>
import AnnouncementCard from '@/components/ProfileComponents/MyAnnouncements/AnnouncementCard/AnnouncementCard.vue';
import { computed, onMounted, Ref, ref, watch } from 'vue';
import { useDevelopersStore } from '@/store/modules/developers';
import { SmallOfferCardType } from '@/types/MainPageTypes';
import { CreateCategoryModalType, TypesMyAdsType } from '@/types';
import { CreateDeveloperModal } from '@/components/ProfileComponents';
import { useRoute, useRouter } from 'vue-router';

const props = withDefaults(
  defineProps<{
    type: TypesMyAdsType;
  }>(),
  {},
);

const route = useRoute();
const router = useRouter();

const resultCards = computed((): SmallOfferCardType[] | undefined => {
  if (props.type === 'active') {
    return useDevelopersStore().orderList;
  } else if (props.type === 'drafts') {
    return useDevelopersStore().draftAdList;
  } else if (props.type === 'archive') {
    return useDevelopersStore().archiveAdList;
  }

  return [];
});
const createCategoryModalData: Ref<CreateCategoryModalType> = ref({
  show: false,
  categoryTitle: undefined,
});
const showCreateCategoryModal = (event: { categoryTitle: string }) => {
  router.push({ hash: '#create' });
  createCategoryModalData.value = {
    show: true,
    categoryTitle: event.categoryTitle,
  };
};
const closeCreateCategoryModal = () => {
  router.push({ hash: '' });
  createCategoryModalData.value = {
    show: false,
    categoryTitle: undefined,
  };
};

const createCategory = (event: { id: number; title: string }) => {
  useDevelopersStore().createAd(event.id);
  router.push({ query: { menuItem: 'drafts' } });
};

const deleteCategory = async (id: number) => {
  useDevelopersStore().deleteAd(props.type, id);
};

const isLoading = ref(false);
const getAnnouncements = async () => {
  try {
    isLoading.value = true;

    if (!resultCards.value) {
      if (props.type === 'active') {
        await useDevelopersStore().getOrderList();
      } else if (props.type === 'drafts') {
        await useDevelopersStore().getDrafts();
      } else if (props.type === 'archive') {
        await useDevelopersStore().getArchive();
      }
    }
  } finally {
    isLoading.value = false;
  }
};

const hash = computed(() => {
  return route?.hash;
});

const checkIsCreate = () => {
  if (hash.value) {
    const isCreate = hash.value === '#create';

    if (isCreate) {
      showCreateCategoryModal({ categoryTitle: 'Объявление' });
    }
  }
};

onMounted(async () => {
  checkIsCreate();
  await getAnnouncements();
});
watch(
  () => hash.value,
  () => {
    checkIsCreate();
  },
);
watch(
  () => props.type,
  () => {
    getAnnouncements();
  },
);
</script>

<style lang="scss" scoped src="./AnnouncementList.scss"></style>
