<template>
  <div v-for="swiper in Object.keys(groupData)" :key="swiper">
    <swiper
      v-show="activeSwiper === swiper"
      id="top-swiper"
      :modules="groupData[swiper].modules"
      :navigation="navigation"
      :space-between="0"
      :thumbs="{ swiper: groupData[swiper].thumbsSwiper }"
      :zoom="true"
      class="top-swiper"
    >
      <swiper-slide
        v-for="(item, index) in groupData[swiper].slides"
        :key="index"
        class="slide"
      >
        <div class="top-swiper__item">
          <slot
            :data="{ item: item, slides: groupData[swiper].slides }"
            name="item"
          ></slot>
        </div>
      </swiper-slide>
    </swiper>
  </div>
  <div
    :style="{
      display: 'grid',
      gridTemplateColumns: gridTemplateColumns,
      gap: '10px',
    }"
  >
    <div v-for="swiper in Object.keys(groupData)" :key="swiper">
      <div v-if="groupData[swiper]?.slides.length <= 1">
        <base-image
          :max-width="widthItem + 'px'"
          :src="groupData[swiper]?.slides[0]"
          border-radius
          cover
          height="70"
          name="img"
          width="100%"
          @click="changeActiveSwiper(swiper)"
        />
      </div>
      <GallerySwiper
        v-else
        :group-data="groupData"
        :modules="groupData[swiper]?.modules"
        :slides="groupData[swiper]?.slides"
        :swiper="swiper"
        @click="changeActiveSwiper(swiper)"
        @swiper="setThumbsSwiper(swiper, $event)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Thumbs } from 'swiper/modules';
import type SwiperClass from 'swiper';
import { computed, onMounted, Ref, ref } from 'vue';
// eslint-disable-next-line import/no-unresolved
import { SwiperModule } from 'swiper/types/shared';
import GallerySwiper from '@/components/UI/BaseSwiper/GallerySwiper.vue';

type SlidesGroupType = {
  id: number;
  title: string;
  slides: string[];
}[];
type GroupDataType = {
  modules: SwiperModule[];
  slides: string[];
  thumbsSwiper?: SwiperClass;
};
const activeSwiper = ref<string>('');
const modules = [Pagination, Navigation, Thumbs];
const props = withDefaults(
  defineProps<{
    slidesGroup: SlidesGroupType;
    widthItem?: number;
    itemsToShow?: number;
    spaceBetween?: number;
    navigation?: boolean;
  }>(),
  {
    widthItem: 120,
    spaceBetween: 5,
    navigation: false,
  },
);

const groupData: Ref<{ [key: string]: GroupDataType }> = ref({});
const setThumbsSwiper = (index: string, swiper: SwiperClass) => {
  groupData.value[index].thumbsSwiper = swiper;
};
const changeActiveSwiper = (swiper: string) => {
  activeSwiper.value = swiper;
};
const gridTemplateColumns = computed(() => {
  const length = Object.keys(groupData.value)?.length;

  let columns: string[] = [];

  Object.keys(groupData.value).forEach(swiper => {
    if (groupData.value[swiper]?.slides?.length <= 1) {
      columns.push(`${props.widthItem}px`);
    } else {
      columns.push(`minmax(${100 / length}%, 100%)`);
    }
  });

  return columns.join(' ');
});

onMounted(() => {
  props.slidesGroup.forEach(group => {
    groupData.value[group.id] = {
      modules: [...modules],
      slides: [...group.slides],
    };
  });
  activeSwiper.value = String(props.slidesGroup[0].id);
});
</script>

<style lang="scss" scoped src="./BaseSwiper.scss"></style>
