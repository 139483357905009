<template>
  <LoginModal
    :hideScroll="false"
    :show-modal="activeModal === 'login'"
    @close="closeModal()"
    @show-password-recovery-modal="changeModalType('passwordRecovery')"
    @show-registration-modal="changeModalType('registration')"
  />
  <RegistrationModal
    :hideScroll="false"
    :show-modal="activeModal === 'registration'"
    @close="closeModal()"
    @successRegistration="changeModalType('successRegistration')"
    @show-login-modal="changeModalType('login')"
  />

  <base-modal
    :hide-title="!modalTitles[activeModal]?.length"
    :hideScroll="false"
    :show-modal="
      activeModal === 'passwordRecovery' ||
      activeModal === 'successRegistration' ||
      activeModal === 'successRegistration' ||
      activeModal === 'successPasswordRestore' ||
      activeModal === 'successPasswordRecovery'
    "
    :title="modalTitles[activeModal]"
    @close="closeModal()"
  >
    <template #content>
      <PasswordRecovery
        v-if="activeModal === 'passwordRecovery'"
        @close="changeModalType('login')"
        @showSuccessMessage="changeModalType('successPasswordRecovery')"
      />
      <div v-else-if="activeModal === 'successPasswordRestore'" class="auth-modal">
        <div>
          <div class="align-center l-font-size">Пароль успешно изменен</div>
        </div>
        <div>
          <base-button
            all-block-width
            height="40"
            text="Войти"
            @click="changeModalType('login')"
          />
        </div>
      </div>
      <div v-else-if="activeModal === 'successRegistration'" class="auth-modal">
        <div>
          <div class="align-center l-font-size">
            Вы успешно зарегистрировались, логин и пароль отправлен вам на почту
          </div>
        </div>
        <div>
          <base-button
            all-block-width
            height="40"
            text="Войти"
            @click="changeModalType('login')"
          />
        </div>
      </div>
      <div v-else-if="activeModal === 'successPasswordRecovery'" class="auth-modal">
        <div class="align-center l-font-size">
          Инструкция по восстановлению пароля отправлена вам на почту
        </div>
      </div>
    </template>
  </base-modal>
  <base-modal
    :show-modal="showPassRestoreModal"
    hide-title
    @close="$router.push({ name: 'main' })"
  >
    <template #content>
      <PasswordRestore @close="gotoLogin" />
    </template>
  </base-modal>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, Ref } from 'vue';
import { useUserStore } from '@/store/modules/user';
import { useRoute, useRouter } from 'vue-router';
import LoginModal from '@/components/AuthComponents/LoginModal.vue';
import PasswordRecovery from '@/components/AuthComponents/PasswordRecovery.vue';
import PasswordRestore from '@/components/AuthComponents/PasswordRestore.vue';
import RegistrationModal from '@/components/AuthComponents/RegistrationModal.vue';
import { useAppStore } from '@/store/modules/app';

type modalsTypes =
  | 'login'
  | 'registration'
  | 'passwordRecovery'
  | 'successPasswordRecovery'
  | 'successRegistration'
  | 'successPasswordRestore';
type ModalTitlesType = {
  [key in modalsTypes]?: string;
};
const activeModal: Ref<modalsTypes | undefined> = ref(undefined);
const modalTitles: ModalTitlesType = {
  login: 'Войти',
  registration: 'Регистрация на БЕТА версию',
  passwordRecovery: 'Восстановление пароля',
};

const router = useRouter();

const showPassRestoreModal = computed((): boolean => {
  return !useUserStore().getIsLogin() && useRoute()?.name === 'passwordRestore';
});

const closeModal = () => {
  useAppStore().closeAuthModal();
};

const changeModalType = (type: modalsTypes) => {
  activeModal.value = type;
};

const gotoLogin = () => {
  useAppStore().openAuthModal();
  activeModal.value = 'login';
  router.push({ name: 'main' });
};

onMounted(() => {
  if (useRoute()?.name !== 'passwordRestore') {
    activeModal.value = 'login';
  }
  document.body.classList.add('overflow-hidden');
});
onUnmounted(() => {
  document.body.classList.remove('overflow-hidden');
});
</script>

<style lang="scss" scoped src="./AuthModal.scss"></style>
