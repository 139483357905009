import request from '@/axios';
import { NotificationsTypeFromBack } from '@/api/notification/types';

export const getNotificationsApi = (): Promise<IResponse<NotificationsTypeFromBack>> => {
  return request.get({
    url: process.env.VUE_APP_API_NOTIFICATION,
  });
};

export const closeNotificationByIdApi = (data: {
  id: number;
}): Promise<IResponse<{ message: string }>> => {
  return request.post({
    url: process.env.VUE_APP_API_NOTIFICATION_CLOSE,
    data,
  });
};

export const getCountNotificationsApi = (): Promise<
  IResponse<{ open: number; all: number }>
> => {
  return request.get({
    url: process.env.VUE_APP_API_NOTIFICATION_COUNT,
  });
};
