<template>
  <div
    :class="[
      {
        borderRadius,
      },
    ]"
    :style="[
      {
        width: width === '100%' ? width : width + 'px',
        height: height + 'px',
      },
    ]"
    class="brazzers"
  >
    <base-image
      :borderRadius="borderRadius"
      :height="height"
      :src="coverSrc"
      :width="width"
      class="brazzers__cover"
      cover
      name="image"
    />
    <div
      v-if="preparedPhotos.length > 1"
      class="brazzers__button-container"
      @mouseleave="mouseLeave"
    >
      <div
        v-for="(photo, index) in preparedPhotos"
        :key="index"
        class="brazzers__button"
        @mouseover="mouseOver(photo)"
      ></div>
    </div>
    <div
      v-if="morePhotoInfo && coverSrc === preparedPhotos[preparedPhotos.length - 1]"
      class="brazzers__info"
    >
      {{ morePhotoInfo }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, Ref, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    maxSliders?: number;
    photos: string[];
    borderRadius: boolean;
    width?: string;
    height?: string;
  }>(),
  {
    maxSliders: 5,
  },
);

const preparedPhotos = computed(() => {
  if (!props.photos) {
    return [];
  }
  if (props.photos.length > props.maxSliders) {
    morePhotoInfo.value = `Ещё ${props.photos.length - props.maxSliders} фото`;

    return props.photos.slice(0, props.maxSliders);
  } else {
    return [...props.photos];
  }
});
const morePhotoInfo: Ref<string | null> = ref(null);

const forceCoverPhoto: Ref<string | null> = ref(null);

const coverSrc = computed(() => {
  if (forceCoverPhoto.value) {
    return forceCoverPhoto.value;
  }
  if (preparedPhotos.value.length > 0) {
    return preparedPhotos.value[0];
  }

  return require('@/assets/img/common/not-image.jpg');
});

const mouseOver = (photo: string) => {
  forceCoverPhoto.value = photo;
};
const mouseLeave = () => {
  forceCoverPhoto.value = null;
};
</script>

<style lang="scss" scoped src="./BaseBrazzersCarousel.scss"></style>
