<template>
  <base-form>
    <div class="update-developer-form mb--20">
      <base-input
        v-model="paramsData['value'].value"
        :error="errors['value']"
        :invalid="!!errors['value']"
        height="36"
        hideArrows
        label="Название категории"
        type="text"
        @blur="$emit('validationErrorsHandlerByKey', 'value')"
        @focus="$emit('focus', 'value')"
        @input="v$['value']?.$touch"
      />
      <base-checkbox-input
        v-model="paramsData['public'].value"
        height="36"
        label="Публиковать"
        @blur="$emit('validationErrorsHandlerByKey', 'public')"
        @update:modelValue="v$['public']?.$touch"
      />
      <base-checkbox-input
        v-if="paramsData['is_missed'] !== undefined"
        v-model="paramsData['is_missed'].value"
        height="36"
        label="Пропустить"
        @blur="$emit('validationErrorsHandlerByKey', 'is_missed')"
        @update:modelValue="v$['is_missed']?.$touch"
      />
      <base-button
        :loading="isSaveLoading"
        all-block-width
        height="40"
        text="Сохранить"
        @click="$emit('save')"
      />
    </div>
  </base-form>
</template>

<script lang="ts" setup>
import { ErrorsType, ParamsDataType } from '@/types';

const props = defineProps<{
  errors: ErrorsType;
  isSaveLoading: boolean;
}>();

const emit = defineEmits(['validationErrorsHandlerByKey', 'inputTouch', 'focus', 'save']);
// eslint-disable-next-line no-undef
const paramsData = defineModel<ParamsDataType>('paramsData');
// eslint-disable-next-line no-undef
const v$ = defineModel<ParamsDataType>('v$');
</script>

<style lang="scss" scoped src="./UpdateForm.scss"></style>
