<template>
  <div class="header">
    <div class="container header__container">
      <div class="header__container-logoBlock">
        <div class="relative">
          <router-link :to="{ name: 'main' }">
            <base-image
              :src="require('@/assets/img/header/logo.svg')"
              class="header__container-logoBlock-logo"
              contain
              height="45"
              name="logo"
              width="204"
            />
            <div class="header__logo-mobile" />
          </router-link>
        </div>
        <base-select-input
          :modelValue="currentRegion"
          :options="regionsOptions"
          arrow="expand_more"
          class="header__container-logoBlock-region"
          height="25"
          type="text"
          @update:modelValue="updateRegion($event)"
        />
      </div>

      <div class="header__container-actionsBlock">
        <div class="items-in-line gap--9">
          <base-button
            v-for="icon in icons"
            :key="icon.id"
            :href="icon.href"
            icon
            style-type="border"
            @click="icon.clickHandler"
          >
            <template #icon>
              <img :alt="icon.id" :src="icon.icon" />
            </template>
            <template #iconActive>
              <img :alt="icon.id" :src="icon.activeIcon" />
            </template>
          </base-button>
        </div>
        <div class="items-in-line gap--9">
          <div class="header__container-actionsBlock-mobileMenu">
            <MenuComponent />
          </div>
          <a
            v-if="useUserStore().user?.access.includes('back')"
            :href="backLink"
            class="header__container-admin-btn center pointer"
            target="_blank"
            >Back</a
          >
          <base-button
            class="no-wrap"
            style-type="coloredBorder"
            text="Объявление +"
            @click="
              checkUserLoginHandler({
                name: 'profile',
                params: { id: 'myAnnouncements' },
                hash: '#create',
              })
            "
          />
          <router-link v-if="useUserStore().user" :to="{ name: 'profile' }">
            <base-button text="Профиль" />
          </router-link>
          <base-button v-else text="Войти" @click="useAppStore().openAuthModal" />
        </div>
      </div>
    </div>
    <div class="header__menu">
      <MenuComponent />
    </div>
    <base-divider class="header__mobile-divider" />
  </div>
</template>

<script lang="ts" setup>
import MenuComponent from '@/components/Menu/Menu.vue';
import { useUserStore } from '@/store/modules/user';
import { type RouteLocationRaw, useRouter } from 'vue-router';
import { computed } from 'vue';
import { useAppStore } from '@/store/modules/app';

type IconType = {
  id: string;
  icon: string;
  activeIcon: string;
  href?: RouteLocationRaw;
  clickHandler?: () => void;
};

const backLink = computed(() => {
  const url =
    process.env.VUE_APP_IS_DEV === 'true'
      ? 'http://localhost:13010'
      : useAppStore().baseUrl;

  return url + `/back`;
});
const router = useRouter();
const checkUserLoginHandler = (href: {
  name: string;
  params?: { id: string };
  hash?: string;
}): void => {
  if (useUserStore().getIsLogin()) {
    router.push(href);
  } else {
    useAppStore().openAuthModal(true);
  }
};

const icons: IconType[] = [
  {
    id: 'search',
    icon: require('@/assets/img/header/icons/icon-search.png'),
    activeIcon: require('@/assets/img/header/icons/icon-search-active.png'),
    href: { name: 'main' },
  },
  {
    id: 'compare',
    icon: require('@/assets/img/header/icons/icon-menu.png'),
    activeIcon: require('@/assets/img/header/icons/icon-menu-active.png'),
    href: { name: 'compare' },
  },
  {
    id: 'like',
    icon: require('@/assets/img/header/icons/icon-like.png'),
    activeIcon: require('@/assets/img/header/icons/icon-like-active.png'),
    clickHandler: () =>
      checkUserLoginHandler({ name: 'profile', params: { id: 'favorite' } }),
  },
  {
    id: 'message',
    icon: require('@/assets/img/header/icons/icon-message.png'),
    activeIcon: require('@/assets/img/header/icons/icon-message-active.png'),
    clickHandler: () =>
      checkUserLoginHandler({ name: 'profile', params: { id: 'chat' } }),
  },
  {
    id: 'bell',
    icon: require('@/assets/img/header/icons/icon-bell.png'),
    activeIcon: require('@/assets/img/header/icons/icon-bell-active.png'),
    clickHandler: () =>
      checkUserLoginHandler({ name: 'profile', params: { id: 'notifications' } }),
  },
];

const currentRegion = computed((): number | undefined => {
  return useAppStore().regions_for_select.defaultForUser;
});

const updateRegion = (region: number) => {
  useAppStore().updateRegion(region);
};
const regionsOptions = computed(() => {
  return useAppStore().regions_for_select.regions;
});
</script>

<style lang="scss" scoped src="./Header.scss"></style>
