<template>
  <base-button
    v-if="withBorder"
    icon
    style-type="border"
    withoutActive
    @click="changeIsCompare"
  >
    <template #icon>
      <base-icon
        :color="useCompareStore().getIsCompare(card.id) ? '#009D3E' : '#969696'"
        :name="
          useCompareStore().getIsCompare(card.id) ? 'playlist_add_check' : 'playlist_add'
        "
        font-size="20"
        width="22"
      />
    </template>
  </base-button>
  <base-icon
    v-else
    :color="useCompareStore().getIsCompare(card.id) ? '#009D3E' : '#969696'"
    :name="
      useCompareStore().getIsCompare(card.id) ? 'playlist_add_check' : 'playlist_add'
    "
    class="ml--5"
    clickable
    font-size="24"
    @click.prevent="changeIsCompare"
  />
  <base-info-modal
    :key="showInfoModalKey"
    :show-info-modal="showInfoModal"
    position="top-right"
    @close="showInfoModal = false"
  >
    <div class="items-in-column">
      <div>Добавлено к сравнению</div>
      <router-link :to="{ name: 'compare' }" class="underline"
        >Перейти к сравнению
      </router-link>
    </div>
  </base-info-modal>
</template>

<script lang="ts" setup>
import { computed, ref, Ref } from 'vue';
import { ResultCardType } from '@/types';
import { useCompareStore } from '@/store/modules/compare';

const props = defineProps<{
  card: ResultCardType;
  withBorder?: boolean;
}>();

const showInfoModalKey: Ref<number> = ref(1);
const showInfoModal: Ref<boolean> = ref(false);

const compareId = computed(() => {
  return useCompareStore().compareData?.id;
});
const changeIsCompare = async () => {
  if (
    useCompareStore().getCompareCount() === 1 &&
    useCompareStore().getIsCompare(props.card.id) &&
    compareId.value
  ) {
    await useCompareStore()
      .deleteCompare(compareId.value)
      .then(() => {
        useCompareStore().changeCurrentCompare(undefined, []);
      });
  } else {
    const res = await useCompareStore().changeIsCompare(props.card);

    if (res === 'showInfo') {
      showInfoModalKey.value++;
      showInfoModal.value = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
