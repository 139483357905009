<template>
  <base-menu-with-tabs
    :activeComponent="activeComponentBtnId"
    :componentBtns="componentBtns"
    bgColor="#fff"
    withRadius
    @changeActiveBtn="changeActiveBtn($event)"
  >
    <template #content="contentProps">
      <div v-if="getActiveComponent(contentProps.data)" class="menuWithTabsContent">
        <component :is="getActiveComponent(contentProps.data)" :key="contentProps.data" />
      </div>
    </template>
  </base-menu-with-tabs>
</template>

<script lang="ts" setup>
import { BtnsForTabsMenuType } from '@/types';
import type { Component } from 'vue';
import { markRaw, onMounted, ref, Ref } from 'vue';
import { AnalyticalChart } from './index';

const componentBtns: BtnsForTabsMenuType[] = [
  { id: 1, title: 'Аналитика', component: markRaw(AnalyticalChart) },
  { id: 2, title: 'Ос', component: markRaw(AnalyticalChart) },
  { id: 3, title: 'Активные', component: markRaw(AnalyticalChart) },
  { id: 4, title: 'Черновики', component: markRaw(AnalyticalChart) },
  { id: 5, title: 'Архив', component: markRaw(AnalyticalChart) },
];

const getActiveComponent = (btnId: string | number) => {
  return componentBtns.find(el => el.id === btnId)?.component;
};

const activeComponentBtnId: Ref<undefined | number | string> = ref(undefined);

const changeActiveBtn = (btnId: number | string) => {
  activeComponentBtnId.value = btnId;
};

onMounted(() => {
  changeActiveBtn(componentBtns[0].id);
});
</script>

<style lang="scss" scoped src="./Analytics.scss"></style>
