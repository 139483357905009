<template>
  <div v-if="isLoading" class="ads-items favorite">
    <base-skeleton-loader
      v-for="i in favoriteAdsCount"
      :key="i"
      :styles="{ height: '94px', width: '100%' }"
      class="mb--5"
      type="mediumText"
    />
  </div>
  <div
    v-else-if="!favoriteAds || favoriteAds.length === 0"
    class="items-in-column align-center"
  >
    <div>Добавьте объявление в избранное</div>
    <base-button :href="{ name: 'main' }" height="40" text="К объявлениям" />
  </div>
  <div v-else class="ads-items favorite">
    <base-card
      v-for="favoriteAd in favoriteAds"
      :key="favoriteAd"
      class="ads-items__section"
      full-width-mobile
    >
      <div class="ads-items__container">
        <router-link :to="{ name: 'offerCard', params: { id: favoriteAd.id } }">
          <base-image
            :src="favoriteAd.img ? favoriteAd.img[0] : undefined"
            border-radius
            cover
            height="60"
            name="img"
            width="96"
          />
        </router-link>
        <div class="ads-items__container-info">
          <div><span class="medium">Адрес:</span> {{ favoriteAd.address }}</div>
          <div>
            <div>
              <span class="medium">Цена: </span>{{ favoriteAd.price.toLocaleString() }} ₽
            </div>
            <div><span class="medium">id: </span>{{ favoriteAd.id }}</div>
          </div>
          <div class="ads-items__container-top-right">
            <base-icon
              clickable
              color="#EA1F1F"
              font-size="20"
              name="favorite"
              width="22"
              @click="deleteFavorite(favoriteAd.landId)"
            />
          </div>
        </div>
      </div>
    </base-card>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, Ref } from 'vue';
import { useUserStore } from '@/store/modules/user';
import { ResultCardType } from '@/types';

const favoriteAds: Ref<ResultCardType[] | undefined> = ref(undefined);

const isLoading = ref(false);

const deleteFavorite = async (id: number) => {
  await useUserStore().changeIsFavorite(id);
  const favoriteIndex = favoriteAds.value?.findIndex(el => el.landId === id);

  if (favoriteIndex !== undefined && favoriteIndex >= 0) {
    favoriteAds.value?.splice(favoriteIndex, 1);
  }
};
const favoriteAdsCount = computed(() => {
  return useUserStore().user?.favorite_ids.length;
});

onMounted(async () => {
  try {
    isLoading.value = true;
    favoriteAds.value = await useUserStore().getFavoriteHouses();
  } finally {
    isLoading.value = false;
  }
});
</script>

<style lang="scss" scoped src="./AdsItems.scss"></style>
