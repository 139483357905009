import {
  FilterBtnsType,
  InputsType,
  ResultCardType,
  SearchParamsDataType,
  SendComplainDataType,
} from '@/types';
import { defineStore } from 'pinia';
import { store } from '@/store';
import {
  getHouseCostHistoryApi,
  houseSimilarApi,
  houseTopApi,
  searchHouseApi,
  searchHouseByIdApi,
  searchHouseCoordinatesApi,
} from '@/api/house';
import {
  OfferCardCoordinatesType,
  SearchHouseCoordinatesDataType,
  SearchHouseDataType,
} from '@/api/house/types';
import { useSearchRealEstateStore } from '@/store/modules/searchRealEstate';
import { useAppStore } from '@/store/modules/app';
import { prepareOfferCard } from '@/mixins/Cards/OfferCard';
import { sendComplainApi } from '@/api/complain';
import qs from 'qs';

type OfferCardsType = {
  offerCards: ResultCardType[];
  offerCardsCoordinates: OfferCardCoordinatesType[];
  filterBtnsByType: FilterBtnsType[];
  params: SearchParamsDataType;
  viewedAds: ResultCardType[];
  searchOfferCardsData: SearchHouseDataType;
  mainInputsParams: InputsType;
  isShowResultBlock: boolean;
  isShowMapBlock: boolean;
  isMoreLoading: boolean;
  isLoadingResultBlock: boolean;
  isLoadingTopBlock: boolean;
  isSimilarLoading: boolean;
  objectCount: number | undefined;
  similarCards: ResultCardType[];
  centerPointMap: string[] | undefined;
  activeFilterBtn: FilterBtnsType | undefined;
};
export const useOfferCardsStore = defineStore('offerCards', {
  state: (): OfferCardsType => {
    return {
      offerCards: [],
      offerCardsCoordinates: [],
      params: {},
      filterBtnsByType: [],
      viewedAds: [],
      mainInputsParams: [],
      searchOfferCardsData: {
        page: 1,
        pageSize: 8,
      },
      isShowResultBlock: false,
      isShowMapBlock: false,
      isMoreLoading: false,
      isLoadingResultBlock: false,
      isSimilarLoading: false,
      objectCount: undefined,
      similarCards: [],
      centerPointMap: undefined,
      activeFilterBtn: undefined,
      isLoadingTopBlock: false,
    };
  },
  actions: {
    async getTopHouse() {
      this.isLoadingTopBlock = true;
      await houseTopApi()
        .then(res => {
          this.filterBtnsByType = [];
          res.data.forEach(data => {
            this.filterBtnsByType.push({
              ...data,
              topBlocks: [
                ...data.topBlocks.map(topBlock => {
                  const topHouse = topBlock.data.map(el => prepareOfferCard(el));

                  return {
                    title: topBlock.title,
                    data: topHouse,
                  };
                }),
              ],
            });
          });
        })
        .finally(() => {
          this.getActiveFilterBtn();
          this.isLoadingTopBlock = false;
        });
    },
    async getSimilarHouse(averagePrice: number, excludedIds: number[]) {
      const res = await houseSimilarApi({
        average: averagePrice,
        exclude: excludedIds,
        region: useAppStore().regions_for_select.defaultForUser,
      });

      if (res && res.data.found) {
        return res.data.found.map(el => prepareOfferCard(el));
      }
    },
    async searchOfferCards(preparedData: SearchHouseDataType, showMore: boolean = false) {
      const res = await searchHouseApi(preparedData);

      if (res) {
        if (res.data.found) {
          if (showMore) {
            this.offerCards = [
              ...this.offerCards,
              ...res.data.found.map(el => prepareOfferCard(el)),
            ];
          } else {
            this.offerCards = res.data.found.map(el => prepareOfferCard(el));
          }
        } else {
          this.offerCards = [];
        }

        return res.data;
      }
    },
    async searchOfferCardsCoordinates(preparedData: SearchHouseCoordinatesDataType) {
      const res = await searchHouseCoordinatesApi(preparedData);

      if (res) {
        this.offerCardsCoordinates = res.data;

        return 'success';
      }
    },
    async searchOfferCardById(cardId: number) {
      const res = await searchHouseByIdApi(cardId);

      if (res) {
        const ad = prepareOfferCard(res.data);

        this.viewedAds = this.viewedAds.filter(el => el.id !== ad.id);
        this.viewedAds.unshift(ad);
        this.viewedAds = this.viewedAds.slice(0, 7);
        localStorage.setItem('viewed-ads', JSON.stringify(this.viewedAds));

        return ad;
      }
    },
    async getHouseCostHistory(cardId: number) {
      const res = await getHouseCostHistoryApi(cardId);

      if (res) {
        return res.data;
      }
    },
    async sendComplain(data: SendComplainDataType) {
      const res = await sendComplainApi(data);

      if (res) {
        return res.data;
      }
    },
    updateParameter(
      id: string,
      value: string | number | null | boolean,
      min: string | null | number,
      max: string | null | number,
    ) {
      if (value || min || max) {
        let preparedValue;

        switch (value) {
          case 'true':
            preparedValue = true;
            break;
          case 'false':
            preparedValue = false;
            break;
          default:
            preparedValue = value;
        }
        this.params[id] = {
          value: preparedValue,
          min: min,
          max: max,
        };
      } else {
        delete this.params[id];
      }
    },
    changeActiveFilterBtn(filter: FilterBtnsType) {
      if (filter.id === 3) {
        this.deleteTerritoryFilterParams();
        this.updateParameter('202', null, null, null);
        this.updateParameter('203', null, null, null);
        this.updateParameter('199', null, null, null);
      } else {
        this.updateParameter('365', null, null, null);
        this.updateParameter('116', null, null, null);
        this.updateParameter('113', null, null, null);
      }

      this.activeFilterBtn = filter;

      const param306 = (): string | null => {
        switch (this.activeFilterBtn?.id) {
          case 1:
            return '265';
          case 2:
            return '266';
          case 3:
            return '267';
          default:
            return null;
        }
      };

      this.updateParameter('306', param306(), null, null);

      if (this.isShowResultBlock || this.isShowMapBlock) {
        this.updateSearchOfferCardsData();
        this.getOfferCards();
      }
    },
    getActiveFilterBtn() {
      //Определяем активную кнопку фильтра по типу
      const param306 = this.params[306]?.value;

      if (param306) {
        switch (param306) {
          case '265':
            this.activeFilterBtn = this.filterBtnsByType[0];
            break;
          case '266':
            this.activeFilterBtn = this.filterBtnsByType[1];
            break;
          case '267':
            this.activeFilterBtn = this.filterBtnsByType[2];
            break;
        }
      } else {
        this.activeFilterBtn = this.filterBtnsByType[0];
        this.updateParameter('306', '265', null, null);
      }
    },
    deleteTerritoryFilterParams() {
      const territoryGroupsIds = useSearchRealEstateStore()
        .filterGroups.filter(
          el => el.categoryId === 2 || el.categoryId === 4 || el.categoryId === 1,
        )
        .map(el => el.groups.map(el => el.id));

      let territoryParams: number[] = [];

      territoryParams = territoryGroupsIds
        .map(territoryGroups => {
          return useSearchRealEstateStore()
            .filterSubGroups.filter(el => territoryGroups.includes(el.groupId))
            .map(el => el.parameters.map(param => param.optionsIds));
        })
        .flat(3);

      territoryParams.forEach(el => {
        delete this.params[el];
      });
    },
    getViewedAdsFromLocalStorage() {
      const viewedAds = localStorage.getItem('viewed-ads');

      if (viewedAds) {
        this.viewedAds = JSON.parse(viewedAds);
      } else {
        this.viewedAds = [];
      }
    },
    // функция для обновления основных inputs  по id
    updateMainInputsParams(event: { id: number; value: string | number | undefined }) {
      if (this.mainInputsParams.find(el => el.id === event.id)) {
        this.mainInputsParams.find(el => el.id === event.id)!.value = event.value;
      }
    },
    rewriteMainInputsParams(params: InputsType) {
      this.mainInputsParams = params;
    },
    updateSearchOfferCardsData() {
      this.searchOfferCardsData = {
        ...this.searchOfferCardsData,
        params: this.getPreparedParamsForSend(),
      };
    },
    updateSortParam(sortParam: string) {
      this.searchOfferCardsData = {
        ...this.searchOfferCardsData,
        page: 1,
        sorting: sortParam,
      };
    },
    updatePageSizeParams(page: number, pageSize: number) {
      this.searchOfferCardsData = {
        ...this.searchOfferCardsData,
        page: page,
        pageSize: pageSize,
      };
    },
    toggleIsShowResultBlock(bool: boolean) {
      this.isShowResultBlock = bool;
    },
    async getOfferCards(showMore?: boolean) {
      const preparedData = this.searchOfferCardsData;

      if (showMore) {
        this.isMoreLoading = true;
      } else {
        this.isLoadingResultBlock = true;
        this.isShowResultBlock = true;
      }

      // Добавляем фильтры в URL
      const str = qs.stringify(useOfferCardsStore().params, {
        arrayFormat: 'brackets',
        skipNulls: true,
        encodeValuesOnly: true,
      });

      window.history.replaceState(window.history.state, '', `?search=${str}`);

      // Отправляем запрос для получения объектов
      try {
        if (this.isShowMapBlock) {
          await this.searchOfferCardsCoordinates({
            params: this.searchOfferCardsData.params,
          });
        }
        if (window.location.pathname !== '/fullMap') {
          const res = await this.searchOfferCards(preparedData, showMore);

          if (res) {
            this.objectCount = res.count;
            await this.getSimilarCards();
          }
        }
      } finally {
        if (showMore) {
          this.isMoreLoading = false;
        } else {
          this.isLoadingResultBlock = false;
        }
      }
    },
    async getSimilarCards() {
      const averagePrice =
        this.offerCards.reduce((a, b) => a + +b.price, 0) / this.offerCards.length;

      const offerCardIds = this.offerCards.map(el => el.id);

      if (averagePrice) {
        try {
          this.isSimilarLoading = true;
          const res = await this.getSimilarHouse(averagePrice, offerCardIds);

          if (res) {
            this.similarCards = res;
          }
        } finally {
          this.isSimilarLoading = false;
        }
      } else {
        this.similarCards = [];
      }
    },
    showResultBlock(isMapBLock?: boolean) {
      const oldParams = this.getPreparedParamsForSend();
      const inputs = useOfferCardsStore().mainInputsParams;

      const prepareValue = (id: number) => {
        const foundValue = inputs.find(el => el.id === id)?.value;

        if (foundValue) {
          if (id === 202 || id === 113) {
            return foundValue;
          }

          return +`${foundValue}`?.replace(/[^0-9]/g, '');
        }

        return null;
      };

      this.updateParameter(
        `${inputs[0].id}`,
        prepareValue(inputs[0].id) ? String(prepareValue(inputs[0].id)) : null,
        null,
        null,
      );
      this.updateParameter('203', null, prepareValue(2), prepareValue(3));
      this.updateParameter('199', null, prepareValue(4), prepareValue(5));
      this.updateParameter('365', null, prepareValue(7), prepareValue(8));
      this.updateParameter('116', null, prepareValue(9), prepareValue(10));
      this.updateSearchOfferCardsData();

      if (
        (oldParams !== this.getPreparedParamsForSend() || !this.isShowMapBlock) &&
        isMapBLock
      ) {
        this.isShowMapBlock = true;
        this.getOfferCards();
      } else if (
        oldParams !== this.getPreparedParamsForSend() ||
        !this.isShowResultBlock
      ) {
        this.updatePageSizeParams(1, this.searchOfferCardsData.pageSize);
        this.getOfferCards();
      }
    },
    hideMapBlock() {
      this.isShowMapBlock = false;
    },
    updateCenterPointMap(point1: string, point2: string) {
      this.centerPointMap = [point1, point2];
    },
  },
  getters: {
    getOfferCardById:
      state =>
      (id: number): ResultCardType | undefined => {
        return state.offerCards.find(el => el.id === id);
      },

    getPreparedParamsForSend: state => (): string => {
      const prepareValue = (
        value: string | number | boolean | null,
      ): string | number | null => {
        if (value === true) {
          return '1';
        }
        if (value === false) {
          return '2';
        }

        return value;
      };
      const preparedParams: SearchParamsDataType = {};

      Object.entries(state.params).forEach(param => {
        preparedParams[param[0]] = { ...param[1], value: prepareValue(param[1].value) };
      });

      const region = useAppStore().regions_for_select.defaultForUser;

      if (region !== undefined) {
        preparedParams[353] = { value: region, min: null, max: null };
      }

      return JSON.stringify(preparedParams);
    },
  },
});

export const useOfferCardsStoreWithOut = () => {
  return useOfferCardsStore(store);
};
