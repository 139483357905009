<template>
  <div>
    <base-card class="developer-project-card">
      <base-skeleton-loader type="img" />
      <div class="developer-project-card__title-box">
        <base-skeleton-loader
          :style="{ height: '30px', width: '100%' }"
          class="mb--5"
          type="mediumText"
        />
        <base-skeleton-loader
          :style="{
            borderRadius: '50%',
            height: iconHeightAndWidth,
            width: iconHeightAndWidth,
          }"
          type="icon"
        />
      </div>

      <div class="developer-project-card__loading-content">
        <div class="developer-project-card__content-developer">
          <base-skeleton-loader :style="{ width: '100%' }" class="mb--5" type="text" />
          <base-skeleton-loader :style="{ width: '100%' }" class="mb--10" type="text" />
        </div>
        <div class="developer-project-card__content-address">
          <base-skeleton-loader
            :style="{ width: '100%' }"
            class="mb--5 only-mobile"
            type="text"
          />
          <base-skeleton-loader :style="{ width: '100%' }" class="mb--10" type="text" />
        </div>
        <div class="items-in-column gap--0">
          <base-skeleton-loader :style="{ width: '100%' }" class="mb--10" type="text" />
        </div>
      </div>
      <div class="developer-project-card__bottom-content none-Mobile mt--0 mb--0">
        <base-skeleton-loader type="btn" />
        <base-skeleton-loader type="icon" />
      </div>
    </base-card>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useAppStore } from '@/store/modules/app';

const iconHeightAndWidth = computed(() => {
  return useAppStore().getWindowWidth() > 600 ? '39px' : '30px';
});
</script>

<style lang="scss" scoped src="./ResidentialComplexCard.scss"></style>
