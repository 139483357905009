<template>
  <div :class="ratingClass" class="rating">
    {{ rating }}
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    rating: number;
    maxRate?: number;
  }>(),
  {
    maxRate: 100,
  },
);

const ratingClass = computed((): string => {
  if (props.rating < props.maxRate / 5) {
    return 'blue';
  }
  if (props.rating < (props.maxRate / 5) * 2) {
    return 'green';
  }
  if (props.rating < (props.maxRate / 5) * 3) {
    return 'yellow';
  }
  if (props.rating < (props.maxRate / 5) * 4) {
    return 'orange';
  }

  return 'darkOrange';
});
</script>

<style lang="scss" scoped src="./BaseRatingNumber.scss"></style>
