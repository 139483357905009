<template>
  <div v-if="isGreen && preparedInfographicImgs.length" class="infographic-imgs">
    <div v-for="item in infographicImgs" class="infographic-imgs__item">
      <div class="infographic-imgs__item-img">
        <base-image
          :name="item.title"
          :src="item.icon"
          contain
          height="28"
          width="100%"
        />
      </div>
      <div>
        <div class="infographic-imgs__item-text">{{ item.title }}</div>
      </div>
    </div>
  </div>
  <div v-else-if="preparedInfographicImgs.length" class="infographic-imgs-grey">
    <div
      :class="{
        more8: preparedInfographicImgs.length > 8,
        more6: preparedInfographicImgs.length > 6,
        more5: preparedInfographicImgs.length > 5,
        more4: preparedInfographicImgs.length > 4,
        more2: preparedInfographicImgs.length > 2,
      }"
      class="infographic-imgs-grey__container"
    >
      <div
        v-for="(item, i) in preparedInfographicImgs"
        :key="i"
        :style="{ gridArea: `infoItem${i + 1}` }"
        class="infographic-imgs-grey__container-item"
      >
        <base-image :name="item.title" :src="item.icon" contain height="25" width="30" />
        <div class="infographic-imgs-grey__container-title">
          {{ item.title }}
        </div>
      </div>
      <base-divider
        v-for="i in 4"
        :class="`infographic-imgs-grey__container-divider${i}`"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, toRaw } from 'vue';
import { useAppStore } from '@/store/modules/app';

type InfographicImgsType = {
  title: string;
  icon: string | undefined;
}[];

const props = withDefaults(
  defineProps<{
    infographics: {
      [key in number]: { title: string; value: number | number[] | string };
    };
    isGreen?: boolean;
  }>(),
  {
    isGreen: true,
  },
);

const preparedInfographicImgs = computed((): InfographicImgsType => {
  return infographicImgs.value.slice(0, 10);
});

const infographicImgs = computed((): InfographicImgsType => {
  const infographicData: InfographicImgsType = [];

  Object.entries(props.infographics).forEach(el => {
    const value = toRaw(el[1].value);

    const iconData = useAppStore().infographic_icons[el[0]];

    const addInfographicData = (value: number | number[] | string) => {
      const foundIconData = iconData.icons.find(el => {
        if (Array.isArray(value) && el.value) {
          return value.includes(+el.value);
        }
        if (!el.value && el.value !== null && !el.min_value && !el.max_value) {
          return !!value;
        }
        if (el.min_value || el.max_value) {
          if (el.min_value && !el.max_value) {
            return value >= el.min_value;
          }
          if (el.max_value && !el.min_value) {
            return value <= el.max_value;
          }
          if (el.min_value && el.max_value) {
            return value >= el.min_value && value <= el.max_value;
          }
        }

        return value === el.value;
      });

      if (foundIconData) {
        infographicData.push({
          title: foundIconData.title || iconData.name,
          icon: props.isGreen ? foundIconData.path : foundIconData.alternative_path,
        });
      }
    };

    if (iconData && value) {
      if (Array.isArray(value) && value.length > 1) {
        value.forEach(el => {
          addInfographicData(String(el));
        });
      } else {
        addInfographicData(value);
      }
    }
  });

  return infographicData;
});
</script>

<style lang="scss" scoped src="./InfographicImgs.scss"></style>
