<template>
  <div class="auth-modal">
    <div>
      <div class="align-center l-font-size">Введите новый пароль</div>
    </div>
    <base-form class="grid--1 gap--15">
      <base-input
        v-model="formData.password"
        :error="errors.password"
        :invalid="!!errors.password"
        height="40"
        label="Пароль"
        type="password"
        @blur="validationErrorsHandlerByKey('password')"
        @focus="delete errors.password"
        @input="v$.password.$touch"
      />
      <base-input
        v-model="formData.password_confirmation"
        :error="errors.password_confirmation"
        :invalid="!!errors.password_confirmation"
        height="40"
        label="Повторите пароль"
        type="password"
        @blur="validationErrorsHandlerByKey('password_confirmation')"
        @focus="delete errors.password_confirmation"
        @input="v$.password_confirmation.$touch"
      />
      <base-button
        :loading="loading"
        allBlockWidth
        height="40"
        text="Сбросить пароль"
        @click="formSubmit"
      />
    </base-form>
    <base-divider v-if="!useUserStore().user" />
    <button
      v-if="!useUserStore().user"
      class="second-color xs-font-size"
      @click="$emit('close')"
    >
      Перейти на страницу входа
    </button>
  </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, Ref, ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, sameAs } from '@vuelidate/validators';
import {
  errorsHandler,
  errorsHandlerByKey,
  validationErrorsByRequest,
} from '@/mixins/Common/Common';
import { ErrorsType, ValidateRulesType } from '@/types';
import { changePasswordApi, passwordRestoreApi } from '@/api/login';
import { useRoute } from 'vue-router';
import { useUserStore } from '@/store/modules/user';

const emit = defineEmits(['close']);

type FormDataType = {
  password: string | undefined;
  password_confirmation: string | undefined;
};

const formData: Ref<FormDataType> = ref({
  password: undefined,
  password_confirmation: undefined,
});
const clearFormData = () => {
  v$.value.$reset();
  for (const [key] of Object.entries(formData)) {
    formData.value[key as keyof FormDataType] = undefined;
  }
};

const passwordRule = helpers.regex(/(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])/);
const rules: ValidateRulesType = {
  password: { required, passwordRule },
  password_confirmation: {
    required,
    sameAsPassword: sameAs(
      computed(() => {
        return formData.value.password;
      }),
    ),
  },
};
const v$ = useVuelidate<FormDataType>(rules, formData);

const errors: Ref<ErrorsType> = ref({});

const validationErrorsHandlerByKey = (key: string) => {
  let error: string | undefined = errorsHandlerByKey(key, rules, v$);

  if (error) {
    errors.value[key] = error;
  } else {
    delete errors.value[key];
  }
};
const validationErrorsHandler = () => {
  errors.value = errorsHandler(formData.value, rules, v$);
};

const params: Ref<
  | {
      id: string;
      token_confirmed: string;
    }
  | undefined
> = ref(undefined);

const loading = ref(false);
const formSubmit = async () => {
  if (v$.value.$invalid) {
    v$.value.$touch();
    validationErrorsHandler();

    return;
  }
  loading.value = true;
  let preparedFormData = {
    ...formData.value,
    ...params.value,
  };
  let preparedFormData2 = {
    newPassword: formData.value.password ?? '',
    confirmPassword: formData.value.password_confirmation ?? '',
  };

  try {
    const res = useUserStore().user
      ? await changePasswordApi(preparedFormData2)
      : await passwordRestoreApi(preparedFormData);

    if (res) {
      clearFormData();
      emit('close');
    }
  } catch (error) {
    errors.value = validationErrorsByRequest(error);
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  if (useRoute()?.name === 'passwordRestore') {
    params.value = {
      id: useRoute()?.query.id as string,
      token_confirmed: useRoute()?.query.token_confirmed as string,
    };
  }
});
</script>

<style lang="scss" scoped src="./AuthModal.scss"></style>
