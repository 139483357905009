<template>
  <div>
    <base-card class="user-support" full-width-mobile>
      <div class="m-font-size mb--20">Техническая поддержка пользователей</div>
      <base-form>
        <base-textarea
          v-model="formData.comment"
          :error="errors.comment"
          :invalid="!!errors.comment"
          height="81"
          label="Сообщение"
          textarea
          @blur="validationErrorsHandlerByKey('comment')"
          @focus="delete errors.comment"
          @input="v$.comment?.$touch"
        />
      </base-form>
      <div class="center mt--20">
        <base-button
          :loading="isLoading"
          height="36"
          text="Отправить"
          type="submit"
          width="165"
          @click="formSubmit"
        />
      </div>
    </base-card>
  </div>
</template>

<script lang="ts" setup>
import { Ref, ref } from 'vue';
import { useVuelidate, ValidationRuleWithoutParams } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { ErrorsType } from '@/types';
import {
  errorsHandler,
  errorsHandlerByKey,
  validationErrorsByRequest,
} from '@/mixins/Common/Common';
import { useUserStore } from '@/store/modules/user';
import { useAppStore } from '@/store/modules/app';

type FormDataType = {
  comment: string | undefined;
};
const formData = ref({
  comment: '',
});
const clearFormData = () => {
  v$.value.$reset();
  formData.value.comment = '';
};
const rules: { [key: string]: { [key: string]: ValidationRuleWithoutParams } } = {
  comment: { required },
};

const v$ = useVuelidate<FormDataType>(rules, formData);
const errors: Ref<ErrorsType> = ref({});
const validationErrorsHandlerByKey = (key: string) => {
  let error: string | undefined = errorsHandlerByKey(key, rules, v$);

  if (error) {
    errors.value[key] = error;
  } else {
    delete errors.value[key];
  }
};
const validationErrorsHandler = () => {
  errors.value = errorsHandler(formData.value, rules, v$);
};

const isLoading = ref(false);
const formSubmit = async () => {
  if (v$.value.$invalid) {
    v$.value?.$touch();
    validationErrorsHandler();

    return;
  }

  isLoading.value = true;
  try {
    const res = await useUserStore().sendSupport(formData.value);

    if (res) {
      useAppStore().openAlert('', 'Сообщение отправлено', 'success');
      clearFormData();
    }
  } catch (error) {
    errors.value = validationErrorsByRequest(error);
  } finally {
    isLoading.value = false;
  }
};
</script>

<style lang="scss" scoped src="./UserSupport.scss"></style>
