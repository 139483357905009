<template>
  <teleport v-if="showModal" to="#modalWindow">
    <dialog class="modal" @click="closeModalIfBackgroundClicked">
      <div ref="dialog">
        <div v-if="customModal">
          <slot name="content"></slot>
        </div>

        <div
          v-else
          :class="{ fullScreen, transparent, withBackground }"
          class="modal__content"
        >
          <div
            v-if="!$slots.title && withBackground && !hideTitle"
            :class="{ showTitlePadding }"
            class="modal__content-title m-font-size"
          >
            {{ title }}
          </div>
          <base-divider v-if="!$slots.title" />
          <div class="mr--30">
            <slot name="title"></slot>
          </div>

          <div
            ref="targetOne"
            :class="{ showPadding, hideTitle }"
            class="modal__content-box items-in-column"
          >
            <slot name="content"></slot>
            <slot name="bottom-box"></slot>
            <div v-if="$slots.btn" class="modal__content-btn center allBlockWidth">
              <slot name="btn"></slot>
            </div>
          </div>
          <base-icon
            v-if="showCloseIcon"
            class="modal__content-closeIcon"
            clickable
            color="black"
            height="15"
            name="close"
            width="15"
            @click="closeModal"
          />
        </div>
      </div>
    </dialog>
  </teleport>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, ref, toRefs, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    showModal?: boolean;
    closeOutside?: boolean;
    showCloseIcon?: boolean;
    fullScreen?: boolean;
    title?: string;
    showPadding?: boolean;
    showTitlePadding?: boolean;
    transparent?: boolean;
    customModal?: boolean;
    withBackground?: boolean;
    hideScroll?: boolean;
    hideTitle?: boolean;
  }>(),
  {
    showCloseIcon: true,
    showPadding: true,
    showTitlePadding: true,
    withBackground: true,
    customModal: false,
    hideScroll: true,
  },
);

const emit = defineEmits(['close']);
const show = ref(false);
const closeModal = () => {
  emit('close');
};

const closeModalIfBackgroundClicked = (event: Event) => {
  if (!props.closeOutside) {
    return;
  }
  if (event.target === event.currentTarget) {
    closeModal();
  }
};
const targetOne = ref<HTMLElement | undefined>();
const dialog = ref<HTMLElement | undefined>();

const { showModal } = toRefs(props);

watch(showModal, isOpen => {
  if (props.hideScroll) {
    document.body.classList[isOpen ? 'add' : 'remove']('overflow-hidden');
  }
});
onBeforeUnmount(() => {
  if (props.hideScroll) {
    document.body.classList.remove('overflow-hidden');
  }
});
</script>

<style lang="scss" scoped src="./BaseModal.scss"></style>
