<template>
  <div class="container content">
    <h2 class="content__title">Юридические документы</h2>
    <div class="juristicDocuments">
      <base-card
        v-for="(doc, i) in documents"
        :key="i"
        class="juristicDocuments__docItem scale"
      >
        <a :href="doc.document" class="items-in-column align-center" download>
          <base-icon font-size="50" name="description" />
          <span>
            {{ doc.title }}
          </span>
        </a>
      </base-card>
    </div>
  </div>
</template>

<script lang="ts" setup>
const documents = [
  {
    title: 'Политика конфиденциальности',
    document: '',
  },
  {
    title: 'Согласие на обработку персональных данных',
    document: '',
  },
  {
    title: 'Согласие на получение рекламных рассылок',
    document: '',
  },
  {
    title: 'Рекомендация технологии',
    document: '',
  },
  {
    title: 'Лицензионное соглашение',
    document: '',
  },
  {
    title: 'Соглашение использования сайта banki.shop',
    document: '',
  },
  {
    title: 'Реквизиты',
    document: '',
  },
];
</script>

<style lang="scss" scoped src="./JuristicDocuments.scss"></style>
