import { useUserStore } from '@/store/modules/user';
import { useChatStore } from '@/store/modules/chat';
import { defaultDevelopersState, useDevelopersStore } from '@/store/modules/developers';
import { defaultCompareStore, useCompareStore } from '@/store/modules/compare';

export const ClearStateAndStorage = () => {
  useUserStore().writeUserInfo(null);
  useChatStore().closeMiniMessenger();
  localStorage.clear();
  useDevelopersStore().$state = { ...defaultDevelopersState };
  useCompareStore().$state = { ...defaultCompareStore };
};
