<template>
  <button
    :class="[
      {
        active: checkboxValue,
      },
      { onlyRead },
      {
        error: error?.length,
      },
    ]"
    :disabled="disabled || onlyRead"
    class="checkBox"
    type="button"
    @click.stop="changeCheckbox"
  >
    <base-icon
      :class="{ active: checkboxValue }"
      class="checkBox__check"
      color="#009D3E"
      font-size="16"
      name="done"
    />
    <span v-if="error?.length" class="checkBox__error">{{ error }}</span>
  </button>
</template>

<script lang="ts" setup>
// eslint-disable-next-line no-undef
const checkboxValue = defineModel<boolean>();

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    onlyRead?: boolean;
    error?: string;
  }>(),
  {
    disabled: false,
  },
);

const changeCheckbox = () => {
  if (!props.onlyRead) {
    checkboxValue.value = !checkboxValue.value;
  }
};
</script>

<style lang="scss" scoped src="./BaseCheckbox.scss"></style>
