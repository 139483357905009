import {
  KeyArrayObjectType,
  OtherProjectType,
  ResidentialComplexApiType,
} from '@/api/developer/types';
import { FileType, ResidentialComplexType } from '@/types';
import {
  prepareArrByParam,
  prepareCoordinatesByParam,
  prepareDocsByParam,
  prepareNameValueByParams,
  prepareParam,
  prepareParamOfArray,
  prepareUrl,
} from '@/mixins/Common/Common';
import { SmallCardLSDType } from '@/types/DeveloperTypes';

export const prepareResidentialComplex = (
  residentialComplex: ResidentialComplexApiType,
): ResidentialComplexType => {
  const getDocuments = (): FileType[] => {
    const docs: FileType[] = [];
    const docIds: KeyArrayObjectType[] = [301, 104, 105, 103, 102];

    docIds.forEach(id => {
      if (residentialComplex[id]) {
        residentialComplex[id][0].value.forEach(doc => {
          docs.push({
            id: doc.id,
            name: doc.name || 'файл',
            path: prepareUrl(doc.path),
          });
        });
      }
    });

    return docs;
  };

  const getRenders = (): string[] => {
    const imgs: string[] = [];

    if (residentialComplex[97]) {
      residentialComplex[97].forEach(item => {
        Array.isArray(item.value)
          ? item.value.map(el => {
              imgs.push(prepareUrl(el.path) || '');
            })
          : imgs.push(prepareUrl(item.value) || '');
      });
    }

    return imgs;
  };

  return {
    title: prepareParam(224, residentialComplex),
    logo: prepareParamOfArray(84, residentialComplex),
    address: prepareParam(88, residentialComplex),
    description: prepareParam(87, residentialComplex),
    phone: prepareParam(96, residentialComplex),
    coordinates: prepareCoordinatesByParam(225, residentialComplex),
    partners: prepareArrByParam(341, residentialComplex),
    documents: getDocuments(),
    renders: getRenders(),
    developer: {
      id: residentialComplex.developerId,
      title: prepareParam(6, residentialComplex[residentialComplex.developerId]) || '',
      logo: prepareParamOfArray(9, residentialComplex[residentialComplex.developerId]),
      builtCount: prepareParam(17, residentialComplex[residentialComplex.developerId]),
      buildingCount: prepareParam(18, residentialComplex[residentialComplex.developerId]),
      description: prepareParam(11, residentialComplex[residentialComplex.developerId]),
    },

    infographics: residentialComplex.infographics,
    presentation: prepareDocsByParam(301, residentialComplex),
    passportLCItems: prepareNameValueByParams(residentialComplex.passport_lc),
  };
};

export const prepareSmallCardLSD = (
  residentialComplex: OtherProjectType[],
): SmallCardLSDType[] => {
  const smallCards: SmallCardLSDType[] = [];

  residentialComplex.forEach(project => {
    Object.entries(project).forEach(LSD => {
      smallCards.push({
        id: LSD[0],
        developerTitle: LSD[1][6]?.value,
        coefficient: LSD[1]?.coefficient,
        title: LSD[1][224]?.value,
        logo: LSD[1][84]
          ? prepareUrl(
              Array.isArray(LSD[1][84].value)
                ? LSD[1][84].value[0].path
                : LSD[1][84].value,
            )
          : undefined,
        address: LSD[1][88]?.value,
        deadline: LSD[1][89]?.value,
        phone: LSD[1][96]?.value,
        coordinates: LSD[1][225]
          ? [{ id: LSD[1][225].id, coordinates: [+LSD[1][225].min, +LSD[1][225].max] }]
          : [],
      });
    });
  });

  return smallCards;
};
