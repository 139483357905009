import request from '@/axios';
import { CheckResultRequestType } from '@/api/check/types';

export const getCheckResultByIdApi = (
  cardId: number,
): Promise<IResponse<CheckResultRequestType>> => {
  return request.post({
    url: process.env.VUE_APP_API_CHECK_RESULT,
    params: {
      id: cardId,
    },
  });
};
