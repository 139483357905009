import request from '@/axios';
import {
  AllDevelopersType,
  AllResidentialComplexType,
  CategoryDataCreateApiType,
  CategoryDataForUpdateApiType,
  CategoryValueApiType,
  LegalEntitiesType,
  ListDataType,
  MyAdsType,
  ObjectParamsApiType,
  OtherDevelopersType,
  OtherProjectType,
  ResidentialComplexApiType,
  UpdateTypes,
} from './types';
import { CreateCategoryDataType } from '@/store/modules/developers';
import { SearchDataByIdType } from '@/api/house/types';

export const categoryValueListApi = (): Promise<IResponse<OtherDevelopersType[]>> => {
  return request.get({
    url: process.env.VUE_APP_API_CATEGORY_VALUE_LIST,
  });
};
export const getResidentialComplexListApi = (): Promise<
  IResponse<OtherProjectType[]>
> => {
  return request.get({
    url: process.env.VUE_APP_API_CATEGORY_VALUE_RESIDENTIAL_COMPLEX_LIST,
  });
};

export const getOrderListApi = (): Promise<IResponse<MyAdsType[]>> => {
  return request.get({
    url: process.env.VUE_APP_API_CATEGORY_VALUE_ORDER_LIST,
  });
};

export const getDraftsApi = (): Promise<IResponse<MyAdsType[]>> => {
  return request.get({
    url: process.env.VUE_APP_API_CATEGORY_VALUE_DRAFTS,
  });
};
export const getArchiveApi = (): Promise<IResponse<MyAdsType[]>> => {
  return request.get({
    url: process.env.VUE_APP_API_CATEGORY_VALUE_ARCHIVE,
  });
};

const getUpdateURL = (type: UpdateTypes) => {
  let url;

  switch (type) {
    case 'updateDeveloper':
      url = process.env.VUE_APP_API_CATEGORY_VALUE_UPDATE;
      break;
    case 'updateResidentialComplex':
      url = process.env.VUE_APP_API_CATEGORY_VALUE_RESIDENTIAL_COMPLEX_UPDATE;
      break;
    case 'updateOfferCard':
      url = process.env.VUE_APP_API_CATEGORY_VALUE_ORDER_UPDATE;
      break;
  }

  return url;
};

export const categoryDataForUpdateApi = (
  type: UpdateTypes,
  id: number,
): Promise<IResponse<CategoryDataForUpdateApiType>> => {
  return request.get({
    url: getUpdateURL(type),
    params: {
      id: id,
    },
  });
};

export const updateCategoryDataApi = (
  type: UpdateTypes,
  data: FormData,
): Promise<IResponse<CategoryDataForUpdateApiType>> => {
  return request.post({
    url: getUpdateURL(type),
    data,
  });
};

export const getConstructionLinesApi = (
  id?: number,
): Promise<IResponse<LegalEntitiesType>> => {
  return request.get({
    url: process.env.VUE_APP_API_CATEGORY_VALUE_CONSTRUCTION_LINES,
    params: {
      id: id,
    },
  });
};

export const getDevelopersApi = (id?: number): Promise<IResponse<LegalEntitiesType>> => {
  return request.get({
    url: process.env.VUE_APP_API_CATEGORY_VALUE_DEVELOPERS,
    params: {
      id: id,
    },
  });
};
export const getCategoryResidentialComplexesApi = (
  id?: number,
): Promise<IResponse<LegalEntitiesType>> => {
  return request.get({
    url: process.env.VUE_APP_API_CATEGORY_VALUE_RESIDENTIAL_COMPLEXES,
    params: {
      id: id,
    },
  });
};
export const getLegalEntitiesApi = (): Promise<IResponse<LegalEntitiesType>> => {
  return request.get({
    url: process.env.VUE_APP_API_CATEGORY_VALUE_LEGAL_ENTITIES,
  });
};
export const getCategoryValueApi = (): Promise<IResponse<CategoryValueApiType>> => {
  return request.get({
    url: process.env.VUE_APP_API_CATEGORY_VALUE_CREATE,
  });
};
export const createCategoryValueApi = (
  data: CreateCategoryDataType,
): Promise<IResponse<CategoryDataCreateApiType>> => {
  return request.post({
    url: process.env.VUE_APP_API_CATEGORY_VALUE_CREATE,
    data,
  });
};

export const addToArchiveApi = (id: number): Promise<IResponse<{ message: string }>> => {
  return request.post({
    url: process.env.VUE_APP_API_CATEGORY_VALUE_ADD_TO_ARCHIVE,
    params: { id: id },
  });
};
export const removeFromArchiveApi = (
  id: number,
): Promise<IResponse<{ message: string; activeTo: string }>> => {
  return request.post({
    url: process.env.VUE_APP_API_CATEGORY_VALUE_REMOVE_FROM_ARCHIVE,
    params: { id: id },
  });
};
export const prolongAdApi = (
  id: number,
): Promise<IResponse<{ message: string; activeTo: string }>> => {
  return request.post({
    url: process.env.VUE_APP_API_CATEGORY_VALUE_PROLONGATION,
    params: { id: id },
  });
};

export const deleteCategoryValueApi = (
  id: number,
): Promise<IResponse<{ message: string }>> => {
  return request.post({
    url: process.env.VUE_APP_API_CATEGORY_VALUE_DELETE,
    params: { id: id },
  });
};

export const deleteFileApi = (id: number): Promise<IResponse<{ message: string }>> => {
  return request.post({
    url: process.env.VUE_APP_API_CATEGORY_VALUE_DELETE_FILE,
    params: { id: id },
  });
};

export const searchDeveloperByIdApi = (
  developerId: number,
): Promise<IResponse<ObjectParamsApiType>> => {
  return request.post({
    url: process.env.VUE_APP_API_DEVELOPER_PAGE,
    params: {
      id: developerId,
    },
  });
};
export const searchOtherDevelopersByIdApi = (
  developerId: number,
): Promise<IResponse<OtherDevelopersType[]>> => {
  return request.post({
    url: process.env.VUE_APP_API_DEVELOPER_OTHER,
    params: {
      id: developerId,
    },
  });
};

export const searchResidentialComplexByIdApi = (
  residentialComplexId: number,
): Promise<IResponse<ResidentialComplexApiType>> => {
  return request.post({
    url: process.env.VUE_APP_API_HOUSING_PAGE,
    params: {
      id: residentialComplexId,
    },
  });
};

export const searchDeveloperProjectsApi = (
  developerId: number,
): Promise<IResponse<OtherProjectType[]>> => {
  return request.post({
    url: process.env.VUE_APP_API_ALL_DEVELOPER_HOUSING_COMPLEX,
    params: {
      id: developerId,
    },
  });
};

export const searchOtherProjectsByIdApi = (
  residentialComplexId: number,
): Promise<IResponse<OtherProjectType[]>> => {
  return request.post({
    url: process.env.VUE_APP_API_OTHER_PROJECTS,
    params: {
      id: residentialComplexId,
    },
  });
};

export const getAllDevelopersApi = (
  data: ListDataType,
): Promise<IResponse<AllDevelopersType>> => {
  return request.post({
    url: process.env.VUE_APP_API_ALL_DEVELOPERS,
    data,
  });
};
export const getAllResidentialComplexApi = (
  data: ListDataType,
): Promise<IResponse<AllResidentialComplexType>> => {
  return request.post({
    url: process.env.VUE_APP_API_ALL_HOUSING_COMPLEX,
    data,
  });
};

export const getOffersOfResidentialComplexApi = (
  residentialComplexId: number,
): Promise<IResponse<SearchDataByIdType[]>> => {
  return request.post({
    url: process.env.VUE_APP_API_RESIDENTIAL_COMPLEX_OFFERS,
    params: {
      id: residentialComplexId,
    },
  });
};
