import request from '@/axios';
import {
  AllPartnerProgramsType,
  HousesListFromBackType,
  PartnerProgramsFromBackType,
  QueueListFromBackType,
  ResidentialComplexesFromBackType,
} from '@/api/partnerProgram/types';

export const getResidentialComplexesApi = (): Promise<
  IResponse<ResidentialComplexesFromBackType>
> => {
  return request.get({
    url: process.env.VUE_APP_API_AFFILIATE_PROGRAM_RESIDENTIAL_COMPLEXES,
  });
};
export const getQueueListApi = (
  residentialComplexId: number,
): Promise<IResponse<QueueListFromBackType>> => {
  return request.get({
    url: process.env.VUE_APP_API_AFFILIATE_PROGRAM_QUEUE_LISTS,
    params: {
      housing_company_id: residentialComplexId,
    },
  });
};

export const getHousesListApi = (
  queueCompanyId: number,
): Promise<IResponse<HousesListFromBackType>> => {
  return request.get({
    url: process.env.VUE_APP_API_AFFILIATE_PROGRAM_HOUSES_LISTS,
    params: {
      queue_company_id: queueCompanyId,
    },
  });
};

export const createPartnerProgramApi = (
  data: FormData,
): Promise<IResponse<PartnerProgramsFromBackType>> => {
  return request.post({
    url: process.env.VUE_APP_API_AFFILIATE_PROGRAM_CREATE,
    data,
  });
};

export const deletePartnerProgramApi = (
  affiliateProgramId: number,
): Promise<IResponse<{ message: string }>> => {
  return request.post({
    url: process.env.VUE_APP_API_AFFILIATE_PROGRAM_DELETE,
    params: {
      id: affiliateProgramId,
    },
  });
};
export const updatePartnerProgramApi = (
  data: FormData,
): Promise<IResponse<PartnerProgramsFromBackType>> => {
  return request.post({
    url: process.env.VUE_APP_API_AFFILIATE_PROGRAM_UPDATE,
    data,
  });
};

export const getAllPartnerProgramsApi = (paginationData?: {
  page: number;
  pageSize: number;
}): Promise<IResponse<AllPartnerProgramsType>> => {
  return request.get({
    url: process.env.VUE_APP_API_AFFILIATE_PROGRAM_ALL,
    params: {
      ...paginationData,
    },
  });
};

export const getCommissionByIdApi = (
  affiliateProgramId: number,
): Promise<IResponse<{ status: boolean; commission: number }>> => {
  return request.get({
    url: process.env.VUE_APP_API_AFFILIATE_PROGRAM_COMMISSION,
    params: {
      id: affiliateProgramId,
    },
  });
};

export const getPartnerDocumentsApi = (
  affiliateProgramId: number,
): Promise<IResponse<{ status: boolean; files: { id: number; name: string }[] }>> => {
  return request.get({
    url: process.env.VUE_APP_API_AFFILIATE_PROGRAM_FILES,
    params: {
      id: affiliateProgramId,
    },
  });
};
