<template>
  <div class="notifications">
    <div v-if="isLoading">
      <base-skeleton-loader
        v-for="i in 3"
        :key="i"
        :styles="{ height: '94px', width: '100%' }"
        class="mb--5 mt--20"
        type="mediumText"
      />
    </div>
    <div v-else-if="prepareNotifications.length" class="notifications__cards">
      <base-card v-for="item in prepareNotifications" :key="item.id" full-width-mobile>
        <div class="notifications__cards-item">
          <div class="notifications__cards-item-info">
            <div class="gradient-color">
              {{ item.title }}
            </div>
            <div class="items-in-line">
              <div class="notifications__item-info-date third-color">
                {{ new Date(item.created_at).toLocaleString() }}
              </div>
              <div :class="{ unread: !item.close }" class="notifications__cards-status" />
            </div>
          </div>
          <div v-html="replaceLinksInText(item.message)" />
          <div v-if="!item.close || item.channel_id" class="items-in-line flex-end">
            <base-button
              v-if="item.channel_id"
              :href="{
                params: { id: 'chat' },
                query: {
                  id: item.channel_id,
                },
              }"
              style-type="coloredBorder"
              text="Чат"
            />
            <base-button
              v-if="!item.close"
              :loading="closeNotificationsLoading.includes(item.id)"
              text="Закрыть"
              @click="closeNotification(item.id)"
            />
          </div>
        </div>
      </base-card>
    </div>
    <div v-else class="mt--20 pt--20 pb--20">
      <div class="center allBlockWidth">Уведомлений нет</div>
    </div>
    <base-pagination-group
      v-if="notifications.length || isLoading"
      v-model="prepareNotifications"
      :is-loading="isLoading"
      :max-for-visible="15"
      :values="notifications"
      auto-add-mobile
      class="mt--30"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, Ref } from 'vue';
import { NotificationsTypeFromBack } from '@/api/notification/types';
import { useUserStore } from '@/store/modules/user';
import { replaceLinksInText } from '@/mixins/Common/Common';

const isLoading = ref(false);
const closeNotificationsLoading: Ref<number[]> = ref([]);

const notifications = computed((): NotificationsTypeFromBack => {
  return useUserStore().notifications;
});
const prepareNotifications: Ref<NotificationsTypeFromBack> = ref(notifications.value);
const closeNotification = async (id: number) => {
  closeNotificationsLoading.value.push(id);
  try {
    await useUserStore().closeNotificationById(id);
  } finally {
    const index = closeNotificationsLoading.value.findIndex(el => el === id);

    if (index >= 0) {
      closeNotificationsLoading.value.splice(index, 1);
    }
  }
};

onMounted(async () => {
  isLoading.value = true;

  try {
    await useUserStore().getNotifications();
  } finally {
    isLoading.value = false;
  }
});
</script>

<style lang="scss" scoped src="./Notifications.scss"></style>
