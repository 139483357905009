<template>
  <div class="page-card">
    <div>
      <div id="about" class="section page-card__content mt--0">
        <base-image
          :src="developerCardData.logo"
          border-radius
          class="page-card__item-img"
          contain
          height="100%"
          name="logo"
          width="100%"
        />
      </div>
      <div class="page-card__content">
        <div class="font-size-title-block mb--20">О застройщике</div>
        <DeveloperSmallCard
          :developer="{
            id: developerCardData.id,
            title: developerCardData.title,
            logo: developerCardData.logo,
            buildingCount: developerCardData.buildingCount,
            builtCount: developerCardData.builtCount,
            description: developerCardData.description,
          }"
          is-full
        />
      </div>
      <div v-if="developerCardData.awards" class="page-card__content">
        <div class="font-size-title-block mb--20">Награды</div>
        <base-card class="page-card__swiper-card" full-width-mobile without-mobile-shadow>
          <base-swiper
            :slides="developerCardData.awards"
            :space-between="20"
            :width-item="130"
          >
            <template #item="itemProps">
              <a>
                <base-image
                  :name="String(itemProps)"
                  :src="itemProps.data"
                  border-radius
                  contain
                  height="100"
                  width="130"
                />
              </a>
            </template>
          </base-swiper>
        </base-card>
      </div>
      <div v-if="developerCardData.team" class="page-card__content">
        <div class="font-size-title-block mb--20">СК в лицах</div>
        <base-card class="page-card__swiper-card" full-width-mobile without-mobile-shadow>
          <base-swiper
            :slides="developerCardData.team"
            :space-between="20"
            :width-item="130"
          >
            <template #item="itemProps">
              <div class="items-in-column center gap--5">
                <base-image
                  :src="itemProps.data.photo"
                  contain
                  height="100"
                  name="img"
                  width="130"
                />
                <span>{{ itemProps.data['fio'] }}</span>
                <span>{{ itemProps.data['job'] }}</span>
              </div>
            </template>
          </base-swiper>
        </base-card>
      </div>
      <div
        v-if="developerCardData.documents"
        id="documentation"
        class="section page-card__content"
      >
        <div class="font-size-title-block mb--20">Документация</div>
        <div>
          <base-card
            class="page-card__documentation"
            full-width-mobile
            without-mobile-shadow
          >
            <DocumentList :documents="developerCardData.documents" />
          </base-card>
        </div>
      </div>
      <div id="map" class="page-card__content section page-card__map">
        <div class="font-size-title-block mb--20">ЖК на карте</div>
        <base-card full-width-mobile without-mobile-shadow>
          <MapBlock
            :center-point-map="developerCardData.coordinates[0]?.coordinates"
            :result-cards-coordinates="developerCardData.coordinates"
            :showOfferCard="false"
            class="mt--20"
            height="370"
          />
        </base-card>
      </div>
      <div id="allLCD" class="section page-card__content">
        <div class="font-size-title-block mb--20">Все ЖК строительной компании</div>
        <div v-if="projectsLoading" class="page-card__grid-cards">
          <LoadingResidentialComplexCard v-for="i in 3" :key="i" />
        </div>
        <div v-else>
          <div v-if="useAppStore().getWindowWidth() > 600">
            <base-swiper
              :slides="projects"
              :space-between="20"
              :width-item="240"
              class="mt--20 mb--20"
            >
              <template #item="itemProps">
                <div class="center page-card__RC-card">
                  <div>
                    <ResidentialComplexCard :project="itemProps.data" />
                  </div>
                </div>
              </template>
            </base-swiper>
          </div>

          <div v-else>
            <div class="page-card__grid-cards">
              <ResidentialComplexCard
                v-for="project in projects?.slice(0, visibleProjectsCount)"
                :project="project"
              />
            </div>
            <div v-if="projects?.length > visibleProjectsCount" class="center mt--20">
              <base-button
                height="36"
                text="Показать еще"
                @click="visibleProjectsCount = visibleProjectsCount + 4"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="developerCardData.partners"
        id="partners"
        class="page-card__content section"
      >
        <div class="font-size-title-block mb--20">Партнеры</div>
        <Partners :partners="developerCardData.partners" />
      </div>
      <!--      <div id="news" class="mt&#45;&#45;27 section">-->
      <!--        <div class="xl-font-size-title-block mb&#45;&#45;20">Новости</div>-->
      <!--        <div class="page-card__grid-cards">-->
      <!--          <NewsCard v-for="i in 3" :key="i" />-->
      <!--        </div>-->
      <!--        <div class="center mt&#45;&#45;30">-->
      <!--          <base-button height="36" text="Показать еще" width="165" />-->
      <!--        </div>-->
      <!--      </div>-->
      <div
        v-if="otherDevelopersLoading || otherDevelopers?.length"
        class="page-card__content"
      >
        <div class="font-size-title-block mb--20">Иные застройщики</div>
        <div
          v-if="otherDevelopersLoading"
          class="page-card__grid-cards m-developer-cards"
        >
          <LoadingMediumDeveloperCard v-for="i in 3" :key="i" />
        </div>
        <div v-else>
          <div v-if="useAppStore().getWindowWidth() > 600">
            <base-swiper
              :slides="otherDevelopers"
              :space-between="20"
              :width-item="240"
              class="mt--20 mb--20"
            >
              <template #item="itemProps">
                <div class="pb--10 pt--10 center">
                  <MediumDeveloperCard :developer="itemProps.data" />
                </div>
              </template>
            </base-swiper>
          </div>

          <div v-else>
            <div class="page-card__grid-cards">
              <MediumDeveloperCard
                v-for="developer in otherDevelopers?.slice(
                  0,
                  visibleOtherDevelopersCount,
                )"
                :developer="developer"
              />
            </div>
            <div class="center mt--20">
              <base-button
                v-if="otherDevelopers?.length > visibleOtherDevelopersCount"
                height="36"
                text="Показать еще"
                @click="visibleOtherDevelopersCount = visibleOtherDevelopersCount + 4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-card__aside">
      <div>
        <base-card class="page-card__main-info">
          <div class="m-font-size mb--10 medium">{{ developerCardData.title }}</div>
          <div class="mb--5">
            <span class="dark-text-color">Сдано: </span>
            <span>{{ developerCardData.builtCount || '-' }}</span>
          </div>
          <div class="mb--10">
            <span class="dark-text-color">Строится: </span>
            <span>{{ developerCardData.buildingCount || '-' }}</span>
          </div>
          <base-progress-line class="mb--5" name="Проверка рисков" value="100" />
        </base-card>
      </div>
      <div class="mt--25">
        <base-card>
          <MenuBlock :menu-items="menuItems" />
        </base-card>
      </div>
      <div class="mt--25">
        <base-card>
          <ActivityButtons
            :docs="developerCardData.documents"
            :phone="developerCardData.phone"
          />
        </base-card>
      </div>
    </div>
    <div class="page-card__aside-mobile mb--22">
      <base-card class="page-card__aside-mobile-card">
        <PhoneButton :phone="developerCardData.phone" height="36" />
        <base-menu>
          <template #menuItems="menuItemsProps">
            <div class="page-card__menu">
              <div v-for="(item, i) in menuItems" :key="item.id">
                <a
                  :href="`#${item.id}`"
                  class="page-card__menu-item"
                  @click="menuItemsProps.events.handleClick()"
                >
                  {{ item.title }}
                </a>
                <base-divider v-if="i !== menuItems.length - 1" />
              </div>
            </div>
          </template>
        </base-menu>
      </base-card>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ActivityButtons, MenuBlock, Partners } from '../CardsComponents';
import {
  DeveloperSmallCard,
  DocumentList,
  MapBlock,
  PhoneButton,
  ResidentialComplexCard,
} from '@/components';
import {
  LoadingMediumDeveloperCard,
  MediumDeveloperCard,
} from '@/components/Cards/MediumCards';
import { DeveloperCardType, SmallCardLSDType } from '@/types';
import LoadingResidentialComplexCard from '@/components/ResidentialComplexCard/LoadingResidentialComplexCard.vue';
import { SmallDeveloperCardType } from '@/types/DeveloperTypes';
import { inject, onMounted, ref } from 'vue';
import { SmoothScrollOptions } from 'vue3-smooth-scroll';
import { useRoute } from 'vue-router';
import { useAppStore } from '@/store/modules/app';

const props = withDefaults(
  defineProps<{
    developerCardData: DeveloperCardType;
    projects: SmallCardLSDType[] | undefined;
    projectsLoading: boolean;
    otherDevelopers: SmallDeveloperCardType[] | undefined;
    otherDevelopersLoading: boolean;
  }>(),
  {},
);

const menuItems = [
  {
    id: 'about',
    title: 'О застройщике',
  },
  {
    id: 'documentation',
    title: 'Документация',
  },
  {
    id: 'map',
    title: 'ЖК на карте',
  },
  {
    id: 'allLCD',
    title: 'Все ЖК',
  },
  {
    id: 'partners',
    title: 'Партнеры',
  },

  // {
  //   id: 'news',
  //   title: 'Новости',
  // },
];

const visibleProjectsCount = ref(4);
const visibleOtherDevelopersCount = ref(4);

const smoothScroll = inject('smoothScroll') as (args: SmoothScrollOptions) => void;

onMounted(() => {
  const hash = useRoute().hash;

  if (hash) {
    const myEl = document.getElementById(hash.replace('#', ''));

    if (myEl) {
      smoothScroll({
        scrollTo: myEl,
        container: myEl,
        updateHistory: true,
      });
    }
  }
});
</script>

<style lang="scss" scoped src="./DeveloperCard.scss"></style>
<style lang="scss" scoped src="../CommonCardStyles.scss"></style>
