<template>
  <teleport to="#modalWindow" v-if="sheet">
    <div class="bottom-sheet">
      <div
        class="bottom-sheet__content"
        v-click-outside="closeOutside ? closeSheet : () => {}"
      >
        <slot></slot>

        <base-icon
          class="bottom-sheet__content-closeIcon"
          name="close"
          clickable
          height="15"
          width="15"
          @click="closeSheet"
        />
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
const props = defineProps<{
  sheet: boolean;
  closeOutside?: boolean;
}>();

const emit = defineEmits(['close']);
const closeSheet = () => {
  emit('close');
};
</script>

<style scoped lang="scss" src="./BaseBottomSheet.scss"></style>
