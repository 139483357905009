<template>
  <div v-if="isLoading" :style="[cssProps]" class="button-group">
    <div v-for="i in 3" :key="i">
      <base-skeleton-loader
        :style="{ height: btnHeight + 'px', width: btnWidth + 'px' }"
        type="btn"
      />
    </div>
  </div>
  <div v-else :style="[cssProps]" class="button-group">
    <div v-for="(btn, i) in buttons" :key="i">
      <base-button
        :active="active === btn.id"
        :height="btnHeight"
        :style-type="type"
        allBlockWidth
        @click="changeActiveBtn(btn)"
      >
        <template #text>
          <slot :data="btn" name="btnText"></slot>
          <span v-if="!$slots.btnText">{{ btn[textKey] }}</span>
        </template>
      </base-button>
    </div>
  </div>
</template>

<script generic="T" lang="ts" setup>
import { ButtonTypesType } from '@/types/ButtonTypes';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    // eslint-disable-next-line no-undef
    buttons: T[];
    type?: ButtonTypesType;
    btnWidth?: string;
    btnHeight?: string;
    // eslint-disable-next-line no-undef
    active?: T;
    textKey?: string;
    isLoading?: boolean;
  }>(),
  {
    buttons: undefined,
    type: 'colored',
    btnWidth: undefined,
    btnHeight: '28',
    active: undefined,
    textKey: 'text',
  },
);
const emit = defineEmits(['changeActiveBtn']);
const changeActiveBtn = <T,>(i: T) => {
  emit('changeActiveBtn', i);
};

const cssProps = computed(() => {
  return {
    '--btn-width': props.btnWidth + 'px',
  };
});
</script>

<style lang="scss" scoped src="./BaseButtonGroup.scss"></style>
