<template>
  <div :class="{ container }" class="pb-0 none-Mobile">
    <div class="inline-block">
      <div
        :class="{
          open: activeComponent,
        }"
        class="menu-with-tabs"
      >
        <div
          v-for="(componentBtn, i) in componentBtns"
          :key="i"
          class="menu-with-tabs__tab"
        >
          <div
            :class="{
              active: activeComponent === componentBtn?.id,
              error:
                btnIdsWithErrors && btnIdsWithErrors.find(el => el === componentBtn?.id),
            }"
            :style="{ ...cssProps, ...maxTabWidth }"
            class="menu-with-tabs__tab-btn"
            @click="changeCurrentComponent(componentBtn.id)"
          >
            <base-icon
              v-if="componentBtn.icon"
              :color="activeComponent === componentBtn?.id ? '#009D3E' : 'white'"
              :name="componentBtn.icon"
              height="18"
              width="18"
            />
            <span
              v-if="hideTitle ? windowWidth >= hideTitle : true"
              :class="{
                active: activeComponent === componentBtn?.id,
                error:
                  btnIdsWithErrors &&
                  btnIdsWithErrors.find(el => el === componentBtn?.id),
              }"
              class="menu-with-tabs__tab-btn-title"
            >
              <slot :data="componentBtn" name="title"></slot>
              <span v-if="!$slots.title">{{ componentBtn.title }}</span>
            </span>
          </div>
          <base-divider
            v-if="i !== componentBtns.length - 1 ? true : withClose"
            :color="
              componentBtn?.id !== activeComponent &&
              componentBtns.findIndex(el => el.id === activeComponent) - 1 !== i
                ? '#ffffff'
                : 'transparent'
            "
            inset="20px"
            vertical
          />
        </div>
        <div v-if="withClose" class="menu-with-tabs__close">
          <base-icon
            clickable
            color="white"
            height="15"
            name="close"
            width="15"
            @click="emit('close')"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-if="mobileShowAllBlocks && useAppStore().getWindowWidth() <= 600">
    <base-expansion-panels
      v-for="category in componentBtns"
      :key="category.id"
      :isError="!!(btnIdsWithErrors && btnIdsWithErrors.find(el => el === category?.id))"
      styleType="simple-green"
    >
      <template #title="titleProps">
        <slot :data="category" name="title"></slot>
        <div v-if="!$slots.title">{{ category.title }}</div>
      </template>
      <template #content="contentProps">
        <slot :data="category.id" name="content"></slot>
      </template>
    </base-expansion-panels>
  </div>
  <div
    v-else
    :class="{
      active: activeComponent,
      withRadius,
    }"
    :style="cssProps"
    class="menu-with-tabs-content"
  >
    <div :class="{ withRadius }" :style="cssProps" class="menu-with-tabs-content-box">
      <div class="menu-with-tabs-mobile">
        <base-select-input
          :modelValue="activeComponent"
          :options="preparedComponentBtns"
          :showLabel="false"
          :type="greenMobile ? 'green' : 'mainColorBorder'"
          label="Фильтрация"
          @update:modelValue="changeCurrentComponent($event)"
        />
        <base-button v-if="withClose" height="34" icon width="50" @click="emit('close')">
          <template #icon>
            <base-icon clickable color="white" height="15" name="close" width="15" />
          </template>
        </base-button>
      </div>
      <div v-if="activeComponent" class="menu-with-tabs-content-box-slot">
        <div id="menu-slot">
          <slot :data="activeComponent" name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import { BtnsForTabsMenuType, OptionsType } from '@/types';
import { useAppStore } from '@/store/modules/app';

const props = defineProps<{
  componentBtns: BtnsForTabsMenuType[];
  activeComponent: number | undefined;
  hideTitle?: number;
  withRadius?: boolean;
  withClose?: boolean;
  bgColor?: string;
  container?: boolean;
  greenMobile?: boolean;
  mobileShowAllBlocks?: boolean;
  btnIdsWithErrors?: (number | string)[];
}>();

const emit = defineEmits(['changeActiveBtn', 'close']);

const windowWidth = computed((): number => {
  return useAppStore().window;
});

const changeCurrentComponent = (componentBtnId: number | string) => {
  emit('changeActiveBtn', componentBtnId);
};
const preparedComponentBtns = computed((): OptionsType[] => {
  return props.componentBtns.map(el => {
    return { id: el.id, name: el.title, icon: el.icon };
  });
});

const cssProps = computed(() => {
  return {
    '--bg-color': props.bgColor,
  };
});

const maxTabWidth = ref({ '--max-active-tab-width': '100%' });
const updateMaxTabWidth = () => {
  const slotBlockWidth = document.getElementById('menu-slot')?.offsetWidth;

  if (slotBlockWidth) {
    maxTabWidth.value['--max-active-tab-width'] =
      `${(slotBlockWidth - 10) / props.componentBtns.length}px`;
  }
};

onMounted(() => {
  setTimeout(() => {
    updateMaxTabWidth();
  }, 100);
});
watch(
  () => windowWidth.value,
  () => {
    updateMaxTabWidth();
  },
);
</script>

<style lang="scss" scoped src="./BaseMenuWithTabs.scss"></style>
