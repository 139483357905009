<template>
  <base-modal :show-modal="showModal" title="Войти" @close="$emit('close')">
    <template #content>
      <div class="auth-modal">
        <div v-if="useAppStore().showAuthMessage" class="main-color align-center">
          Доступ откроется при входе в личный кабинет
        </div>
        <base-form class="grid--1 gap--15">
          <base-input
            v-model="formData.email"
            :error="errors.email"
            :invalid="!!errors.email"
            height="40"
            label="E-mail"
            @blur="validationErrorsHandlerByKey('email')"
            @focus="delete errors.email"
            @input="v$.email.$touch"
          />
          <base-input
            v-model="formData.password"
            :error="errors.password"
            :invalid="!!errors.password"
            height="40"
            label="Пароль"
            type="password"
            @blur="validationErrorsHandlerByKey('password')"
            @focus="delete errors.password"
            @input="v$.password.$touch"
          />
          <button
            class="main-color align-right"
            type="button"
            @click="$emit('showPasswordRecoveryModal')"
          >
            Забыли пароль?
          </button>
        </base-form>
        <span class="second-color xs-font-size"
          >При регистрации и входе вы соглашаетесь с условиями использования "Мой ИЖС" и
          политикой конфиденциальности.</span
        >
        <div class="items-in-line gap--5 flex-start">
          Нет аккаунта?
          <button
            class="gradient-color gradient-underline"
            type="button"
            @click="$emit('showRegistrationModal')"
          >
            Зарегистрироваться
          </button>
        </div>
      </div>
    </template>
    <template #btn>
      <base-button :loading="loading" height="40" text="Войти" @click="formSubmit" />
    </template>
  </base-modal>
</template>
<script lang="ts" setup>
import { Ref, ref } from 'vue';
import { useVuelidate, ValidationRuleWithoutParams } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import {
  errorsHandler,
  errorsHandlerByKey,
  validationErrorsByRequest,
} from '@/mixins/Common/Common';
import { ErrorsType } from '@/types';
import { loginApi } from '@/api/login';
import { useUserStore } from '@/store/modules/user';
import { useAppStore } from '@/store/modules/app';
import { defaultCompareStore, useCompareStore } from '@/store/modules/compare';

const props = defineProps<{
  showModal?: boolean;
}>();
const emit = defineEmits(['close', 'showPasswordRecoveryModal', 'showRegistrationModal']);

type FormDataType = {
  email: string | undefined;
  password: string | undefined;
};

const formData: Ref<FormDataType> = ref({
  email: undefined,
  password: undefined,
});

const clearFormData = () => {
  v$.value.$reset();
  for (const [key] of Object.entries(formData)) {
    formData.value[key as keyof FormDataType] = undefined;
  }
};

const rules: { [key: string]: { [key: string]: ValidationRuleWithoutParams } } = {
  email: { required, email },
  password: { required },
};
const v$ = useVuelidate<FormDataType>(rules, formData);

const errors: Ref<ErrorsType> = ref({});

const validationErrorsHandlerByKey = (key: string) => {
  let error: string | undefined = errorsHandlerByKey(key, rules, v$);

  if (error) {
    errors.value[key] = error;
  } else {
    delete errors.value[key];
  }
};
const validationErrorsHandler = () => {
  errors.value = errorsHandler(formData.value, rules, v$);
};

const loading = ref(false);
const formSubmit = async () => {
  if (v$.value.$invalid) {
    v$.value.$touch();
    validationErrorsHandler();

    return;
  }
  loading.value = true;
  try {
    const res = await loginApi(formData.value);

    if (res) {
      useUserStore().writeUserInfo(res.data.user);
      clearFormData();
      useCompareStore().$state = { ...defaultCompareStore };
      emit('close');
      localStorage.setItem('auth-event', 'login' + Math.random());
    }
  } catch (error) {
    errors.value = validationErrorsByRequest(error);
  } finally {
    loading.value = false;
  }
};
</script>

<style lang="scss" scoped src="./AuthModal.scss"></style>
