<template>
  <div class="info-top-bar">
    <div>
      {{ text }}
    </div>

    <base-button
      all-block-width
      height="36"
      style-type="border"
      text="Отзыв / предложение"
      @click="showReviewModal = true"
    />
  </div>
  <ReviewModal :show-modal="showReviewModal" @close="showReviewModal = false" />
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import ReviewModal from '@/components/ReviewModal/ReviewModal.vue';

const props = withDefaults(
  defineProps<{
    text?: string;
  }>(),
  {
    text:
      ' Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium\n' +
      '      doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore\n' +
      '      veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam\n' +
      '      voluptatem, quia voluptas sit, aspernatur aut odit aut fugit, sed quia consequuntur\n' +
      '      magni dolores eos, qui ratione voluptatem sequi nesciunt, neque porro quisquam est,\n' +
      '      qui dolorem ipsum, quia dolor sit, amet, consectetur, adipisci velit, sed quia non\n' +
      '      numquam eius modi tempora incidunt, ut labore et dolore magnam aliquam quaerat\n' +
      '      voluptatem. Ut enim ad minima veniam',
  },
);

const showReviewModal = ref(false);
</script>

<style lang="scss" scoped src="./InfoTopBar.scss"></style>
