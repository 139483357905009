<template>
  <div class="input-group">
    <div
      v-for="(input, i) in inputs"
      :key="input.id"
      :style="{ gridArea: `input${i + 1}` }"
      class="input-group__input"
    >
      <div
        v-if="input.id === 6"
        v-click-outside="clickOutside"
        class="input-group__input-address"
      >
        <base-input
          v-model="input.value"
          :height="height"
          :label="input.label"
          :styles="{ border: 'none' }"
          :type="input.type"
          filled
          hideArrows
          @update:model-value="updateAddress"
        />
        <div v-if="showAddressItems" class="input-group__input-dadata-items">
          <button
            v-for="item in addressItems"
            class="input-group__input-dadata-items-item"
            @click.prevent="
              changeLocation(item);
              input.value = item;
            "
          >
            {{ item }}
          </button>
        </div>
      </div>

      <base-select-input
        v-else-if="input.type === 'select'"
        :height="height"
        :label="input.label"
        :modelValue="input.value"
        :options="input.options"
        :styles="{ border: 'none' }"
        clearable
        filled
        @update:modelValue="
          useOfferCardsStore().updateMainInputsParams({ id: input.id, value: $event });
          useOfferCardsStore().showResultBlock();
        "
      />
      <base-input
        v-else
        v-model="input.value"
        :height="height"
        :label="input.label"
        :max="input.max"
        :min="input.min"
        :styles="{ border: 'none' }"
        :type="input.type"
        filled
        hideArrows
        @blur="useOfferCardsStore().showResultBlock()"
      />
    </div>

    <div
      v-for="i in inputs?.length"
      :style="{ gridArea: `verticalDivider${i}` }"
      class="input-group-verticalDivider center"
    >
      <base-divider inset="80%" vertical />
    </div>

    <base-divider
      v-for="i in 3"
      :style="{ gridArea: `horizontalDivider${i}` }"
      class="input-group-horizontalDivider"
    />
  </div>
</template>

<script lang="ts" setup>
import { getAddress, getLocation } from '@/api/ymaps';
import { computed, ref, Ref } from 'vue';
import { useOfferCardsStore } from '@/store/modules/offerCards';
import { useAppStore } from '@/store/modules/app';
import { useRouter } from 'vue-router';

const props = withDefaults(
  defineProps<{
    height?: string;
  }>(),
  {
    height: '36',
  },
);

const inputs = computed(() => {
  return useOfferCardsStore().mainInputsParams;
});
const addressItems: Ref<string[]> = ref([]);
const showAddressItems: Ref<boolean> = ref(false);

const clickOutside = () => {
  if (showAddressItems.value) {
    showAddressItems.value = false;
  }
};
const updateAddress = (event: string) => {
  if (event) {
    getAddress(event).then(res => {
      addressItems.value = res.data.suggestions.map((el: { value: string }) => el.value);
      if (addressItems.value.length > 0) {
        showAddressItems.value = true;
      }
    });
  }
};
const router = useRouter();
const changeAddressValue = (value: string) => {
  getLocation(value).then(res => {
    const point =
      res.data.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos.split(
        ' ',
      );

    useOfferCardsStore().updateCenterPointMap(point[1], point[0]);
    if (useAppStore().getWindowWidth() <= 600) {
      router.push({ name: 'fullMap' });
    }

    useOfferCardsStore().showResultBlock(true);
    if (!useOfferCardsStore().offerCards.length) {
      useOfferCardsStore().showResultBlock();
    }
  });
};
const changeLocation = (value: string) => {
  changeAddressValue(value);
  showAddressItems.value = false;
};
</script>

<style lang="scss" scoped src="./InputGroup.scss"></style>
