<template>
  <div ref="carousel" :style="[cssProps]" class="carousel-box">
    <div v-if="slides.length === 1" :class="{ withBgColor }">
      <Slide>
        <div class="carousel__item">
          <slot :data="slides[0]" name="item"></slot>
        </div>
      </Slide>
    </div>
    <Carousel
      v-else-if="withPreview"
      id="gallery"
      v-model="currentSlide"
      :class="{ withBgColor }"
      :items-to-show="1"
      :wrap-around="true"
      >class="carousel__item"
      <Slide v-for="(slide, i) in slides" :key="i">
        <div class="carousel__item">
          <slot :data="slide" name="item"></slot>
        </div>
      </Slide>
      <template v-if="showNavigation && slides.length > 1" #addons>
        <Navigation />
      </template>
    </Carousel>
    <div
      v-if="slides.length > 1"
      :class="{ 'carousel-box--with-navigation': thumbnailsNavigation }"
    >
      <base-icon
        v-if="thumbnailsNavigation"
        :disabled="currentSlide === 0"
        class="arrow_back"
        clickable
        color="#868298"
        height="14"
        name="arrow_back_ios"
        width="15"
        @click="prev"
      />
      <div :id="`thumbnails${uuid}`" ref="thumbnails" class="carousel__thumbnails">
        <Carousel
          id="thumbnails"
          v-model="currentSlide"
          :items-to-show="slides.length > maxItemsToShow ? maxItemsToShow : slides.length"
          :wrap-around="false"
        >
          <Slide v-for="(slide, i) in slides" :key="i">
            <div
              v-if="withPreview"
              class="carousel__small-item pointer"
              @click="slideTo(i)"
            >
              <slot :data="slide" name="smallItem"></slot>
            </div>

            <div v-else :style="{ width: widthSmallItem }" class="carousel__small-item">
              <slot :data="slide" name="smallItem"></slot>
            </div>
          </Slide>
        </Carousel>
      </div>

      <base-icon
        v-if="thumbnailsNavigation"
        :disabled="currentSlide === slides.length - 1"
        class="arrow_forward"
        clickable
        color="#868298"
        height="14"
        name="arrow_forward_ios"
        width="15"
        @click="next"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, getCurrentInstance, onMounted, Ref, ref, watch } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { useAppStore } from '@/store/modules/app';
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import { ComponentInternalInstance } from '@vue/runtime-core';

const window = computed((): number => {
  return useAppStore().window;
});

const instance: ComponentInternalInstance | null = getCurrentInstance();
const uuid = ref(instance?.uid);

const props = withDefaults(
  defineProps<{
    slides: string[];
    itemsToShow?: number;
    showNavigation?: boolean;
    thumbnailsNavigation?: boolean;
    withBgColor?: boolean;
    withPreview?: boolean;
    widthSmallItem?: number;
    carouselGap?: string;
  }>(),
  {
    thumbnailsNavigation: false,
    showNavigation: true,
    withPreview: true,
    widthSmallItem: 120,
    carouselGap: '5',
  },
);

const currentSlide = ref(0);
const next = () => {
  currentSlide.value = currentSlide.value + 1;
};
const prev = () => {
  currentSlide.value = currentSlide.value - 1;
};

// const thumbnails = ref<HTMLElement | undefined>();
const galleryWidth: Ref<number | undefined> = ref(undefined);
const maxItemsToShow: Ref<number> = ref(1);

const updateGalleryWidth = () => {
  if (props.slides.length > 1) {
    const thumbnails = document.getElementById(`thumbnails${uuid.value}`);

    galleryWidth.value = thumbnails?.offsetWidth;
    updateMaxItemsToShow();
  }
};

const updateMaxItemsToShow = () => {
  if (galleryWidth.value) {
    maxItemsToShow.value = Math.round(galleryWidth.value / props.widthSmallItem);
  } else {
    updateGalleryWidth();
  }
};

const cssProps = computed(() => {
  return {
    '--carousel-gap': props.carouselGap + 'px' ?? '5px',
  };
});

const slideTo = (val: number) => {
  currentSlide.value = val;
};

onMounted(() => {
  if (props.itemsToShow) {
    maxItemsToShow.value = props.itemsToShow;
  } else {
    updateGalleryWidth();
  }
});
watch(
  () => window.value,
  () => {
    if (!props.itemsToShow) {
      updateGalleryWidth();
    }
  },
);
</script>

<style lang="scss" scoped src="./BaseImageCarousel.scss"></style>
