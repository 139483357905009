<template>
  <div class="params-block">
    <div v-for="category in activeParams">
      <div v-if="category.options.length" class="params-block__box">
        <div class="params-block__name medium">
          <div>
            {{ category.categoryName }}
          </div>
          <div v-if="category.distance" class="params-block__name-distance">
            <span class="none-Mobile">,</span> расстояние в
            <button
              :class="{ 'main-color medium': category.distance === 'km' }"
              :disabled="category.distance === 'km'"
              class="underline"
              @click="$emit('changeDistance', { id: category.paramId, value: 'km' })"
            >
              километрах
            </button>
            /
            <button
              :class="{ 'main-color medium': category.distance === 'm' }"
              :disabled="category.distance === 'm'"
              class="underline"
              @click="$emit('changeDistance', { id: category.paramId, value: 'm' })"
            >
              метрах
            </button>
          </div>
          <base-divider class="only-mobile mt--10 mb--10" color="#009D3E" />
        </div>
        <div
          :class="{
            visible:
              visibleParameters.includes(category.paramId) || activeParams?.length <= 1,
          }"
          class="params-block__inputs"
        >
          <div v-for="input in category.options" :key="input.id">
            <div v-if="paramsValues && paramsValues[String(input.id)]">
              <div v-if="!isMinMaxType(input.options)">
                <div v-if="isOnlyMax(input.options)">
                  <base-date-picker
                    v-if="
                      input.type === 12 ||
                      input.type === 13 ||
                      input.type === 19 ||
                      input.type === 20
                    "
                    v-model="paramsValues[String(input.id)].max"
                    :flow="['year', 'month', 'calendar']"
                    :height="heightInput"
                    :label="input.title"
                    :month-picker="input.type === 20"
                    :year-picker="input.type === 19"
                    with-bg
                  />
                  <div v-else class="items-in-line">
                    <base-input
                      v-model="paramsValues[String(input.id)].max"
                      :height="heightInput"
                      :isPercent="input.type === 6"
                      :label="input.title"
                      :max="
                        input.type === 6
                          ? 100
                          : input.options.max
                            ? +input.options.max
                            : undefined
                      "
                      :min="input.options.min ? +input.options.min : undefined"
                      :styles="{ border: '1px solid #94857d' }"
                      :type="
                        input.type === 2 || input.type === 5 || input.type === 6
                          ? 'number'
                          : 'text'
                      "
                      hideArrows
                      with-bg
                    />
                  </div>
                </div>
                <div v-else>
                  <base-date-picker
                    v-if="
                      input.type === 12 ||
                      input.type === 13 ||
                      input.type === 19 ||
                      input.type === 20
                    "
                    v-model="paramsValues[String(input.id)].value"
                    :flow="['year', 'month', 'calendar']"
                    :height="heightInput"
                    :label="input.title"
                    :month-picker="input.type === 20"
                    :year-picker="input.type === 19"
                    with-bg
                  />
                  <base-checkbox-input
                    v-else-if="input.type === 3"
                    v-model="paramsValues[String(input.id)].value"
                    :height="heightInput"
                    :label="input.title"
                  />
                  <base-select-input
                    v-else-if="input.type === 4 || input.type === 14"
                    :height="heightInput"
                    :isMulti="input.type === 14"
                    :label="input.title"
                    :modelValue="paramsValues[String(input.id)].value"
                    :options="prepareOptions(input.options)"
                    :searchCount="
                      input.options['search2-visual'] === '15' ||
                      input.options['search2-visual'] === '13'
                        ? 5
                        : 7
                    "
                    arrow="expand_more"
                    clearable
                    with-bg
                    @update:modelValue="paramsValues[String(input.id)].value = $event"
                  />
                  <base-input
                    v-else-if="input.type === 23"
                    v-model="paramsValues[input.id].value"
                    v-maska
                    :height="heightInput"
                    :label="input.title"
                    data-maska="##:##:######:##"
                    hideArrows
                    placeholder="47:14:120301:814"
                    with-bg
                  />
                  <div v-else class="items-in-line">
                    <base-input
                      v-model="paramsValues[String(input.id)].value"
                      :height="heightInput"
                      :isPercent="input.type === 6"
                      :label="input.title"
                      :max="
                        input.type === 6
                          ? 100
                          : input.options.max
                            ? +input.options.max
                            : undefined
                      "
                      :min="input.options.min ? +input.options.min : undefined"
                      :styles="{ border: '1px solid #94857d' }"
                      :type="
                        input.type === 2 || input.type === 5 || input.type === 6
                          ? 'number'
                          : 'text'
                      "
                      hideArrows
                      with-bg
                    />
                  </div>
                </div>
              </div>
              <div
                v-else-if="paramsValues[String(input.id)]"
                :class="{ withText: input.type === 27 }"
                class="params-block__inputs-group"
              >
                <base-date-picker
                  v-if="
                    input.type === 12 ||
                    input.type === 13 ||
                    input.type === 19 ||
                    input.type === 20
                  "
                  v-model="paramsValues[String(input.id)].min"
                  :flow="['year', 'month', 'calendar']"
                  :height="heightInput"
                  :label="input.title"
                  :month-picker="input.type === 20"
                  :year-picker="input.type === 19"
                  backgroundColor="#F9F8FD"
                  with-bg
                />
                <base-input
                  v-else
                  v-model="paramsValues[String(input.id)].min"
                  :height="String(+heightInput - 2)"
                  :label="input.title + ' от'"
                  :styles="{
                    backgroundColor: '#F9F8FD',
                    border: 'none',
                    borderRadius: '10px 0 0 10px',
                  }"
                  hideArrows
                  type="number"
                  with-bg
                />
                <base-divider
                  class="params-block__inputs-group-divider"
                  inset="80%"
                  vertical
                />
                <base-date-picker
                  v-if="
                    input.type === 12 ||
                    input.type === 13 ||
                    input.type === 19 ||
                    input.type === 20
                  "
                  v-model="paramsValues[String(input.id)].max"
                  :flow="['year', 'month', 'calendar']"
                  :height="heightInput"
                  :label="input.title"
                  :month-picker="input.type === 20"
                  :year-picker="input.type === 19"
                  backgroundColor="#F9F8FD"
                  with-bg
                />
                <base-input
                  v-model="paramsValues[String(input.id)].max"
                  :height="String(+heightInput - 2)"
                  :label="input.title + ' до'"
                  :styles="{
                    backgroundColor: '#F9F8FD',
                    border: 'none',
                    borderRadius: '0 10px 10px 0',
                  }"
                  hideArrows
                  type="number"
                  with-bg
                />
                <div v-if="input.type === 27">км</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ParamType } from '@/store/modules/searchRealEstate';
import { OptionType } from '@/api/common/types';
import { SearchParamsDataType } from '@/types';
import { computed } from 'vue';
import { vMaska } from 'maska';

const props = defineProps<{
  activeParams: ParamType[] | undefined;
  visibleParameters: number[];
}>();
const emit = defineEmits(['changeVisibleCategory', 'changeDistance']);
// eslint-disable-next-line no-undef
const paramsValues = defineModel<SearchParamsDataType>();
const heightInput = '34';

const isMinMaxType = (options: OptionType) => {
  const type = options['search2-visual'];

  if (type) {
    return type === '3' || type === '6' || type === '7' || type === '8';
  }

  return false;
};

const isOnlyMax = (options: OptionType) => {
  const type = options['search2-visual'];

  if (type) {
    return type === '2' || type === '9' || type === '10' || type === '11';
  }

  return false;
};
const modelValue = computed(() => {});
const prepareOptions = (options: OptionType) => {
  return Object.entries(options.list.items).map(item => {
    return {
      id: item[0],
      name: item[1],
    };
  });
};
</script>

<style lang="scss" scoped src="./ParamsBlock.scss"></style>
